import { combineReducers } from "redux";
import { allAnnouncementShow } from "./dashboardReducer";
import { logInReducer, logOutReducer, LoggedInReducer } from "./authReducer";
import { clinicIDReducer, ClinicsReducer } from "./clinicReducer";
import {
  clientIDReducer,
  clientInsuranceIDReducer,
  selectedClientIdReducer,
  clientFilterReducer,
  selectedDrawer,
  clientDetails,
  getClientProfileImg,
  currentInsuranceDetails,
  getPrimaryCarePhysician,
  getPediatrician,
  getAllclientAvailable,
  clientFlagsReducer,
  getClientListPageNumber,
  getJoinUrlLink,
  selectedClientName,
  GetClinicLogoByteReducer,
  GetClinicLogoUrlReducer,
  ClientReducer,
  clientPrivatePay,
  PrimaryDX
} from "./clientReducer";

import { selectedServiceIdReducer } from "./serviceReducer";
import {
  selectedHeaderMenu,
  isGlobalSearchReducer,
  getGender,
  getRole,
  getDocumentTemplate,
  getSiteId,
  selectedAppSite,
  getSiteVaue,
} from "./commonSlice";
import { userAccessReducer } from "./userAccessReducer";

import {
  selectedStaffIdReducer,
  getStaffReducer,
  getMaritialsStatusReducer,
  getGenderReducer,
  getStaffDetails,
  getStaffProfileImg,
  getRolePermission,
  getAvailableStaff,
  getDocumentFilter,
  getClientDocumentFilter,
  getBillDocFilter,
  getStaffOnline,
  getBillTransactionFilter,
  getBillingClaimViewFilter,
  getBillRemittanceFilter,
  StaffReducer,
  getAuthorizationFilters,
  getClientAuthorizationFilters,
  getSchedulerFilter,
  staffFiter,

} from "./staffReducer";

import { getClinicDetails } from "./clinicReducer";
import { LoaderReducer } from "./LoaderReducer";
import { DocumentReducer } from "./docsReducers";
import { BillingReducer } from "./billingReducer";
import { DashboardReducer } from "./dashboardReducer";
import { StaffDocReducer } from "./staffDocReducer";
import { ServiceRuleReducer } from "./serviceRulesReducer";
import { MessageReducer } from "./MessageReducer";
import { ReportReducer } from "./ReportReducer";
import { SubsCriptionManagerReducer } from "./subscriptionManagerReducer";



const appReducer = combineReducers({
  loggedIn: logInReducer,
  loggedOut: logOutReducer,
  clinicId: clinicIDReducer,
  clientId: clientIDReducer,
  clientFlagsReducer: clientFlagsReducer,
  selectedDrawer: selectedDrawer,
  clientInsuranceId: clientInsuranceIDReducer,
  selectedClientId: selectedClientIdReducer,
  selectedServiceId: selectedServiceIdReducer,
  selectedStaffId: selectedStaffIdReducer,
  clientLastFilter: clientFilterReducer,
  getStaffReducer: getStaffReducer,
  getMaritialsStatusReducer: getMaritialsStatusReducer,
  getGenderReducer: getGenderReducer,
  selectedHeaderMenu: selectedHeaderMenu,
  isGlobalSearchReducer: isGlobalSearchReducer,
  getGender: getGender,
  getRole: getRole,
  clientDetails: clientDetails,
  clientPrivatePay: clientPrivatePay,
  getClientProfileImg: getClientProfileImg,
  getStaffDetails: getStaffDetails,
  getStaffProfileImg: getStaffProfileImg,
  currentInsuranceDetails: currentInsuranceDetails,
  userAccessPermission: userAccessReducer,
  getPrimaryCarePhysician: getPrimaryCarePhysician,
  getPediatrician: getPediatrician,
  getRolePermission: getRolePermission,
  getDocumentTemplate: getDocumentTemplate,
  getSiteId: getSiteId,
  selectedAppSite: selectedAppSite,
  getAllclientAvailable: getAllclientAvailable,
  getAvailableStaff: getAvailableStaff,
  getSiteVaue: getSiteVaue,
  getDocumentFilter: getDocumentFilter,
  getAuthorizationFilters: getAuthorizationFilters,
  getClientDocumentFilter: getClientDocumentFilter,
  getBillDocFilter: getBillDocFilter,
  getStaffOnline: getStaffOnline,
  getClinicDetails: getClinicDetails,
  getBillTransactionFilter: getBillTransactionFilter,
  getBillRemittanceFilter: getBillRemittanceFilter,
  getBillingClaimViewFilter: getBillingClaimViewFilter,
  getClientListPageNumber: getClientListPageNumber,
  getJoinUrlLink: getJoinUrlLink,
  selectedClientName: selectedClientName,
  LoggedInReducer,
  ReportReducer,
  LoaderReducer,
  DocumentReducer,
  BillingReducer,
  logoByte: GetClinicLogoByteReducer,
  GetClinicLogoUrlReducer,
  ClientReducer,
  ClinicsReducer,
  DashboardReducer,
  StaffReducer,
  StaffDocReducer,
  ServiceRuleReducer,
  MessageReducer,
  SubsCriptionManagerReducer,
  // AuthReducer,
  getClientAuthorizationFilters: getClientAuthorizationFilters,
  PrimaryDX: PrimaryDX,
  getSchedulerFilter:getSchedulerFilter,
  staffFiter:staffFiter,
  allAnnouncementShow:allAnnouncementShow

});

export default appReducer;
