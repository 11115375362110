const NOTIFICATION_MESSAGE = {
  DELETE_CLIENT: "Client deleted successfully.",
  ERROR_DELETE_CLIENT: "Error while deleting client.",
  CLIENT_CREATED: "Client added successfully.",
  CLIENT_UPDATED: "Client details updated.",
  SITES_ADDED: "Sites added successfully.",
  SIBLING_ADDED: "Relationship added successfully.",
  SIBLING_UPDATED: "Sibling updated successfully.",
  SIBLING_DELETED: "Relationship deleted successfully.",
  USERNAME_SUBMITTED: "Otp has been sent to your registered phone number.",

  PASSWORD_CHANGED: "Password changed successfully",
  PASSWORD_CHANGED_TEMP_PASSWORD: "Password changed successfully. Please login again with your new password.",
  OTP_CONFIRMED: "Otp confirmed successfully",

  TREATMENT_PLAN_DELETED: "Treatment plan deleted successfully.",
  TREATMENT_GOAL_DELETED: "Treatment goal deleted successfully.",
  TREATMENT_OBJECTIVE_DELETED: "Objective deleted successfully.",
  TREATMENT_INTERVENTION_DELETED: "Intervention deleted successfully.",
  REACTIVATE_CLIENT: "Client reactivated successfully.",

  //-------------------------- staff notifiation message --------------------------------//
  INACTIVE_STAFF: "Staff Deactivated Successfully",
  ACTIVE_STAFF: "Staff Reactivated Successfully",
  UPDATE_STAFF: "Staff profile updated successfully",
  ADD_STAFF: "Staff added successfully",
  ADD_POSITION: "Position added successfully",
  UPDATE_POSITION: "Position updated successfully",
  UPLOAD_PHOTO: "Profile photo successfully uploaded",
  DELETE_PHOTO: "Profile photo successfully deleted",
  TIME_ADDED: "Time added successfully",
  ADD_CREDENTIAL: "Credentials added successfully",
  DELETE_CREDENTIAL:"Credentials deleted successfully",
  UPDATE_CREDENTIAL: "Credentials Updated successfully",

  //-------------------------- general notifiation message --------------------------------//
  SUCCESS: "Success",

  TASK_DELETED: "Task deleted successfully.",
  DISCUSSION_DELETED: "Discussion deleted successfully.",
  DISCUSSION_UPDATED: "Discussion updated successfully.",
  SIGNATURE_DELETED: "Signature deleted successfully.",
  DOCUMENT_TEMPLATE_RENAME: "Document template rename successfully",

  //-------------------------- settings message --------------------------------//
  CLINIC_REFERRING_PROVIDER: "Referring provider added successfully.",
  UPDATED_REFERRING_PROVIDER: "Referring provider updated successfully.",

  CLINIC_REF_SOURCE: "Referring source added successfully.",
  UPDATED_REF_SOURCE: "Referring source updated successfully.",

  DOCUMENT_SETTINGS_UPDATED: "Document settings updated successfully.",
  CERTIFICATION_UPDATED: "Certification updated successfully.",
  CERTIFICATE_DELETE: "Certificate deleted successfully.",
  CERTIFICATION_ADDED: "Certification added successfully.",
  FLAG_ADDED: "Flag added successfully.",
  FLAG_DELETE: "Flag deleted successfully.",
  FLAG_UPDATED: "Flag updated successfully.",

  TAG_ADDED: "Tag added successfully.",
  TAG_DELETE: "Tag deleted successfully.",
  TAG_UPDATED: "Tag updated successfully.",
  SESSION_CLOSE: "Admin End the Call ",
  ADD_COMPLIANCE: "Add Compliance Successfully",
  UPDATE_COMPLIANCE: "Update Compliance Successfully",
  COMPLETE_COMPLIANCE: "Complete Compliance Successfully",
  DELETE_COMPLETE_COMPLIANCE: "Deleted Compliance Successfully",
  CHECK_COMPLIANCE: "Check Compliance Successfully",
  ASSIGN_FLAG_TO_CLINET: "Assigned  Flag to Selected Client Successfully ",
  CHANGE_CLIENT_STATUS_OF_SELECTED_CLIENTS: "Change Selected Client Status Successfully",
  INSERT_ELIGIBILITY: "Your eligibility check request has been taken. It may take 2 to 12 hours to process.",
  DELETE_DEFAULT_GOALS: "Goal Deleted Successfully",
  ASSIGN_DEFAULT_GOAL: "Goals Assigned to clients successfully",
  DELETE_SERVICE_SET: "Service Deleted Successfully",
  ASSIGN_SET_TO_STAFF: "Assign Set To Staff Successfully",
  ASSIGN_SET_TO_CLIENT: "Assign Set To Client Successfully",

  INSERT_SERVICE_SET: "Save Service Successfully ",
  UPDATE_SERVICE_SET: "Update Service Successfully ",
  SUBMIT_BILLING_ACTION: "Submit Billing Status Successfully ",
  INSERT_DEFAUT_GOALS_TEMPLATES: "Save  Default Goal Template",
  UPDATE_DEFAUT_GOALS_TEMPLATES: "Update  Default Goal Template",
  DELETE_DEFAUT_GOALS_TEMPLATES: "Delete  Default Goal Template",
  SUBMIT_PAYROLL_ACTION: "Payroll status submitted Successfully ",
  MOVE_CLIENT_SITE_SUCCESS: "Site is updated for selected Clients.",
  INSERT_REVIEW_GOAL: "Add Review Goal Successfully",

  ASSIGN_CERTIFICATE_TO_STAFF:"Certificate Assign To Staff Successfully",


  ADD_CALENDAR_CATEGORY:"Calendar Category Added Successfully",
  UPDATE_CALENDAR_CATEGORY:"Calendar Category Update Successfully",
  DELETE_CALENDAR_CATEGORY:"Calendar Category Delete Successfully",

  UPDATE_BILLING_STATUS:"Billing Status Updated Successfully",
  UPDATE_STAFF_COMMENT: "Staff Comment Updated",

  DELETE_ANNOUNCEMENTS:"Bulletin Deleted Successfully",
  SAVE_ANNOUNCEMENTS_LIST: "Bulletin Save Successfully",
  UPDATE_ANNOUNCEMENTS_LIST:"Bulletin updated Successfully",

  UPDATE_ICN:"ICN Updated Successfully",
  DELETE_CLIENTS_PRINTED_DOCUMENT:"Document Printed file deleted successfully"





};

export default NOTIFICATION_MESSAGE;
