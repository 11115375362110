import React, { ComponentState, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { ClientService } from "src/services/clientService";
import APP_ROUTES from "src/helper/app-routes";
import { renderErrors } from "src/helper/error-message-helper";
import NotificationManager from "react-notifications/lib/NotificationManager";

import { NichqHeader } from "./Nichq-heads";
import { QIDSheads } from "./QIDS-heads";






export const QIDS_SelfReport = ({ QIDS_SR, QIDS_SR_SCORING, QIDS_HEADERS, response, interfered, isPrint, questionId }) => {

  const [qidsSelfReport, setQidsSelfReport] = useState<any[]>([]);
  const { state } = useLocation();
  const selectedClientId = useSelector((state: ComponentState) => state.selectedClientId);
  const navigate = useNavigate();

  
  const [qidsSelfReportScoring, setQidsSelfReportScoring] = useState<any[]>([]);
  const [qidsSelfReportHeaders, setQidsSelfReportHeaders] = useState<any[]>([]);


  useEffect(() => {

    const listQidsSelfReport = QIDS_SR.map((item) => {
      const foundItem = response.find((i) => i.questionId === item.questionId);
      if (foundItem) {
        if (item.type === "question") {
          if (foundItem.value) {
            return { ...item, isSelected: true, value: foundItem.value };
          } else {
            return { ...item, isSelected: false, value: "" };
          }
        }
        return { ...item, ...foundItem };
      } else {
        return item;
      }
    });
    
    const QidsScoring = QIDS_SR_SCORING.map((item) => {
      const foundItem = response.find((i) => i.questionId === item.questionId);
      if (foundItem) {
        if (item.type === "question") {
          if (foundItem.value) {
            return { ...item, isSelected: true, value: foundItem.value };
          } else {
            return { ...item, isSelected: false, value: "" };
          }
        }
        return { ...item, ...foundItem };
      } else {
        return item;
      }
    });
    const QidsHeaders = QIDS_HEADERS.map((item) => {
      const foundItem = response.find((i) => i.questionId === item.questionId);
      if (foundItem) {
        if (item.type === "question") {
          if (foundItem.value) {
            return { ...item, isSelected: true, value: foundItem.value };
          } else {
            return { ...item, isSelected: false, value: "" };
          }
        }
        return { ...item, ...foundItem };
      } else {
        return item;
      }
    });


    setQidsSelfReport(listQidsSelfReport)
    setQidsSelfReportScoring(QidsScoring)
    setQidsSelfReportHeaders(QidsHeaders)



  }, [response])

  const handleSubmit = async () => {
    await ClientService.insertClientQuestionnaire(
      selectedClientId, 0,
      [
        ...qidsSelfReport, 
        ...qidsSelfReportScoring,
        ...qidsSelfReportHeaders,
      ],
      questionId
    )
      .then((result) => {
        let questionList = result.resultData;
        navigate(APP_ROUTES.QUESTIONNAIRE);
        NotificationManager.success("Add successfully");
      })
      .catch((error) => {
        // setLoading(false);
        renderErrors(error.message);
      });
  };

  return (
    <div  className="ml-3">
      <div className="mt-4 head-4"> 
          QUICK INVENTORY OF DEPRESSIVE SYMPTOMATOLOGY (SELF-REPORT)
          <br />
          (QIDS-SR) 
      </div>

      
      {
        qidsSelfReportHeaders.length > 0 &&
        <div className="row mt-4">
        <NichqHeader

          title={""}
          disabled={response && response.length > 0}
          subTitle={''}
          listItems={qidsSelfReportHeaders}
          setListItems={setQidsSelfReportHeaders}
        />
        </div>
      }

      <div>
    <label>  Please circle the one response to each item that best describes you for the past seven days. </label>
      </div>

      {
        qidsSelfReport.length > 0 &&
        <div className="row mt-4">
          <NichqHeader

            title={""}
            disabled={response && response.length > 0}
            subTitle={''}
            listItems={qidsSelfReport}
            setListItems={setQidsSelfReport}
          />
        </div>

      }

      <div>
      <label><b>Scoring Criteria:</b></label>


        <div className="row">
          <div className="col-md-4"><b>0-5 </b></div>
          <div className="col-md-8">Normal </div>
        </div>
        <div className="row">
          <div className="col-md-4"><b>6-10 </b></div>
          <div className="col-md-8">Mild </div>
        </div>
        <div className="row">
          <div className="col-md-4"><b>11-15 </b></div>
          <div className="col-md-8">Moderate </div>
        </div>
        <div className="row">
          <div className="col-md-4"><b>16-20 </b></div>
          <div className="col-md-8">Severe </div>
        </div>
        <div className="row">
          <div className="col-md-4"><b> &gt;&#61;  21 </b></div>
          <div className="col-md-8">Very Severe </div>
        </div>
      </div>
      <div className="mt-4">
        <label>To score:</label>
      </div>

      
      {
        qidsSelfReportScoring.length > 0 &&
        <div className="row mt-4">
        <QIDSheads

          title={""}
          disabled={response && response.length > 0}
          subTitle={''}
          listItems={qidsSelfReportScoring}
          setListItems={setQidsSelfReportScoring}
        /></div>
      }


      
      {state == null && !isPrint ? (
        <div className="text-start mt-3 col-12">
          <button onClick={handleSubmit} className="submitButon ">
            Submit
          </button>
        </div>
      ) : (
        ""
      )}






    </div>

  )

} 