
import { TextArea, TextAreaChangeEvent } from "@progress/kendo-react-inputs";
import { Error } from '@progress/kendo-react-labels';

export interface TextAreaKendoProps {
  name: string,
  txtValue?: string,
  onChange: (newValue: TextAreaChangeEvent) => void
  label: string,
  maxLength?: number,
  rows?: number,
  column?: number,
  required?: boolean,
  error?: any,
  valid?: boolean,
  title?: string,
  defaultValue?: string,
  placeholder?: string,
  resizable?:boolean
}

function TextAreaKendo(props: TextAreaKendoProps) {
  const {
    name,
    txtValue,
    onChange,
    label,
    maxLength,
    rows,
    column,
    required,
    error,
    valid,
    title,
    defaultValue,
    placeholder,
    resizable,
    ...others
  } = props;


  return (
    <div
    >
      <label className={ "k-label mb-0"} >{label}</label>
      <TextArea
        name={name}
        maxLength={maxLength}
        value={txtValue}
        onChange={onChange}
        title={title}
        rows={rows}
        cols={column}
        required={required}
        defaultValue={defaultValue}
        placeholder={placeholder}
        autoSize = {resizable}
      />
      {
        error &&
        <Error >
          {error}
        </Error>
      }
    </div>
  );
}

export default TextAreaKendo;
