import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import {
  GRID_COL_INDEX_ATTRIBUTE
} from "@progress/kendo-react-grid";
import { Checkbox, Rating } from "@progress/kendo-react-inputs";
import React, { ComponentState, useEffect, useRef, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  default as DeleteAssignedSupervisorModal,
  default as DeleteAttachmentModal,
  default as DeleteDialogModal,
  default as DeleteLinkedDocModal,
} from "../../control-components/custom-delete-dialog-box/delete-dialog";
import SealDocumentModal from "../../control-components/custom-delete-dialog-box/seal-document-dialog";
import LockDialogModal from "../../control-components/custom-lock-unlock-dialouge-box/lock-dialog";
import Loading from "../../control-components/loader/loader";
import CustomSkeleton from "../../control-components/skeleton/skeleton";
import useModelScroll from "../../cutomHooks/model-dialouge-scroll";
import apiHelper from "../../helper/api-helper";
import APP_ROUTES from "../../helper/app-routes";
import { ClientService } from "../../services/clientService";
import { showError } from "../../util/utility";
import { Encrption, telehealthDecrption } from "../encrption";
import { AddDocumentFileTemplate } from "./add-document-file-template";
import AddDocumentSignature from "./add-document-signature";
import {
  convertServerDocumentToLocal,
  convertTimeToLocally,
  timeRecordingTypeEnum,
  totHoursAndMinutes
} from "./document-utility";
import { mapDocumentTemplate } from "./template/document-template-utility";
import { PreviewDocumentTemplate } from "./template/preview-document-template";

import { useParams } from 'react-router-dom';
import { renderErrors } from "src/helper/error-message-helper";

import moment from "moment";
import { DocAttachements, DocClientSignature, DocCommonControllArray2, DocPrefsResponse, DocQuestionaries, DocSettings, DocSignature, DocumentByIdResponse, DocumentFieldsMapping, DocumentMileagesObj, FormattedDocument, LinkedDocs, TemplateResponse } from "src/dataModels/docListModel";
import { docUnitsChangedAction, getClientsMedication, getMileagePayRateByStaffId, getRiskDDl, getRiskProtectiveDDl, getRiskactorDDl, loaderAction, saveSelectedDocId, successCall ,getClientPrintedDocuments} from 'src/redux/actions';
import { API_ENDPOINTS } from "src/services/api-endpoints";
import { customAxios } from "src/services/useExPressApi";
import { permissionEnum, userIsAdmin, userIsSuperAdmin,userIsQA } from "../../helper/permission-helper";
import AssignSupervisor from "./assign-Supervisor-dialog";
import AttachQuestionnaire from "./attach-questionnaire";
import ViewBillingStatus from "./billing-status";
import LinkDocument from "./link-documents";
import AddDocumentTask from "./tasks/add-doc-task";
import ListDocumentTasks from "./tasks/list-doc-task";
import ViewLinkedQuestionire from "./view-linked-questionaire";
import trashIcon from "../../assets/images/trash2.png";
import { Chip } from "@progress/kendo-react-buttons";
import CreateDocZip from "./template/create-doc-zip";
import CommonActionDialog from "src/control-components/custom-delete-dialog-box/common-dialog-with-buttons";
const DiagnosisModel = React.lazy(() => import("./service/diagnosis-model"))
const ServiceModel = React.lazy(() => import("./service/service-model"))
const SiteOfServiceModel = React.lazy(() => import("./service/site-of-service-model"))
const ServiceDateModel = React.lazy(() => import("./service/service-date-model"))
const PlaceOfServiceModel = React.lazy(() => import("./service/place-of-service-model"));
const ViewTransModel = React.lazy(() => import("../clinic/service-auth/open-view-trans-model"));


export const levelOfRisk = [
  {
    label: "Low",
    value: 19,
  },
  {
    label: "Medium",
    value: 20,
  },
  {
    label: "High",
    value: 21,
  },
  {
    label: "Imminent",
    value: 22,
  },

];

const ACTION_ID = {
  approved: 2,
  postEditReview: 4,
  notApproved: 3,
  toBeReview: 1,
};

const ViewDocument = (props) => {
  const isBillingManager = useSelector(
    (state: any) => state.getRolePermission.isBillingManager
  );
  const state: ComponentState = useSelector(states => {
    return states;
  });
  const rolePermisson = useSelector((state: ComponentState) => state.getRolePermission);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const clientId = location?.state?.clientId
  const params = useParams();
  const staffId = useSelector((state: ComponentState) => state.loggedIn?.staffId);
  const [index, setIndex] = useState(props.index);
  const [tasks, setTasks] = useState([]);
  const [documentFormattedVal, setDocumentDetail] = useState<FormattedDocument>();
  const [documentId, setDocumentId] = useState<string | any>();
  const [selectedQuestionId, setSelectedQuestionId] = useState({});
  const [documentDraftId, setDocumentDraftId] = useState();
  const [canSealDocument, setCanSealDocument] = useState(false);
  const [isMultiView, setMultiView] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [backRoute, setBackRoute] = useState(APP_ROUTES.DOCUMENT_LIST);
  const [diagnosisList, setDiagnosisList] = useState([]);
  const [template, setTemplate] = useState<any>();
  const [confirm, setConfirm] = useState(false);
  const [modelScroll, setScroll] = useModelScroll();
  const [ratingVal, setRatingVal] = useState(0);
  const [isHtmlFileTypeTemplate, setHtmlFileTypeTemplate] = useState(false);
  const [htmlFileName, setHtmlFileName] = useState("");
  const [fieldsMapping, setFieldsMapping] = useState<DocumentFieldsMapping[]>([]);
  const [displaySignDialog, setDisplaySignDialog] = useState(false);
  const [docServiceDate, setServiceDate] = useState<string>();
  const [docSignature, setDocSignature] = useState<DocSignature[]>([]);
  const [docClientSignature, setDocClientSignature] = useState<DocClientSignature[]>([]);
  const [isDocumentDeleted, setDocumentDeleted] = useState<boolean>();
  const [docAttachment, setDocAttachment] = useState<DocAttachements[]>([]);
  const [linkedDocuments, setLinkedDocuments] = useState<LinkedDocs[]>([]);
  const [linkedQuestionnaire, setLinkedQuestionnaire] = useState<DocQuestionaries[]>([]);
  const [historyList, setHistoryList] = useState<[]>([]);
  const userAccessPermission = useSelector(
    (state: ComponentState) => state.userAccessPermission
  );
  const [isLockDocument, setLockDocument] = useState(false);
  const [isDocSealed, setSealDocumentVal] = useState(false);
  const [isOpenLock, setOpenLock] = useState(false);
  const staffInfo = useSelector((state: ComponentState) => state.getStaffReducer);
  const [signStaffId, setSignStaffId] = useState();
  const clinicId = useSelector((state: ComponentState) => state.loggedIn.clinicId);
  const [isDeleteAttachment, setDeleteAttachment] = useState(false);
  const [isDeleteLinkDoc, setDeleteLinkedDoc] = useState(false);
  const [isSealDocument, setIsSealDocument] = useState(false);
  const [docSettings, setDocSettings] = useState<DocSettings>();
  const [attachmentId, setAttachmentId] = useState();
  const [linkedDocId, setLinkedDocId] = useState();
  const [markAsReview, setMarkAsReview] = useState(false);
  const [alreadyMarkReview, setAlreadyMark] = useState(false);
  const [isParent, setIsParent] = useState(false);
  const [isShowMenus, setMenusShow] = useState(false);
  const [isLinkDoc, setLinkDoc] = useState(false);
  const [isTaskAndDocuments, setShowTaskAndComments] = useState(false);
  const [isDeleteAssociateSign, setDeleteAssociateSign] = useState(false);
  const taskListRef = useRef(null);
  const [isAttachQuestion, setAttachQuestion] = useState(false);
  const [deletedQuesionId, setDeletedQuestionId] = useState();
  const [isDeleteLinkQueston, setDeleteLinkQuestion] = useState(false);
  const [isViewQueston, setViewQuestion] = useState(false);
  const [isApprovedDoc, setApprovedDoc] = useState(false);
  const [billingClaimsManager, setBillingClaimsManager] = useState(false)
  const [isAssignSupervisorVisible, setAssignSupervisorClose] = useState(false);
  const [selectedSupervisorId, setSelectedSupervisorId] = useState(false);
  const staffLoginInfo = useSelector((state: ComponentState) => state.getStaffReducer);
  const [showConfirmAssignSupervisorDelete, setShowConfirmAssignSupervisorDelete] = useState(false);
  const { serviceLineDocId, docUnitsChanged, documentsDetail, documentBillingHistory, success, clientsMedicationResp } = state['DocumentReducer'];
  const [clientsMedication, setClientsMedication] = useState([]);
  const [isScreenInitialLoad, setIsScreenInitialLoad] = useState(false);
  const [documentCommonControls, setDocumentControls] = useState<DocCommonControllArray2>();
  const [docHasControlls, setDocHasControlls] = useState(false);
  const [staffSignatureDone, setStaffSignatureDone] = useState(false)
  const [isValidForMultiSign, setValidForMultiSign] = useState(props.validForMultiSign)
  const [isOpenServiceModel, setIsOpenServiceModel] = useState<boolean>(false)
  const [isOpenServiceDateModel, setIsOpenServiceDateModel] = useState<boolean>(false)
  const [isOpenDiagnosisModel, setIsOpenDiagnosisModel] = useState<boolean>(false)
  const [isOpenSiteOfServiceModel, setIsOpenSiteOfServiceModel] = useState<boolean>(false)
  const [isOpenPlaceOfServiceModel, setIsOpenPlaceOfServiceModel] = useState<boolean>(false)
  const [selectedServiceName, setSelectedServiceName] = useState()
  const [clientID, setClientID] = useState<string>()
  const [riskData, setRiskData] = useState<any>()
  const [showStaffSignature, setShowStaffSignature] = useState<boolean>(false)
  const [showSignatureLink, setShowSignatureLink] = useState(false)
  const [clientSiteList, setClientSiteList] = useState([]);
  const [selectedTransId, setSelectedTransId] = useState(null);
  const [isOpenViewTrans, setIsOpenViewTrans] = useState(false);
  const [clientName, setClientName] = useState("");
  const [docMileage, setDocMileage] = useState<DocumentMileagesObj>();
  const [clientFlagList, setClientFlagList] = React.useState([]);
  const [generateDocumentZip ,setGenerateDocumentZip] = useState(false);
  const [openConfirmPopup, setOpenConfirmPopup] = useState(false);
  const [prepareDocZipData, setPrepareDocZipData] = useState([]);

  const canDuplicateDocuments = state.getRolePermission.canDuplicateDocuments

  /* ============================= useEffects ============================= */

  useEffect(() => {
    if (documentFormattedVal?.clientId) {
      getClientSites();
      getClientFlags();
    }
  }, [documentFormattedVal?.clientId])

  useEffect(() => {
    if (template?.showMileage) {
      const data = {
        staffId: documentFormattedVal?.createdBy,
        serviceDate: moment(docServiceDate).format('YYYY-MM-DD')
      }
      dispatch(getMileagePayRateByStaffId(data))
    }
  }, [template])

  useEffect(() => {
      if( template?.showMedication &&  documentFormattedVal?.clientId) {
          // let clientArray = []
          // documentFormattedVal?.clientId.forEach((client) => {
          //     clientArray.push(client.id)
          // })
          const data = {
              clientIds: [documentFormattedVal?.clientId],
              isActive: true
          }
          dispatch(getClientsMedication(data))
      }
  }, [ template, documentFormattedVal ])

  useEffect(() => {
    if(clientsMedicationResp) {
      setClientsMedication(clientsMedicationResp)
    }
  }, [clientsMedicationResp])

  useEffect(() => {
    if (docUnitsChanged) {
      fetchByDocumentId(documentId)
      dispatch(docUnitsChangedAction(false))
    }
  }, [docUnitsChanged])

  useEffect(() => {
    if(!props.multiId){
      setMultiView(0);
    }
    if (props.multiId) {
      if (props.multiId !== documentId) {
        setDocumentId(props.multiId);
        setClientID(props?.clientId)
        setMultiView(1);
        setIsScreenInitialLoad(true);
      }
    } else if (location && location.state) {
      if (location.state.id !== documentId) {
        setDocumentId(location.state.id);
        setClientID(clientId)
        setIsScreenInitialLoad(true);
      }
      if (location.state.backRoute && location.state.backRoute !== backRoute) {

        setBackRoute(location.state.backRoute);
      }
      if (location.state.draftId && location.state.draftId !== documentDraftId) {
        setDocumentDraftId(location.state.draftId);
      }
    }
  }, []);


  useEffect(() => {
    if (params?.id) {
      let decUrl = telehealthDecrption(params?.id);
      setDocumentId(decUrl);
      setIsScreenInitialLoad(true);
    }
  }, []);

  useEffect(() => {
    if (serviceLineDocId && serviceLineDocId > 0) {
      setLoading(true);
      setDocumentId(serviceLineDocId);
      setIsScreenInitialLoad(true);
      dispatch(saveSelectedDocId(0));
      fetchByDocumentId(serviceLineDocId);
      setTimeout(() => {
        setLoading(false);
      }, 200);

    }
  }, [serviceLineDocId]);

  useEffect(() => {
    if (isScreenInitialLoad && documentFormattedVal) {
      if (documentFormattedVal.clientId) fetchDiagnosisList();
      if (documentFormattedVal.documentTemplateId) getDocumentTemplate();
    }
  }, [isScreenInitialLoad, documentFormattedVal]);

  useEffect(() => {
    if (linkedDocId) {
      setDocumentId(linkedDocId);
      setIsScreenInitialLoad(true);
      if (documentFormattedVal?.documentTemplateId) getDocumentTemplate();
    }
  }, []);


  useEffect(() => {
    if (isScreenInitialLoad && documentId) {
      fetchByDocumentId(documentId);
    }
  }, [isScreenInitialLoad, staffSignatureDone]);

  const getClientSites = () => {
    dispatch(loaderAction(true))
    customAxios.get(API_ENDPOINTS.GET_CLIENT_SITES_BY_CLIENTID + documentFormattedVal?.clientId)
      .then((result: any) => {
        const clientSites = result.resultData.clientSites;
        setClientSiteList(clientSites);
      })
      .catch((error) => { })
      .finally(() => dispatch(loaderAction(false)));
  };




  const getClientFlags = () => {

    dispatch(loaderAction(true))
    customAxios.get(API_ENDPOINTS.GET_CLIENT_FLAGS_BY_CLIENT_ID + documentFormattedVal?.clientId)
      .then((result: any) => {
        const clientFlags = result?.resultData;
        setClientFlagList(clientFlags);
      })
      .catch((error) => { })
      .finally(() => dispatch(loaderAction(false)));
  };

  async function getOtherInfo(docid: string) {
    const taskResult = await apiHelper.queryGetRequestWithEncryption(
      API_ENDPOINTS.GET_DOCUMENT_TASKS,
      docid
    );

    if (taskResult.resultData) {
      setTasks(
        taskResult.resultData.map((task) => {
          return {
            ...task,
            dueDate: task.dueDate,
          };
        })
      );
    }
  }

  useEffect(() => {
    if (success) {
      fetchByDocumentId(documentId)
      dispatch(successCall(""))
    }

  }, [documentId, success])


  function fetchTemplateSettings(value) {
    apiHelper
      .queryGetRequestWithEncryption(
        API_ENDPOINTS.GET_DOCUMENT_TEMPLATE_PREF_BY_TEMPLATE_ID,
        value
      )
      .then((result) => {
        if (result.resultData) {
          let showStaffSig = result.resultData.staffSignatureNotRequired ? false : true;
          setShowStaffSignature(showStaffSig);
        }
      })
  }

  function fetchByDocumentId(documentId: string) {
    apiHelper
      .queryGetRequestWithEncryption(API_ENDPOINTS.GET_DOCUMENT_BY_ID, documentId)
      .then(async (result: DocumentByIdResponse) => {
        if (result.resultData) {
          setClientID(result.resultData.clientId)
          setDocumentDetail(convertServerDocumentToLocal(result.resultData));
          setHtmlFileTypeTemplate(result.resultData.isHtmlFileTypeTemplate);
          setHtmlFileName(result.resultData.htmlFileName);
          setDocumentDeleted(result.resultData.isActive);
          setFieldsMapping(result.resultData.documentFieldsMappings);
          setServiceDate(result.resultData.serviceDate);
          setLockDocument(result.resultData.isLocked);
          setSealDocumentVal(result.resultData.isSealed);
          fetchDocumentSignature(documentId);
          fetchLinkedDocuments(documentId);
          fetchLinkedQuestionnaire(documentId);
          setMenusShow(true);
          setRatingVal(result.resultData.rating);
          setAlreadyMark(result.resultData?.isNoteReviewed);
          setMarkAsReview(result.resultData?.isNoteReviewed);
          setDocumentControls(result.resultData.documentCommonControls)
          const hadDx = (result.resultData?.documentCommonControls?.documentGoals?.length ?? 0 > 0) ? true : false
          const hasGoals = (result.resultData?.documentCommonControls?.documentDiagnosis?.length ?? 0 > 0) ? true : false
          setDocHasControlls(hadDx || hasGoals)
          setRiskData(result.resultData?.riskAssessment)
          fetchTemplateSettings(result.resultData?.documentTemplateId);
          getDocumentHistory(documentId)
        }

        // Get TASKs attached to this document
        const taskResult = await apiHelper.queryGetRequestWithEncryption(
          API_ENDPOINTS.GET_DOCUMENT_TASKS,
          documentId
        );
        if (taskResult.resultData) {
          setLoading(false)
          setTasks(
            taskResult.resultData.map((task) => {
              return {
                ...task,
                dueDate: task.dueDate,
              };
            })
          );
        }
      })
      .catch((err) => {
        setLoading(false)
        showError(err, "Fetch Document");
      })

  }

  async function fetchTasks() {
    setLoading(true);
    return apiHelper
      .queryGetRequestWithEncryption(API_ENDPOINTS.GET_DOCUMENT_TASKS, documentId)
      .then((taskResult) => {
        setLoading(false)
        if (taskResult.resultData) {
          setTasks(taskResult.resultData);
        }
      })
      .catch((err) => {
        showError(err, "Fetch Tasks");
        setLoading(false)
      })

  }

  useEffect(() => {
    fetchDocSettings();
  }, []);

  useEffect(() => {
    if (staffInfo?.id) {
      getStaffSettingList();
    }
  }, []);

  useEffect(() => {
    if (documentDraftId) {
      setLoading(true);
      apiHelper
        .queryGetRequestWithEncryption(
          API_ENDPOINTS.GET_DOCUMENT_DRAFT_BY_ID,
          documentDraftId
        )
        .then((result) => {
          if (result.resultData) {
            // setDocumentDetail(
            //   convertServerDocumentDraftToLocal(result.resultData)
            // );
            setDocumentDetail((result.resultData));
            setLoading(false)
          }
        })
        .catch((err) => {
          setLoading(false)
          showError(err, "Fetch Draft");
        })

    }
  }, [documentDraftId]);

  /* ============================= private functions ============================= */

  const insertStaffSignature = async (pin, sigDate, buttonName, sigTime) => {
    setLoading(true);
    await ClientService.InsertStaffDocumentSign(
      pin,
      sigDate,
      buttonName,
      documentId,
      sigTime,

    )
      .then(() => {
        setStaffSignatureDone(true)
        setLoading(false);
        NotificationManager.success("Signature applied successfully");
        setDisplaySignDialog(false);
        setScroll('false');
        if (documentId) {
          fetchDocumentSignature(documentId);
        }

      })
      .catch((error) => {
        setLoading(false);
        renderErrors(error);
      });
  };

  const handleSetDocumentPrint = async (documentId) => {
    const body = [documentId];
    try {
      await customAxios.post(`${API_ENDPOINTS.SET_DOCUMENT_PRINT}`, body);
    } catch (err) {
      console.error('Error Set Document Print:', err);
    }
  };
  const insertClientSignature = async (pin, sigDate, sigTime) => {
    setLoading(true);
    await ClientService.InsertClientDocumentSign(
      pin,
      sigDate,
      sigTime,
      documentId,
      isParent,
      documentFormattedVal?.clientId
    )
      .then(() => {
        setLoading(false);
        NotificationManager.success("Signature applied successfully");
        setDisplaySignDialog(false);
        setScroll('false');
        if (documentId) {
          fetchDocumentSignature(documentId);
        }
      })
      .catch((error) => {
        setLoading(false);
        renderErrors(error);
      });
  };

  function getDocumentTemplate() {
    // setLoading(true);
    apiHelper
      .queryGetRequestWithEncryption(
        API_ENDPOINTS.GET_DOCUMENT_TEMPLATE_BY_ID,
        documentFormattedVal?.documentTemplateId
      )
      .then((result: TemplateResponse) => {
        if (result.resultData) {
          const mappedTemplate = mapDocumentTemplate(result.resultData);
          setTemplate(mappedTemplate);
          setLoading(false)
          if (mappedTemplate.staffSignatureNotRequired === false || mappedTemplate.canApplyClientSig === true) {
            setShowSignatureLink(true);
          }
        }
      })

      .catch((error) => {
        setLoading(false)
        showError(error, "Fetch Template");
      })

  }

  function deleteDraft() {
    setLoading(true);
    apiHelper
      .deleteRequestWithEncryption(
        API_ENDPOINTS.DELETE_DOCUMENT_DRAFT_BY_ID,
        documentDraftId
      )
      .then((_) => {
        onBack();
        setLoading(false)
      })
      .catch((err) => {
        showError(err, "Delete Draft");
        setLoading(false)
      })

  }

  const getStaffSettingList = () => {
    setLoading(true);
    let id = Encrption(staffInfo.id);
    apiHelper
      .getRequest(API_ENDPOINTS.GET_STAFF_SETTING + id)
      .then((result) => {
        const data = result.resultData;
        setCanSealDocument(data.canSealDocuments);
        setBillingClaimsManager(data?.isBillingManager)
        setLoading(false);
      })
      .catch((error) => {
        renderErrors(error.message);
        setLoading(false);
      });
  };

  function fetchDocSettings() {
    apiHelper
      .queryGetRequestWithEncryption(
        API_ENDPOINTS.GET_DOCUMENT_PREFS_BY_CLINIC_ID,
        clinicId
      )
      .then((result: DocPrefsResponse) => {
        if (result.resultData) {
          if (result.resultData) {
            const resultData = result?.resultData[0]
            setLoading(false)
            if (resultData) {
              const settings: DocSettings = {
                canDuplicateDocuments: resultData.allowStaffToDuplicateThierOwnDocs === 1,
                canSealDocument: resultData.canSealDocument === 1,
                canChooseSigDate: resultData.canChooseSigDate === true,
                numDaysApplySigAfterDos: resultData.numDaysApplySigAfterDos,
                numDaysAllowedInPast: resultData.numDaysAllowedInPast,
                showDocumentHistoryOnPrint: resultData.showDocumentHistoryOnPrint,
              };
              setDocSettings(settings);
            }
          }
        }
      })
      .catch((err) => {
        setLoading(false)
        showError(err, "Document Settings");
      })

  }

  function deleteDocument() {
    setLoading(true);
    apiHelper
      .deleteRequestWithEncryption(API_ENDPOINTS.DELETE_DOCUMENT, documentId)
      .then(() => {
        setLoading(false)
        NotificationManager.success("Document deleted successfully");
        //  onBack();
        setDocumentDeleted(false);
        setConfirm(false);
        setScroll('false');
      })
      .catch((err) => {
        setLoading(false)
        showError(err, "Delete Document");
      })

  }

  function unTrashDocument() {
    setLoading(true);
    apiHelper
      .queryGetRequestWithEncryption(API_ENDPOINTS.UNTRASH_DOCUMENT, documentId)
      .then(() => {
        NotificationManager.success("Document restored successfully");
        //  onBack();
        setDocumentDeleted(true);
        setConfirm(false);
        setScroll('false');
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        showError(err, "Restore Document");
      })

  }

  function deleteAttachment() {
    setLoading(true);
    apiHelper
      .deleteRequestWithEncryption(
        API_ENDPOINTS.DELETE_DOCUMENT_ATTACHMENT,
        attachmentId
      )
      .then(() => {
        NotificationManager.success("Attachment deleted successfully");
        hideConfirmPopup();
        setLoading(false)
        if (documentId != null) {
          fetchDocumentAttachment(documentId);
        }
      })
      .catch((err) => {
        setLoading(false)
        showError(err, "Delete Attachment");
      })

  }

  function handleDocumentRating(value) {
    const body = {
      rating: value,
      documentId: documentId,
    };
    apiHelper
      .postRequest(API_ENDPOINTS.POST_DOCUMENT_RATING, body)
      .then(() => {
        if (documentId) {
          fetchByDocumentId(documentId);
        }
        // NotificationManager.success("Document rating done successfully");
      })
      .catch((error) => {
        showError(error, "Rating Document");
      });
  }

  function handleSealedDocument() {
    const body = {
      isSealed: isDocSealed === false ? true : false,
      documentId: documentId,
    };
    apiHelper
      .postRequest(API_ENDPOINTS.POST_SEAL_DOCUMENT, body)
      .then(() => {
        var sucessMsg = isDocSealed ? "Document unsealed successfully" : "Document sealed successfully";
        NotificationManager.success(sucessMsg);
        setSealDocumentVal(body.isSealed);
        setIsSealDocument(false);
        setScroll('false');
      })
      .catch((error) => {
        showError(error, "Seal Document");
      });
  }

  function deleteLinkedDoc() {
    setLoading(true);
    apiHelper
      .deleteRequestWithEncryption(API_ENDPOINTS.DELETE_LINKED_DOCUMENT, linkedDocId)
      .then(() => {
        NotificationManager.success("Linked document deleted successfully");
        hideConfirmPopup();
        fetchLinkedDocuments(documentId);
        setLoading(false);
      })
      .catch((err) => {
        showError(err, "Delete Attachment");
        setLoading(false);
      })

  }

  function deleteLinkedQuestionnaire() {
    setLoading(true);
    apiHelper
      .deleteRequestWithEncryption(
        API_ENDPOINTS.DELETE_LINKED_QUESTIONNAIRE,
        deletedQuesionId
      )
      .then(() => {
        setLoading(false)
        NotificationManager.success(
          "Linked questionnaire deleted successfully"
        );
        hideConfirmPopup();
        fetchLinkedQuestionnaire(documentId);
      })
      .catch((err) => {
        setLoading(false)
        showError(err, "Delete Questionnaire");
      })

  }

  function fetchDocumentAttachment(id: string) {
    apiHelper
      .queryGetRequestWithEncryption(
        API_ENDPOINTS.GET_DOCUMENT_ATTACHMENT,
        id
      )
      .then((result) => {
        setLoading(false)
        setDocAttachment(result.resultData);
      })
      .catch((err) => {
        setLoading(false)
        showError(err, "Error while fetching document attachment");
      })

  }

  function fetchDocumentSignature(id: string) {
    apiHelper
      .queryGetRequestWithEncryption(API_ENDPOINTS.GET_STAFF_DOCUMENT_SIGNATURE, id)
      .then((result) => {
        let sign = result.resultData;
        sign.forEach((element) => {
          let id = element.staffId;
          setSignStaffId(id);
        });
        setDocSignature(result.resultData);
        fetchDocumentAttachment(id);
        fetchClientDocumentSignature(id);
      })
      .catch((err) => {
        showError(err, "Error while fetching document signature");
      })

  }
  function fetchClientDocumentSignature(id) {
    apiHelper
      .queryGetRequestWithEncryption(API_ENDPOINTS.GET_CLIENT_DOCUMENT_SIGNATURE, id)
      .then((result) => {
        setDocClientSignature(result.resultData);
      })
      .catch((err) => {
        showError(err, "Error while fetching document signature");
      })

  }

  function fetchLinkedDocuments(id) {
    apiHelper
      .queryGetRequestWithEncryption(API_ENDPOINTS.GET_DOCUMENTS_BY_DOCUMENT_ID, id)
      .then((result) => {
        setLinkedDocuments(result.resultData);
      })
      .catch((err) => {
        showError(err, "Error while fetching linked documents");
      })

  }

  function fetchLinkedQuestionnaire(id) {
    apiHelper
      .queryGetRequestWithEncryption(
        API_ENDPOINTS.GET_QUESTIONNAIRE_BY_DOCUMENT_ID,
        id
      )
      .then((result) => {
        setLinkedQuestionnaire(result.resultData);
      })
      .catch((err) => {
        showError(err, "Error while fetching linked questionnaire");
      })

  }


  function fetchDiagnosisList() {
    const body = {
      clientIds: [documentFormattedVal?.clientId],
      isActive: true,
    };
    apiHelper
      .postRequest(API_ENDPOINTS.GET_CLIENTS_DIAGNOSIS, body)
      .then((result) => {
        const data = result.resultData;
        setDiagnosisList(data);
      })
      .catch((error) => {
        showError(error, "Fetch Diagnosis List");
      });
  }

  function lockUnLockDocument() {
    let docArry: string[] = [];
    docArry.push(documentId ?? '');
    const body = {
      ids: docArry,
      isLock: isLockDocument === false ? true : false,
      deleteAssociateSigns: isDeleteAssociateSign,
    };
    apiHelper
      .postRequest(API_ENDPOINTS.LOCK_UNLOCK_DOCUMENTS, body)
      .then(() => {
        if (isLockDocument === true) {
          NotificationManager.success("Document unlocked successfully");
          setLockDocument(false);
          if (documentId) {
            fetchDocumentSignature(documentId);
          }
          setDeleteAssociateSign(false);

        } else {
          setLockDocument(true);
          NotificationManager.success("Document locked successfully");
        }
        fetchByDocumentId(documentId)
        hideLockPopUp();
      })
      .catch((error) => {
        showError(error, "Lock Documents");
      });
  }

  function approveDisapproveDoc(status) {
    let docArry: string[] = [];
    docArry.push(documentId ?? '');
    const body = {
      docIds: docArry,
      isApproved: status,
    };
    apiHelper
      .postRequest(API_ENDPOINTS.APPROVE_DISAPPROVE_DOCUMENTS, body)
      .then(() => {
        if (status === true) {
          NotificationManager.success("Document approved successfully");
          setApprovedDoc(false);
          if (documentId) {
            fetchByDocumentId(documentId);
          }
        } else {
          setApprovedDoc(true);
          NotificationManager.success("Document not approved");
          if (documentId) {
            fetchByDocumentId(documentId);
          }
        }
      })
      .catch((error) => {
        showError(error, "Lock Documents");
      });
  }

  const handleApproved = () => {
    setApprovedDoc(true);
    approveDisapproveDoc(true);
  };

  const handleNotApproved = () => {
    setApprovedDoc(false);
    approveDisapproveDoc(false);
  };

  /* ============================= Event functions ============================= */

  const handleLockDocument = () => {
    setOpenLock(true);
  };

  const handleViewHistory = () => {
    navigate(APP_ROUTES.DOCUMENT_HISTORY, {
      state: {
        id: documentId,
        documentName: documentFormattedVal,
      },
    });
  };

  const hideLockPopUp = () => {
    setOpenLock(false);
    setScroll('false');
  };

  const onTaskDialogClose = () => {
    setShowTaskAndComments(!isTaskAndDocuments);
    // setScroll('false');
    // if (isTaskAndDocuments) {
    //   setScroll('false')
    // } else {
    //   setScroll('true')
    // }

  };

  const onTaskAdded = ({ added }) => {
    if (added) {
      fetchTasks();
      // taskListRef.current.focus()
    }
  };

  const currencyFormat = (num) => {
    const sign = num < 0 ? '-' : '';
    const formattedNumber = Math.abs(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    return `${sign}$${formattedNumber}`;
  };

  const handleTaskAndComments = () => {
    setShowTaskAndComments(true);
    // setScroll('true');
  };

  const handleConfirm = () => {
    setConfirm(true);
    setScroll('true');
  };

  const hideConfirmPopup = () => {
    setConfirm(false);
    setScroll('false');
    setDeleteAttachment(false);
    setDeleteLinkedDoc(false);
    setIsSealDocument(false);
    setDeleteLinkQuestion(false);
  };

  function onBack() {
    navigate(backRoute);
  }

  function handleDelete() {
    if (documentDraftId) {
      deleteDraft();
    } else {
      if (isDocumentDeleted) {
        deleteDocument();
      } else {
        unTrashDocument();
      }
    }
  }

  function handleEdit() {
    navigate(documentId ? APP_ROUTES.DOCUMENT_EDIT : APP_ROUTES.DOCUMENT_ADD, {
      state: {
        id: documentId,
        draftId: documentDraftId,
        backRoute: APP_ROUTES.DOCUMENT_VIEW,
      },
    });
  }

  /* ============================= render functions ============================= */

  const handleDeleteAttachment = (id) => {
    setAttachmentId(id);
    setDeleteAttachment(true);
  };

  const handleDeleteDoc = (id) => {
    setLinkedDocId(id);
    setDeleteLinkedDoc(true);
  };

  const handleDeleteQuestionnaire = (id) => {
    setDeletedQuestionId(id);
    setDeleteLinkQuestion(true);
  };

  const viewLinkedDocument = (id: string) => {
    if (id !== undefined && id.length > 0) {
      setDocumentId(id);
      fetchByDocumentId(id);
      fetchDocumentSignature(id);
      fetchClientDocumentSignature(id);
      fetchLinkedDocuments(id);
      fetchLinkedQuestionnaire(id);
      fetchDocumentAttachment(id);
      fetchDiagnosisList();
      window.scrollTo(0, 0);
    }
  };

  const viewLinkedQuestionaire = (obj) => {
    setViewQuestion(true);
    setSelectedQuestionId(obj);
    setScroll('true');
  };

  const handleCloseQuestion = () => {
    setViewQuestion(false);
    setSelectedQuestionId({});
    setScroll('false');
  };
  const onCloseBilling = () => {
    if (documentId) {
      fetchByDocumentId(documentId);
    }
  }
  const handleAssignSupervisorClose = () => {
    setAssignSupervisorClose(false);
  };
  const handleAssignSupervisorView = () => {
    setAssignSupervisorClose(true);
  }
  const handleViewTrans = (id, open, ClientName) => {
    setSelectedTransId(id);
    setIsOpenViewTrans(!isOpenViewTrans);
    setClientName(ClientName);
  }


  const handleSavePdfPrint = async() => {
    const response:any= await customAxios.get<any>(`${API_ENDPOINTS.GET_CLIENTS_PRINTED_DOCUMENT_BY_DOCUMENT_ID}?documentId=${documentId}`);
       if (response?.resultData)
       {
         setOpenConfirmPopup(true)
       }
       else if(!response?.resultData)
       {
         genrateZipFile()
       }
   }
 
   const genrateZipFile = () =>{
      
     let documentHistories: any[] = []
 
     if (docSettings && docSettings?.showDocumentHistoryOnPrint) {
       documentHistories = historyList;
     }
 
     const newState: any = [{
       staffInfo: staffInfo,
       documentDetail: documentFormattedVal,
       diagnosisList: diagnosisList,
       docSignature: docSignature,
       docClientSignature: docClientSignature,
       isHtmlFileTypeTemplate: isHtmlFileTypeTemplate,
       htmlFileName: htmlFileName,
       template: template,
       fieldsMapping: fieldsMapping,
       documentId: documentId,
       docTreatmentPlans: documentFormattedVal?.documentTreatmentPlans,
       docHasControlls: docHasControlls,
       documentCommonControls: documentCommonControls,
       historyList: documentHistories,
     }];
     if (mileagePayRate && template.showMileage) {
       newState[0].mileagePayRate = mileagePayRate;
     }
 
     localStorage.setItem('riskList', JSON.stringify(riskList));
     localStorage.setItem('riskFactorList', JSON.stringify(riskFactorList));
     localStorage.setItem('riskProtectiveList', JSON.stringify(riskProtectiveList));
     localStorage.setItem('print-doc-state', JSON.stringify(newState));
 
     setPrepareDocZipData(newState);
     setGenerateDocumentZip(true)
    
   };

   const handleCloseConfirmationDialog = () =>{
    setOpenConfirmPopup(false);
   }

   const handleYesOperation = () =>{
     setOpenConfirmPopup(false);
     genrateZipFile();
   }
 


  function renderAttachments(isDocSealed) {
    const li = docAttachment.map((file: DocAttachements, index) => {
      return (
        <li key={index} className="ml-2 mx-1 cursor-pointer d-flex align-items-center">
          <a href={file.fileUrl} target="_blank" download>
            {file.fileName}
          </a>
          {isDocSealed === false && (
            <button
              title="Delete"
              className=" bg-transparent border-0  fa-regular fa-circle-xmark"
              onClick={() => {
                handleDeleteAttachment(file.id);
              }}
            ></button>
          )}
        </li>
      );
    });
    return (
      <ul className="d-flex flex-wrap upload-attachemnt list-unstyled file-attachment mt-3 align-items-center">
        {li}
      </ul>
    );
  }

  function renderLinkedDocs(isDocSealed: any) {
    const li = linkedDocuments.map((obj: LinkedDocs, index) => {
      return (
        <li key={index} className="ml-2 mx-1 cursor-pointer valueColor fw-600 d-flex align-items-center">
          <a
            onClick={() => {
              viewLinkedDocument('' + obj.linkedDocId);
            }}
          >
            {obj.docName}
          </a>
          {isDocSealed === false && (
            <button
              title="Delete"
              className=" bg-transparent border-0 pr-3 fa-regular fa-circle-xmark"
              onClick={() => {
                handleDeleteDoc(obj.id);
              }}
            ></button>
          )}
        </li>
      );
    });
    return (
      <ul className="d-flex flex-wrap upload-attachemnt list-unstyled file-attachment mt-3 ">
        {li}
      </ul>
    );
  }

  function renderLinkedQuestionnaire() {
    const li = linkedQuestionnaire.map((obj: DocQuestionaries, index) => {
      return (
        <li key={index} className="ml-2 mx-1 cursor-pointer valueColor fw-600 d-flex align-items-center">
          <a
            onClick={() => {
              viewLinkedQuestionaire(obj);
            }}
          >
            [{moment.utc(obj.utcDateCreated).local().format("M/D/YYYY")}]{" "}
            {obj.shortName} - {obj.fullName}
          </a>

          {!isDocSealed && (
            <button
              title="Delete"
              className=" bg-transparent border-0 pr-3 fa-regular fa-circle-xmark"
              onClick={() => {
                handleDeleteQuestionnaire(obj.id);
              }}
            ></button>
          )}
        </li>
      );
    });
    return (
      <ul className="d-flex flex-wrap upload-attachemnt list-unstyled file-attachment mt-3">
        {li}
      </ul>
    );
  }

  function renderVerticalPair(headerText: any, contentText: any, dontAddParentDiv: boolean = false) {
    if (dontAddParentDiv) {
      return <>
        <p className="docTitle">{headerText}</p>
        {contentText && <p className="docContent">{contentText}</p>}
        {!contentText && <CustomSkeleton shape="text" />}
      </>
    }
    return <div className="p-0 col-md-6">
      <p className="docTitle">{headerText}</p>
      {contentText && <p className="docContent">{contentText}</p>}
      {!contentText && <CustomSkeleton shape="text" />}
    </div>
  }

  function renderTreatmentPlans() {
    return (
      <div className="form-group mt-3 pl-0 col-md-12">
        <p className="mb-3 docTitle">Treatment Plan</p>
        <div className="row">
          {documentFormattedVal?.documentTreatmentPlans && (
            <div className="col-lg-12 col-12 mb-3">
              <div>
                {documentFormattedVal?.documentTreatmentPlans.length === 0 ? (
                  <p className="docContent">No Selected Treatment Plans</p>
                ) : (
                  documentFormattedVal?.documentTreatmentPlans.map((plan, index) => {
                    const goalText = plan.goalName + (plan.goalDescription ? ` (${plan.goalDescription})` : ' (N/A)');
                    return (
                      <div key={index} className="treament-text ml-2 mb-2">
                        <div className="tex-show">
                          <div>
                            <p className="docTitle">Goal:</p>
                            <p className="docContent">{goalText}</p>
                            <p className="docTitle">Obj:</p>
                            <p className="docContent">{plan.objectiveName}</p>
                            <p className="docTitle">Int:</p>
                            <p className="docContent">{plan.interventionName}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  function renderMedicationSection() {
    const currentDate = moment();

    const isEndDateValid = (endDate) => {
        return (
            endDate &&
            moment(endDate, moment.ISO_8601, true).isValid() && 
            endDate !== "0001-01-01T00:00:00"
        );
    };

    const getOngoingMedications = (medications) => {
        return medications?.filter(
            (medication) =>
                moment(medication.startDate)
                    .startOf("day")
                    .isSameOrBefore(currentDate.startOf("day")) &&
                (!isEndDateValid(medication.endDate) || 
                 moment(medication.endDate)
                    .startOf("day")
                    .isSameOrAfter(currentDate.startOf("day")))
        );
    };

    const renderMedicationDetails = (medication) => (
        <div className="col-lg-12 col-12 mb-3" key={medication.id}>
            <div className="p-2 border mt-2">
                <p className="docTitle">Medication Name:</p>
                <p className="docContent">{medication.medicationName}</p>
                <div className="ml-0 row docTitle">
                    <p className="ml-0 docTitle">Dosage:</p>
                    <p className="docContent">{medication.dosage}</p>
                    <p className="ml-4 docTitle">Date:</p>
                    <p className="docContent">
                        {moment(medication.startDate).format("MM/DD/YYYY")} to{" "}
                        {isEndDateValid(medication.endDate)
                            ? moment(medication.endDate).format("MM/DD/YYYY")
                            : "till now"}
                    </p>
                </div>
            </div>
        </div>
    );

    const renderClientMedications = (client, clientIndex) => {
        const ongoingMedications = getOngoingMedications(client.medication);
        if (!ongoingMedications || ongoingMedications.length === 0) return null;

        return (
            <div key={clientIndex} className="col-12 mb-3">
                <h6 className="docTitle">{client.clientName}</h6>
                {ongoingMedications.map(renderMedicationDetails)}
            </div>
        );
    };

    return (
        <div className="form-group mt-3 pl-0 col-md-12">
            <h6 className="mb-3 docTitle">Client(s) Medication</h6>
            <div className="row">
                {clientsMedication && clientsMedication.length > 0 ? (
                    clientsMedication.map(renderClientMedications)
                ) : (
                    <p>No medications available.</p>
                )}
            </div>
        </div>
    );
}


  function renderMileageSection() {
    return (
      <div className="form-group mt-3 pl-0 col-md-12">
        <p className="mb-3 docTitle">Document Mileage</p>
        <div className="row">
          {documentFormattedVal?.documentTreatmentPlans && (
            <div className="col-lg-12 col-12 mb-3">
              {(documentCommonControls?.documentMileages ?? []).length > 0 ? (
                <div className="col-12 p-0 mx-2">
                  {(documentCommonControls?.documentMileages ?? []).map((mileage, index) => (
                    <div key={index} className="p-2 border mt-2">
                      {mileage.totalMileage > 0 ? (
                        <>
                          <p className="printableHeader">Mileage:</p>
                          <p className="printableContent">
                            {mileage.mileageTypeId === 1 ? 'By Address' : 'By Odometer'}
                          </p>
                          {mileage.mileageTypeId === 1 ? (
                            <>
                              <p className="docTitle">Start Address:</p>
                              <p className="docContent">{mileage.startAddress}</p>
                              <p className="docTitle">Destination Address:</p>
                              <p className="docContent">{mileage.endAddress}</p>
                              <p className="docTitle">Total Mileage:</p>
                              <p className="docContent">{mileage.totalMileage} mi</p>
                              <p className="docTitle">Total Amount:</p>
                              <p className="docContent">
                                {currencyFormat(mileage?.totalMileage * mileagePayRate?.rateAmt)}
                              </p>
                            </>
                          ) : (
                            <>
                              <p className="docTitle">Start Odometer:</p>
                              <p className="docContent">{mileage.odoStart} mi</p>
                              <p className="docTitle">End Odometer:</p>
                              <p className="docContent">{mileage.odoEnd} mi</p>
                              <p className="docTitle">Total Mileage:</p>
                              <p className="docContent">{mileage.totalMileage} mi</p>
                              <p className="docTitle">Total Amount:</p>
                              <p className="docContent">
                                {currencyFormat(mileage?.totalMileage * mileagePayRate?.rateAmt)}
                              </p>
                            </>
                          )}
                        </>
                      ) : (
                        <p className="docContent">N/A</p>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <p className="docContent">N/A</p>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }




  const { riskList, riskFactorList, riskProtectiveList, mileagePayRate } = state['DocumentReducer']


  useEffect(() => {
    // if (template?.) {
    dispatch(getRiskDDl("riskArea"))
    dispatch(getRiskactorDDl("riskFactors"))
    dispatch(getRiskProtectiveDDl("protectiveFactors"))
    // }

  }, [])

  // const [riskDDLData, setRiskDDLData] = useState([])
  const [riskListData, setRiskListData] = useState<any>([])

  // useEffect(() => {
  //   if (riskList) {
  //     setRiskDDLData(riskList)
  //   }
  // }, [riskList])


  useEffect(() => {
    if (riskData?.riskAssessmentDetails.length > 0) {
      setRiskListData(riskData?.riskAssessmentDetails?.map((item: any) => {

        const areaLevel = levelOfRisk?.find((data: any) => {
          return data?.value === item?.riskLevelId
        })
        const areaRisk = riskList?.find((data: any) => {
          return data?.id === item?.areaRiskId
        })
        // Map protective factors IDs to corresponding objects
        const protectiveFactors = item.protectiveFactorIds?.map((protectiveFactorId: any) => {
          return riskProtectiveList?.find((data: any) => data.id === protectiveFactorId);
        });

        // Map risk factors IDs to corresponding objects
        const riskFactors = item.riskFactorIds?.map((riskFactorId: any) => {
          return riskFactorList?.find((data: any) => data.id === riskFactorId);
        });
        return {
          documentRiskId: item?.documentRiskId,
          riskArea: areaRisk,
          riskLevelId: areaLevel,
          intentToAct: item?.intentToAct,
          meansToAct: item?.meansToAct,
          planToAct: item?.planToAct,
          protectiveFactors: protectiveFactors,
          riskFactors: riskFactors,
          additionalInfo: item?.additionalInfo
        }
      }))
    }

  }, [riskData])

  function getDocumentHistory(documentId: string) {
    setLoading(true);
    apiHelper
      .queryGetRequestWithEncryption(
        API_ENDPOINTS.DOCUMENT_HISTORY,
        documentId
      )
      .then((result) => {
        setHistoryList(result.resultData);
        setLoading(false);
      })
      .catch((err) => {
        showError(err, "History Document");
        setLoading(false);
      })

  }

  function renderRiskAssessment() {
    const isAnyRiskData = riskListData && riskListData.length > 0
    return (
      <div className="form-group mt-3 pl-0 col-md-12">
        <p className="mb-3 docTitle">Risk Assessment</p>
        <div className="row">
          {!isAnyRiskData && <p className="docContent">No Selected Risk Assessment</p>}
          {isAnyRiskData && (
            <div className="col-lg-12 col-12 mb-3">
              <div>
                {riskListData.map((risk: any, index: number) => {
                  return (
                    <div key={index} className="treament-text ml-2 mb-2">
                      <div className="tex-show">
                        <div>
                          <p className="docTitle">Area of Risk: </p>
                          <p className="docContent">{risk.riskArea?.name}</p>
                          <p className="docTitle ">Level of Risk: </p>
                          <p className="docContent">{risk.riskLevelId?.label}</p>
                          <p className="docTitle">Intent to Act: </p>
                          <p className="docContent">{risk.intentToAct}</p>
                          <p className="docTitle ">Plan to Act: </p>
                          <p className="docContent">{risk.planToAct}</p>
                          <p className="docTitle">Means to Act: </p>
                          <p className="docContent">{risk.meansToAct}</p>
                          <p className="docTitle ">Risk factors: </p>
                          <p className="docContent">{risk.riskFactors && risk.riskFactors.length > 0 ? (
                            <ul>
                              {risk.riskFactors.map((item, index) => (
                                <li key={index}>{item?.name}</li>
                              ))}
                            </ul>
                          ) : (
                            <p>No risk factors found.</p>
                          )}</p>
                          <p className="docTitle">Protective Factors: </p>
                          <p className="docContent">{risk.protectiveFactors && risk.protectiveFactors.length > 0 ? (
                            <ul>
                              {risk.protectiveFactors.map((item, index) => (
                                <li key={index}>{item?.name}</li>
                              ))}
                            </ul>
                          ) : (
                            <p>No protective factors found.</p>
                          )}</p>
                          <p className="docTitle">Additional Information: </p>
                          <p className="docContent">{risk.additionalInfo}</p>
                        </div>
                      </div>
                    </div>
                  )
                }
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  function renderHeader(headerText: any) {
    return <p className="docTitle">{headerText}</p>
  }

  function renderContent(contentText: any) {
    return <p className="docContent">{contentText}</p>
  }


  function renderCommonControlls() {
    return (
      <div className="form-group  pl-0 col-md-12">
        <div className="row">
          {documentCommonControls?.documentGoals && documentCommonControls?.documentGoals.length > 0 && (
            <div className="col-lg-12 col-12 mb-3">
              <p className="mb-3 docTitle">Goal</p>
              {
                documentCommonControls?.documentGoals.map((goal) => {
                  return <div>
                    <div className="col-12 m-0 child_goal ">
                      <div className="row m-0 align-items-center">
                        <div className="form-group mb-1 mt-1 pl-0 col-lg-12 col-lg-12">
                          {renderHeader("Goal Name")}
                          {renderContent(goal.goalName)}
                        </div>

                        <div className="form-group mb-1 mt-1 pl-0 col-md-12">
                          {renderHeader("Start Date")}
                          {renderContent(goal.startDate && moment(goal.startDate).format('M/D/YYYY'))}
                        </div>

                        <div className="form-group mb-1 mt-1 pl-0 col-md-12">
                          {renderHeader("Target Date")}
                          {renderContent(goal.targetDate && moment(goal.targetDate).format('M/D/YYYY'))}
                        </div>

                        <div className="form-group mb-1 mt-1 pl-0 col-md-12">
                          {renderHeader("Description")}
                          {renderContent(goal.goalDescription)}
                        </div>

                        <div className="form-group mb-1 mt-1  col-md-12 pl-0">
                          {goal && goal.objectives && goal.objectives.length > 0 &&
                            goal?.objectives.map((objective) => {
                              return <div className='row m-0 borderTop pb-3'>
                                {index != 0 && <hr className="hr-line" />}
                                <div className='col-md-12 pl-0'>
                                  {renderHeader("Objective")}
                                  {renderContent(objective.objective)}
                                </div>
                                <div className='col-md-12 p-0'>
                                  {
                                    objective.interventions && objective.interventions.length > 0 &&
                                    objective.interventions.map((intervention, index) => {

                                      const commaSeparatedServiceTypes = intervention.serviceTypes?.map(item => item.serviceTypes).join(", ");
                                      const commaSeparatedStaffTypes = intervention.staffTypes?.map(item => item.name).join(", ");

                                      return (<div >
                                        <div className="form-group mb-1 mt-1 ">
                                          {renderHeader("Intervention")}
                                          {renderContent(intervention.intervention)}
                                          {renderHeader("Service Type")}
                                          {renderContent(commaSeparatedServiceTypes)}
                                          {renderHeader("Staff Type")}
                                          {renderContent(commaSeparatedStaffTypes)}
                                          {renderHeader("Frequency:")}
                                          {renderContent(`${intervention.numFreq} ${intervention.freq}`)}
                                        </div>
                                      </div>
                                      )
                                    })
                                  }
                                </div>
                              </div>
                            })
                          }


                        </div>

                      </div></div>


                  </div>
                })
              }

            </div>
          )
          }
        </div>


        {documentCommonControls?.documentDiagnosis && documentCommonControls?.documentDiagnosis.length > 0 && (<div>

          <div className="col-lg-12 col-12 mb-3 p-0 ">
            {renderHeader("Diagnosis")}
            {
              documentCommonControls?.documentDiagnosis.map((selectedDx) => {
                return <div>
                  <div className="col-12 m-0 ">
                    <div className="row m-0 align-items-center">
                      <div className="form-group mb-1 mt-1 pl-0 col-lg-9 col-md-9">
                        {renderHeader("Dx")}
                        {renderContent(selectedDx.diagnosisName)}
                      </div>
                      <div className="form-group mb-1 mt-1 pl-0 col-lg-3 col-md-3">
                        {renderHeader("Date Diagnose")}
                        {renderContent(moment(selectedDx.dateDiagnose).format('M/D/YYYY'))}
                      </div></div></div></div>
              })
            }
          </div>
        </div>)}
      </div>
    );
  }

  const CustomCheckBox = (props) => {
    const navigationAttributes = useTableKeyboardNavigation(props.id);

    return (
      <td
        colSpan={props.colSpan}
        role={"gridcell"}
        aria-colindex={props.ariaColumnIndex}
        aria-selected={props.isSelected}
        {...{ [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex }}
        {...navigationAttributes}
      >
        <div className="k-chip-content">
          <Checkbox
            title="Select"
            value={
              documentFormattedVal?.clientDiagnosisId === props.dataItem.id && true
            }
          />
        </div>
      </td>
    );
  };

  function renderTemplate() {
    return (
      <PreviewDocumentTemplate
        controlList={template.controlList}
        documentFieldsMappings={documentFormattedVal?.documentFieldsMappings}
        isViewMode={true}
      />
    );
  }

  const handlePrintOnClick = () => {

    let documentHistories: any[] = []

    if (docSettings && docSettings?.showDocumentHistoryOnPrint) {
      documentHistories = historyList;
    }

    const newState: any = [{
      staffInfo: staffInfo,
      documentDetail: documentFormattedVal,
      diagnosisList: diagnosisList,
      docSignature: docSignature,
      docClientSignature: docClientSignature,
      isHtmlFileTypeTemplate: isHtmlFileTypeTemplate,
      htmlFileName: htmlFileName,
      template: template,
      fieldsMapping: fieldsMapping,
      documentId: documentId,
      docTreatmentPlans: documentFormattedVal?.documentTreatmentPlans,
      docHasControlls: docHasControlls,
      documentCommonControls: documentCommonControls,
      historyList: documentHistories,
    }];
    if (mileagePayRate && template.showMileage) {
      newState[0].mileagePayRate = mileagePayRate;
    }

    // Save data to local storage

    localStorage.setItem('riskList', JSON.stringify(riskList));
    localStorage.setItem('riskFactorList', JSON.stringify(riskFactorList));
    localStorage.setItem('riskProtectiveList', JSON.stringify(riskProtectiveList));
    localStorage.setItem('print-doc-state', JSON.stringify(newState));

    // Open the URL in a new tab
    window.open(APP_ROUTES.DOCUMENT_PRINT, '_blank', 'noopener');
    handleSetDocumentPrint(documentId);
  };


  const handleAddSignature = () => {
    setDisplaySignDialog(true);
    setScroll('true');
  };

  const hideSignatureDialog = () => {
    setDisplaySignDialog(false);
    setScroll('false');

  };

  function handleDuplicateDoc() {
    navigate(APP_ROUTES.DOCUMENT_EDIT, {
      state: {
        id: documentId,
        isDuplicate: true,
      },
    });
  }

  function handleLinkDoc() {
    setLinkDoc(true);
    setScroll('true');
  }

  function handleAttachQuestion() {
    setAttachQuestion(true);
    setScroll('true');
  }

  function handleSealDoc() {
    if ((!isDocSealed) || (isDocSealed && userIsSuperAdmin(staffLoginInfo?.roleId))) {
      setIsSealDocument(true);
      setScroll('true');
    }
  }

  function handleCloseLinkDoc({ linked }) {
    if (linked) {
      fetchLinkedDocuments(documentId);
    }
    setLinkDoc(false);
    setScroll('false');
  }

  function handleCloseQuestionnaire({ attached }) {
    if (attached) {
      fetchLinkedQuestionnaire(documentId);
    }
    setAttachQuestion(false);
    setScroll('false');
  }

  const handleRatingChange = (event) => {
    setRatingVal(event.value);
    handleDocumentRating(event.value);
  };




  const ButtonApproved = ({ }) => {
    return <div className="approve_btn px-0">
      <button onClick={handleApproved} className="cancelBtn m-0 px-1 bg-white">
        <i className="fa fa-check mr-1"></i>
        Mark Approved
      </button>
    </div>
  }


  const ButtonNotApproved = ({ }) => {
    return <div className="unapprove_btn px-0">
      <button onClick={handleNotApproved} className="cancelBtn m-0 px-1 bg-white">
        <i className="fa fa-ban mr-1"></i>
        Mark Not Approved
      </button>
    </div>

  }
  const handleAssignSupervisorDelete = () => {
    setShowConfirmAssignSupervisorDelete(true);
  }
  const hideDeleteAssignedSupervisor = () => {
    setShowConfirmAssignSupervisorDelete(false);
  }
  const deleteAssignedSupervisor = () => {
    setLoading(true);
    var postData = {
      "supervisorStaffId": 0,
      "documentIds": [documentId]
    };
    apiHelper.postRequest(API_ENDPOINTS.ASSIGN_SIPERVISOR, postData).then((result) => {
      setLoading(false);
      if (documentId) {
        fetchByDocumentId(documentId);
      }
      setShowConfirmAssignSupervisorDelete(false);
      NotificationManager.success("Supervisor deleted successfully");
    }).catch((err) => {
      renderErrors(err);
      setLoading(false);
    })
  }


  const BothApprovedButton = ({ }) => {
    return (
      <>
        <ButtonApproved />
        <ButtonNotApproved />
      </>
    );
  };

  function getClassName(actionId) {
    switch (actionId) {
      case ACTION_ID.approved:
        return <ButtonNotApproved />;
      case ACTION_ID.notApproved:
        return <ButtonApproved />;
      case ACTION_ID.toBeReview:
        return <BothApprovedButton />;
      case ACTION_ID.postEditReview:
        return <BothApprovedButton />;
    }
    return;
  }

  // Function to scroll the page down
  const scrollDown = () => {
    // window.scrollBy(0, window.innerHeight);
    window.scrollTo({
      top: document.documentElement.scrollHeight - 1200,
      behavior: "smooth",
    });
  };

  // Function to scroll the page up
  const scrollUp = () => {
    // window.scrollBy(0, -window.innerHeight);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const moveToElement = (targetElement) => {
    // const element = document.getElementById('targetElement');
    const offset = 100;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = targetElement.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  }

  const handlePreviousClick = () => {
    const el = document.getElementById(`document-${props.index - 1}`);
    if (el) {
      moveToElement(el)
      // el.scrollIntoView({ behavior: "smooth", block: "start" })
    }
  }
  const handleNextClick = () => {
    const el = document.getElementById(`document-${props.index + 1}`);
    if (el) {
      moveToElement(el)

      // el.scrollIntoView({ behavior: "smooth", block: "start" })
    }
  }

  const handleService = (serviceName) => {
    setIsOpenServiceModel(!isOpenServiceModel)
    setSelectedServiceName(serviceName)
  }

  const handleServiceDate = () => {
    setIsOpenServiceDateModel(!isOpenServiceDateModel)
  }

  const handleDiagnosis = () => {
    setIsOpenDiagnosisModel(!isOpenDiagnosisModel)
  }

  const handleSiteOfService = () => {
    setIsOpenSiteOfServiceModel(!isOpenSiteOfServiceModel)
  }

  const handlePlaceOfService = () => {
    setIsOpenPlaceOfServiceModel(!isOpenPlaceOfServiceModel)
  }

  
  return (
    <>

      <div>
        <button className="btn-down btnScroller  small-button" type="button" onClick={scrollDown}><i className="fa-solid fa-angle-down"></i></button>
        <button className="btn-up btnScroller small-button" type="button" onClick={scrollUp}><i className="fa-solid fa-angle-up"></i></button>

      </div>

      <div className={"file-" + documentId}  >
        <button className="btn-down btnScroller  small-button" type="button" onClick={scrollDown}><i className="fa-solid fa-angle-down"></i></button>
        <button className="btn-up btnScroller small-button" type="button" onClick={scrollUp}><i className="fa-solid fa-angle-up"></i></button>

        {(isMultiView===0) && (
          <button
            type="button"
            value="BACK"
            onClick={onBack}
            className="border-0 bg-transparent arrow-rotate mb-3"
          >
            <i className="k-icon k-i-sort-asc-sm"></i>
            Back
          </button>
        )}
        <div className="container-fluid">
          <div className="Service-RateList">
            <div className="d-flex justify-content-between">
              <h4 className="address-title">
                <span className="f-20">
                  {documentFormattedVal ? (
                    `${documentFormattedVal.docTemplateName} for ${documentFormattedVal.clientNameDoc} (${documentFormattedVal.gender})`
                  ) : (
                    <CustomSkeleton shape="text" />
                  )}
                </span>

              </h4>
            </div>

            <div>
              {clientFlagList.length > 0 &&
                clientFlagList.map((obj: any) => (
                  <Chip
                    text={obj.flagName}
                    key={obj.id}
                    value="chip"
                    rounded={"large"}
                    fillMode={"solid"}
                    size={"medium"}
                    style={{
                      marginRight: 5,
                      backgroundColor: obj.color,
                      marginBottom: 5,
                      color: "#ffffff",
                    }}
                  />
                ))}
            </div>
          </div>
          <div>


            <div className="d-flex justify-content-between">
              <span className="f-12 labelColor fw-600">
                {!documentFormattedVal ? (
                  <CustomSkeleton shape="text" />
                ) : (
                  documentFormattedVal?.clientInsurance != null ? "Ins: " + documentFormattedVal?.clientInsurance : ""
                )}
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="f-12 labelColor fw-600">
                {!documentFormattedVal ? (
                  <CustomSkeleton shape="text" />
                ) : (
                  "DOB (Age at Time of Srv.): " + documentFormattedVal?.clientDob
                )
                }
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="f-12 labelColor fw-600">
                {!documentFormattedVal ? (
                  <CustomSkeleton shape="text" />
                ) : (
                  "Document ID: " + documentId
                )
                }
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="f-12 labelColor fw-600">
                {!documentFormattedVal ? (
                  <CustomSkeleton shape="text" />
                ) : (
                  (documentFormattedVal?.custAuthId && <tr><td>Auth ID:</td>
                    <td className="authIdPointer text-theme" onClick={() => handleViewTrans(documentFormattedVal?.authId, "open", documentFormattedVal?.clientName)} > {documentFormattedVal?.custAuthId} </td></tr>)
                )
                }
              </span>
            </div>
          </div>
        </div>
        <div className="container-fluid mt-2">
          <div className="d-flex flexWrapper position-relative">

            <div className="col-md-3 pl-0">
              <div className="view-teplate-side viewStaffDOcument">
                <div className='d-flex justify-content-between align-items-center'>
                  {!isShowMenus ? (
                    <CustomSkeleton shape="text" />
                  ) : (
                    <Rating
                      value={ratingVal}
                      disabled={ratingVal > 0 ? true : false}
                      precision={"item"}
                      onChange={handleRatingChange}
                    />
                  )}
                  <div className='mt-2'>
                    {props?.idList?.length > 1 && props.index > 0 && <button className='btn-transparent' onClick={handlePreviousClick} style={{ color: "#07479a" }}><i className="fa-solid fa-chevron-left" ></i>  Prev</button>}
                    {props?.idList?.length > 1 && props.index < props?.idList?.length - 1 && <button className='btn-transparent' onClick={handleNextClick} style={{ color: "#07479a" }}>Next <i className="fa-solid fa-chevron-right" style={{ color: "#07479a" }}></i></button>}
                  </div>
                </div>

                <ul className="list-styled pl-0 staffDocumentLIst" >
                  {
                    (
                      (staffId === documentFormattedVal?.createdBy && userAccessPermission[permissionEnum.CAN_EDIT_MY_OWN_DOCUMENTS])
                      ||
                      (staffId !== documentFormattedVal?.createdBy && userAccessPermission[permissionEnum.CAN_EDIT_OTHER_STAFF_DOC])) && (
                      <>
                        {!isLockDocument && isDocSealed === false && (
                          <li onClick={handleEdit}>
                            {!isShowMenus ? (
                              <CustomSkeleton shape="text" />
                            ) : (
                              <a>
                                <i className="fa fa-edit pr-2"></i> Edit
                              </a>
                            )}
                          </li>
                        )}
                      </>
                    )}

                  {
                    (
                      (staffId === documentFormattedVal?.createdBy && userAccessPermission[permissionEnum.CAN_LOCK_UNLOCK_MY_OWN_DOCUMENTS])
                      ||
                      (staffId !== documentFormattedVal?.createdBy && userAccessPermission[permissionEnum.CAN_LOCK_UNLOCK_OTHER_STAFF_DOC])) && (
                      <>
                        {isDocSealed === false && (
                          <li onClick={handleLockDocument}>
                            {!isShowMenus ? (
                              <CustomSkeleton shape="text" />
                            ) : (
                              <a>
                                <i
                                  className={
                                    isLockDocument === true
                                      ? "fa fa-unlock pr-2"
                                      : "fa fa-lock pr-2"
                                  }
                                ></i>{" "}
                                {isLockDocument === true ? "Unlock" : "Lock"}
                              </a>
                            )}
                          </li>
                        )}
                      </>
                    )}

                  {userAccessPermission[permissionEnum.VIEW_HISTORY] && (
                    <>
                      {
                        <li onClick={handleViewHistory}>
                          {!isShowMenus ? (
                            <CustomSkeleton shape="text" />
                          ) : (
                            <a>
                              <i className="fa fa-history pr-2"></i> View History
                            </a>
                          )}
                        </li>
                      }
                    </>
                  )
                  }

                  {
                    <li
                      onClick={handlePrintOnClick}
                    >
                      {!isShowMenus ? (<CustomSkeleton shape="text" />) : (<a><i className="fa fa-print pr-2"></i> Print</a>)}
                    </li>
                  }

                  {!isDocSealed &&
                    <li onClick={handleTaskAndComments}>
                      {!isShowMenus ? (<CustomSkeleton shape="text" />) : (<a><i className="fa-solid fa-list-check pr-2"></i>  {"Add Tasks/Comments"}</a>)}
                    </li>
                  }


                  {
                    isDocSealed === false &&
                    <>
                      {
                        isLockDocument &&
                        (documentFormattedVal?.supervisorStaffId === staffLoginInfo.id || documentFormattedVal?.createdBy === staffLoginInfo.id || staffLoginInfo.canReviewDocuments === true) &&
                        <li onClick={handleAddSignature}>
                          {!isShowMenus ? (<CustomSkeleton shape="text" />) : (<a><i className="fa-solid fa-signature pr-2"></i>Apply Signature</a>)}
                        </li>
                      }
                    </>
                  }



                  <>
                    {(canDuplicateDocuments && template?.canStaffDuplicateDocTemplateType) &&
                        <li onClick={handleDuplicateDoc}>
                          {!isShowMenus ? (<CustomSkeleton shape="text" />) : (<a><i className="fa fa-clone pr-2"></i> Duplicate</a>)}
                        </li>
                    }
                  </>


                  {
                    isDocSealed === false &&
                    <li onClick={handleLinkDoc}>
                      {!isShowMenus ? (<CustomSkeleton shape="text" />) : (<a><i className="fa fa-link pr-2"></i>Link Document</a>)}
                    </li>
                  }


                  {!isDocSealed &&

                    <li onClick={handleAttachQuestion}>
                      {!isShowMenus ? (<CustomSkeleton shape="text" />) : (<a><i className="fa fa-file-text pr-2"></i> Link Questionnaire</a>)}
                    </li>
                  }



                  {
                    canSealDocument &&
                    <li onClick={handleSealDoc}>
                      {!isShowMenus ? (<CustomSkeleton shape="text" />) : (<a className={(!(isDocSealed || userIsSuperAdmin(staffLoginInfo?.roleId))) ? "cursor-default" : ""} ><i style={{ color: isDocSealed === true ? "#FF0000" : "" }} className="fa-solid fa-stamp pr-2"></i>
                        {isDocSealed === true ? userIsSuperAdmin(staffLoginInfo?.roleId) ? "Unseal Document" : "Document Sealed" : "Seal Document"}</a>)}
                    </li>
                  }
                  {userIsAdmin(staffLoginInfo.roleId) &&
                    <>
                      {
                        <li onClick={handleAssignSupervisorView}>
                          {!isShowMenus ? (<CustomSkeleton shape="text" />) : (<a><i className={"fa fa-exchange pr-2"
                          }></i>{"Assign Supervisor"}</a>)}
                        </li>
                      }
                    </>
                  }

                  
                 {(userIsAdmin(staffLoginInfo.roleId) || userIsQA(staffLoginInfo.roleId)) && (
                    <>
                      {
                        <li onClick={handleSavePdfPrint}>
                          {!isShowMenus ? (<CustomSkeleton shape="text" />) : (<a style={{ textTransform: "none" }}><i className={"fa fa-save pr-2"
                          }></i>{"Save Pdf Copy to Client File Manager"}</a>)}
                        </li>
                      }
                    </>)
                  }



                  {userAccessPermission[permissionEnum.TRASH_UN_TRASH_DOCUMENT] &&
                    <>
                      {
                        <li onClick={handleConfirm} className="deleteList">
                          {!isShowMenus ? (
                            <CustomSkeleton shape="text" />
                          ) : (
                            <a>
                              {isDocumentDeleted ? (
                                <img
                                  src={trashIcon}
                                  alt="trash icon"
                                  aria-hidden="true"
                                  className="cursor-pointer mb-1 pr-2 ml-auto"
                                  title="Delete"
                                />
                              ) : (
                                <i className="fa fa-rotate-left pr-2"></i>
                              )}
                              {isDocumentDeleted ? "Trash" : "Restore"}
                            </a>
                          )}
                        </li>
                      }

                    </>
                  }
                  {!isShowMenus ? (<CustomSkeleton shape="text" />) : (
                    <>
                      {
                        staffInfo?.canReviewDocuments ? (
                          <div className="box_approve">
                            <label className="review_box-approve ml-2 fw-600">{"Doc Status"}</label>
                            <div className={documentFormattedVal?.docStatusId === 1 ?
                              "color_change_docs px-2 new-color-change"
                              : " color_change_docs px-2"}
                              style={{
                                backgroundColor: documentFormattedVal?.docStatusId === 2 ? "#d9ffc8" :
                                  documentFormattedVal?.docStatusId === 1 ? "" : "#fee9eb"
                              }}>

                              <label className="dynamic_text_approve px-0 text-black">{documentFormattedVal?.docStatus}</label>
                              <div className="dynamic_approve_btn mt-2">   {!isDocSealed && getClassName(documentFormattedVal?.docStatusId)}</div>
                            </div>
                          </div>
                        ) : (
                          <div className="box_approve">
                            <label className="review_box-approve ml-2 fw-600">{"Doc Status"}</label>
                            <div className={documentFormattedVal?.docStatusId === 1 ?
                              "color_change_docs px-2 new-color-change"
                              : " color_change_docs px-2"}
                              style={{
                                backgroundColor: documentFormattedVal?.docStatusId === 2 ? "#d9ffc8" :
                                  documentFormattedVal?.docStatusId === 1 ? "" : "#fee9eb"
                              }}>
                              <label className="dynamic_text_approve px-0 text-black">{documentFormattedVal?.docStatus}</label>
                            </div>
                          </div>
                        )}
                    </>
                  )}

                  {(isValidForMultiSign && isDocSealed === false && isLockDocument && (documentFormattedVal?.supervisorStaffId === staffLoginInfo.id || documentFormattedVal?.createdBy === staffLoginInfo.id || staffLoginInfo.canReviewDocuments === true)) &&
                    <>

                      <div className="box_approve d-flex justify-content-start ml-2 blue_theme">
                        <Checkbox
                          onChange={props.handleChangeInDocSignArray && props.handleChangeInDocSignArray}
                          value={(props.docIdsToSigns && props.docIdsToSigns.includes(documentId))}
                        /> <label className="review_box-approve ml-2 mr-2 fw-600">{"Check here to include this document when adding your e-signature."}</label>

                      </div>

                    </>
                  }


                </ul>
              </div>
            </div>
            <div >
              {((billingClaimsManager || rolePermisson.canModifyBillingStatus)&& template?.showServiceControl) && 
              <ViewBillingStatus 
              custAuthId={documentFormattedVal?.custAuthId} authId={documentFormattedVal?.authId} documentDetail={documentFormattedVal} documentId={documentId} 
              onCloseBilling={onCloseBilling} ViewBillingStatus={ViewBillingStatus} showServiceControl={template?.showServiceControl}/>}
              <div className="a4-size-page-view thin-scroll mt-2">
                <div className="col-12 m-0 ">
                  <div className="row m-0 align-items-center">
                    {renderVerticalPair("Client", documentFormattedVal?.clientNameDoc)}
                    <div className="p-0 col-md-6">
                      {renderVerticalPair("Staff (Supervisor)", documentFormattedVal?.createByStaffName, true)}
                      {!documentFormattedVal
                        ? (
                          <CustomSkeleton shape="text" />
                        ) : (
                          <span style={{ color: '#939393 ' }} className="ml-2 docContent greyTextColor" >
                            {documentFormattedVal.supervisorStaffId > 0 ? "(" + documentFormattedVal?.supervisorName + ")" : ""}
                          </span>
                        )}
                      {!documentFormattedVal ? (
                        <CustomSkeleton shape="text" />
                      ) : (
                        <span style={{ color: '#939393 !important' }} className="docContent greyTextColor" >
                          {(documentFormattedVal.supervisorStaffId > 0 && userIsAdmin(staffLoginInfo.roleId)) ?
                            <span className="cursor-pointer" title="Remove Assigned Supervisor"
                              onClick={handleAssignSupervisorDelete}>&nbsp;<i className="fa fa-times" aria-hidden="true">
                              </i></span> : ""}
                        </span>
                      )}
                    </div>

                    <div className="form-group mb-1 mt-1 pl-0 col-md-6">
                      <div className='d-flex'>
                        <p className="docTitle">Service Date
                          {
                            (
                              (userIsSuperAdmin(staffLoginInfo?.roleId)) &&
                              <>
                                {isLockDocument && isDocSealed === false &&
                                  (
                                    <button
                                      onClick={() => handleServiceDate()}
                                      className="bg-transparent border-0 text-theme"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Edit"
                                    >
                                      <i className="k-icon k-i-edit f-12 mb-1 "></i>
                                    </button>
                                  )
                                }
                              </>
                            )}
                        </p>
                      </div>
                      {!documentFormattedVal ? (
                        <CustomSkeleton shape="text" />
                      ) : (
                        <p className="ml-2 docContent mb-0">
                          {moment(documentFormattedVal?.serviceDateStr).format(
                            "M/D/YYYY"
                          )}
                        </p>
                      )}
                    </div>
                    {template?.showServiceControl && (
                      <div className="form-group mb-1 mt-1 pl-0 col-md-6">
                        <div className='d-flex'>
                          <p className=" docTitle">Service
                            {
                              (
                                (isBillingManager || userIsSuperAdmin(staffLoginInfo?.roleId)) &&
                                <>
                                  {isLockDocument && isDocSealed === false &&
                                    (
                                      <button
                                        onClick={() => handleService(documentFormattedVal?.serviceNameTemp)}
                                        className="bg-transparent border-0 text-theme"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Edit"
                                      >
                                        <i className="k-icon k-i-edit f-12 mb-1 "></i>
                                      </button>
                                    )
                                  }
                                </>
                              )}
                          </p>
                        </div>
                        {!documentFormattedVal ? (
                          <CustomSkeleton shape="text" />
                        ) : (
                          <>
                            <p className="ml-2 docContent mb-0">
                              {documentFormattedVal?.serviceNameTemp}
                            </p>
                          </>
                        )}
                      </div>
                    )}


                    {(documentFormattedVal?.ebpCodeId && documentFormattedVal?.ebpCodeId > 0) && (
                      <div className="form-group mb-1 mt-1 pl-0 col-md-6">
                        <div className='d-flex'>
                          <p className=" docTitle">EBP Code</p>
                        </div>
                        {!documentFormattedVal ? (
                          <CustomSkeleton shape="text" />
                        ) : (
                          <>
                            <p className="ml-2 docContent mb-0">
                              {`${documentFormattedVal.ebpTrackingCode} - ${documentFormattedVal.ebpPracticeName}`}
                            </p>
                          </>
                        )}
                      </div>
                    )}

                    {template?.timeRecordingMethodId !== timeRecordingTypeEnum.notRequired && (
                      <div className="form-group mb-1 mt-1 pl-0 col-md-6">
                        <p className=" docTitle">
                          {documentFormattedVal?.documentTimeRecording?.recordingMethodName === 'Duration' ? 'Time Only' :
                            documentFormattedVal?.documentTimeRecording?.recordingMethodName === 'Actual Time' ? 'Actual Time' :
                              documentFormattedVal?.documentTimeRecording?.recordingMethodName === 'Shift' ? 'Shift' :
                                'Time/Duration/Shift'}</p>
                        {documentFormattedVal?.documentTimeRecording?.startTime &&
                          documentFormattedVal?.documentTimeRecording?.endTime ? (
                          <p className="mb-2 docContent">
                            {!documentFormattedVal ? (
                              <CustomSkeleton shape="text" />
                            ) : (
                              <p className="ml-2 docContent mb-0">
                                {convertTimeToLocally(
                                  documentFormattedVal?.documentTimeRecording
                                )}
                              </p>
                            )}
                          </p>
                        ) : documentFormattedVal?.documentTimeRecording?.recordingMethodId === timeRecordingTypeEnum.shift ? (
                          <>
                            <p className="ml-2 docContent mb-0">
                              {documentFormattedVal?.documentTimeRecording?.shiftName}
                            </p>
                          </>
                        ) :
                          documentFormattedVal?.documentTimeRecording?.recordingMethodId === timeRecordingTypeEnum.duration ? (
                            <>
                              <p className="ml-2 docContent mb-0">
                                {documentFormattedVal?.documentTimeRecording?.totalMinutes}m ({totHoursAndMinutes(documentFormattedVal?.documentTimeRecording?.totalMinutes)})
                              </p>
                            </>
                          ) : (
                            <p className="ml-2">NA</p>
                          )}
                      </div>
                    )}
                    {template?.showVisitType &&
                      renderVerticalPair("Visit Type", documentFormattedVal?.isFaceToFace ? "Face to Face"
                        : documentFormattedVal?.isIndirectVisit ? "Indirect"
                          : documentFormattedVal?.isTelehealth ? "Secure Telehealth"
                            : documentFormattedVal?.isTelephone ? "Phone" : "")
                    }
                    {template?.showClientProgress &&
                      renderVerticalPair("Client Progress", documentFormattedVal?.clientProgress
                        ? documentFormattedVal?.clientProgress
                        : "")
                    }
                    {template?.posType !== "Not Required" && (
                      <div className="form-group mb-1 mt-1 pl-0 col-md-6">
                        <div className='d-flex' style={{ textAlign: "center" }}>
                          <p className=" docTitle">Place of Service
                            {(isBillingManager || userIsSuperAdmin(staffLoginInfo?.roleId)) && isLockDocument && isDocSealed === false &&
                              <button
                                onClick={() => handlePlaceOfService()}
                                className="bg-transparent border-0 text-theme"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Edit"
                              >
                                <i className="k-icon k-i-edit f-12 mb-1 "></i>
                              </button>
                            }</p>
                        </div>

                        {documentFormattedVal?.placeOfServiceList.length === 0 ? (
                          <p className="ml-2">---</p>
                        ) : (
                          documentFormattedVal?.placeOfServiceList.map((obj, index) => {
                            return (
                              <>
                                {!documentFormattedVal ? (
                                  <CustomSkeleton shape="text" />
                                ) : (
                                  <div>
                                    <p key={index} className="ml-2 docContent mb-0">
                                      {obj.placeOfServiceName}
                                    </p>
                                  </div>
                                )}
                              </>
                            );
                          })
                        )}
                      </div>
                    )}

                    {
                      documentFormattedVal?.specificPlaceOfService &&
                      renderVerticalPair("Specific Place", documentFormattedVal?.specificPlaceOfService)
                    }

                    {template?.showSiteOfService && (
                      <div className="form-group  mb-3  mt-3 pl-0 col-md-6 ">
                        <div className='d-flex'>
                          <p className=" docTitle">Location/Site of Service {
                            (
                              (isBillingManager || userIsSuperAdmin(staffLoginInfo?.roleId)) &&
                              clientSiteList.length !== 0 && (
                                clientSiteList.length > 1 || !clientSiteList.find((x: any) => x.id === documentFormattedVal?.serviceSiteId)
                              ) &&
                              <>
                                {isLockDocument && isDocSealed === false &&
                                  (
                                    <button
                                      onClick={() => handleSiteOfService()}
                                      className="bg-transparent border-0 text-theme"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Edit"
                                    >
                                      <i className="k-icon k-i-edit f-12 mb-1 "></i>
                                    </button>
                                  )
                                }
                              </>
                            )}
                          </p>
                        </div>
                        {!documentFormattedVal ? (
                          <CustomSkeleton shape="text" />
                        ) : (
                          <p className="ml-2 docContent mb-0">{documentFormattedVal?.siteName}</p>
                        )}
                      </div>
                    )}
                    {(template?.showClientDiags || template?.showAddDiagnosis) && (
                      <div className="form-group  mb-3  mt-3 pl-0 col-md-6 ">
                        <div className='d-flex'>
                          <p className=" docTitle">Diagnosis {
                            (
                              (isBillingManager || userIsSuperAdmin(staffLoginInfo?.roleId)) &&
                              <>
                                {isLockDocument && isDocSealed === false &&
                                  (
                                    <button
                                      onClick={() => handleDiagnosis()}
                                      className="bg-transparent border-0 text-theme"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Edit"
                                    >
                                      <i className="k-icon k-i-edit f-12 mb-1 "></i>
                                    </button>
                                  )
                                }
                              </>
                            )}
                          </p>
                        </div>
                        {!documentFormattedVal ? (
                          <CustomSkeleton shape="text" />
                        ) : (
                          <label className="ml-2 docContent mb-0">
                            {documentFormattedVal?.clientDiagnosisName}
                          </label>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="form-group  mb-3 mt-3 pl-0 col-md-12 ">
                    <p className="docTitle"> Linked Documents</p>
                    {linkedDocuments.length < 0 ? (
                      <CustomSkeleton shape="text" />
                    ) : linkedDocuments.length > 0 ? (
                      renderLinkedDocs(isDocSealed)
                    ) : (
                      <p className="ml-2 docContent">NA</p>
                    )}
                  </div>

                  <div className="form-group  mb-3 mt-3 pl-0 col-md-12 ">
                    <p className="docTitle"> Questionnaire</p>
                    {linkedQuestionnaire.length < 0 ? (
                      <CustomSkeleton shape="text" />
                    ) : linkedQuestionnaire.length > 0 ? (
                      renderLinkedQuestionnaire()
                    ) : (
                      <p className="ml-2 docContent">NA</p>
                    )}
                  </div>

                  {template?.showFileAttachment && (
                    <div className="form-group  mb-3 mt-3 pl-0 col-md-12 ">
                      <p className="docTitle"> Document Attachment</p>
                      {docAttachment.length < 0 ? (
                        <CustomSkeleton shape="text" />
                      ) : docAttachment.length > 0 ? (
                        renderAttachments(isDocSealed)
                      ) : (
                        <p className="ml-2 docContent">NA</p>
                      )}
                    </div>
                  )}

                  {template?.showTreatmentPlan && renderTreatmentPlans()}
                  { template?.showMedication &&  renderMedicationSection()}
                  {template?.showMileage && renderMileageSection()}
                  {template?.showRiskAssessment && renderRiskAssessment()}
                  {
                    docHasControlls
                    && renderCommonControlls()}


                  {/* {diagnosisList && template.showClientDiags && renderDiagnosisList()} */}

                  {tasks.length > 0 && (
                    <ListDocumentTasks
                      ref={taskListRef}
                      taskList={tasks}
                      fetchTasks={fetchTasks}
                      documentId={documentId}
                      authorStaffId={documentFormattedVal?.createdBy}
                    />
                  )}

                  {!isHtmlFileTypeTemplate &&
                    template &&
                    template?.controlList &&
                    renderTemplate()}

                  {isHtmlFileTypeTemplate && (
                    <div className="form-group  mb-3 pl-0 col-md-12 ">
                      <AddDocumentFileTemplate
                        onComponentLoaded={() => { }}
                        controlErrors={null}
                        showValidationError={null}
                        focusEnable={null}
                        name={htmlFileName}
                        selectedPatientList={[{ id: documentFormattedVal?.clientId, name: documentFormattedVal?.clientName }]}
                        isEdit={false}
                        onGoalsDataStateChange={() => { }}
                        isViewDoc={true}
                        preselctedGoalEdit={documentCommonControls?.documentGoals}
                        dxPreselectedData={documentCommonControls?.documentDiagnosis}
                        onDxStateChange={() => { }}
                        tpId={0}
                        isDraft={false}
                        draftCommonControlls={[]}
                        isViewMode={true}
                        docFieldMappings={fieldsMapping}
                        documentId={documentId}
                        docIndex={index}
                      />
                    </div>
                  )}
                  <div className="form-group  mb-3 pl-0 col-md-12 " >
                    {docClientSignature?.length > 0 || showStaffSignature ? <h6 className="">Signature</h6> : null}
                    {docSignature.length < 0 ? (
                      <CustomSkeleton shape="text" />
                    ) : docSignature.length > 0 ? (
                      docSignature.map((staffDocSign: DocSignature, index) => (
                        <div
                          key={index}
                          className="d-flex justify-content-between align-items-center border p-3 mb-3"
                        >
                          <div className="">
                            <div className="">
                              <p className="mb-0 text-grey">
                                {staffDocSign.staffId === documentFormattedVal?.createdBy
                                  ? "Document Author" : "Co-Signing Staff"}:{" "}
                                <span className="fw-500">
                                  {staffDocSign.staffName}
                                  {staffDocSign?.staffCredentials && (
                                    <span className="staff-credentials">, {staffDocSign.staffCredentials}</span>
                                  )}
                                </span>
                              </p>
                              {staffDocSign?.position && <p className="mb-0 text-grey">
                                {staffDocSign.position}
                              </p>}
                              <p className="mb-0 text-grey">
                                Date:{" "}
                                {moment(staffDocSign.dateSig).format("M/D/YYYY")}
                              </p>
                            </div>
                            <img
                              className="signImage"
                              alt="demo"
                              src={"data:image/png;base64," + staffDocSign.signature}
                            />
                            <p className="f-9 mb-0 text-grey">
                              Electronically signed: {moment.utc(staffDocSign.utcDateCreated).local().format("M/D/YYYY hh:mm A")}
                            </p>
                          </div>
                        </div>
                      ))
                    ) : (
                      showStaffSignature && <p className="ml-2">---</p>
                    )}
                    {docClientSignature?.length > 0 &&
                      docClientSignature?.map((obj: DocClientSignature, key) => (
                        <div
                          key={key}
                          className="d-flex justify-content-between align-items-center border p-3 mb-3"
                        >
                          <div className="">
                            <div className="">
                              <p className="mb-0 text-grey">
                                Signed by: <span>{obj?.fullNameAtSig}</span>{" "}
                                {obj.isParent === true ? "(parent-guardian)" : "(client)"}
                              </p>
                              <p className="mb-0 text-grey">
                                Date: {moment(obj.sigDateTime).format("M/D/YYYY")}
                              </p>
                            </div>
                            <img
                              className="signImage"
                              alt="demo"
                              src={"data:image/png;base64," + obj.signature}
                            />
                            <p className="f-9 mb-0 text-grey">
                              Electronically signed: {moment.utc(obj?.utcDateCreated).local().format("M/D/YYYY hh:mm A")}
                            </p>
                          </div>
                        </div>
                      ))}
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>


        <br />
        {loading && <Loading />}
        {confirm && (
          <DeleteDialogModal
            onClose={hideConfirmPopup}
            title="Document"
            message="document"
            handleDelete={handleDelete}
            activeType={isDocumentDeleted === true ? false : true}
            handleReactive={handleDelete}
          />
        )}

        {isDeleteAttachment && (
          <DeleteAttachmentModal
            onClose={hideConfirmPopup}
            title="Attachment"
            message="attachment"
            handleDelete={deleteAttachment}
            activeType={false}
          />
        )}

        {isDeleteLinkDoc && (
          <DeleteLinkedDocModal
            onClose={hideConfirmPopup}
            title="Linked Document"
            message=""
            handleDelete={deleteLinkedDoc}
            activeType={false}
          />
        )}
        {isDeleteLinkQueston && (
          <DeleteLinkedDocModal
            onClose={hideConfirmPopup}
            title="Linked Questionnaire"
            message=""
            handleDelete={deleteLinkedQuestionnaire}
            activeType={false}
          />
        )}

        {isSealDocument && (
          <SealDocumentModal
            onClose={hideConfirmPopup}
            title="Document"
            isisDocSealed={isDocSealed}
            message={(isDocSealed ? "Are you sure you want to unseal this document?" : "Sealing a note prohibits all further editing to Document. Continue?")}
            handleDelete={handleSealedDocument}
          />
        )}

        {isOpenLock && (
          <LockDialogModal
            title="Document"
            onClose={hideLockPopUp}
            handleDelete={lockUnLockDocument}
            activeType={isLockDocument}
            handleReactive={lockUnLockDocument}
            setDeleteAssociateSign={setDeleteAssociateSign}
            isDeleteAssociateSign={isDeleteAssociateSign}
            textForDialogBox='Locking a document prohibits all further editing to this document. Continue?'
          />
        )}

        {displaySignDialog && (
          <AddDocumentSignature
            isStaffDoc={false}
            insertDocumentStaffSign={insertStaffSignature}
            insertClientSignature={insertClientSignature}
            onClose={hideSignatureDialog}
            serviceDate={docServiceDate ? new Date(docServiceDate) : new Date()}
            template={template}
            signStaffId={signStaffId}
            documentName={documentFormattedVal}
            setIsParent={setIsParent}
            mainLoading={null}
            docSettings={docSettings}
            viewDoc="viewDoc"
            isstaffSignConsent={template?.canApplyClientSig ? false : true}
          />
        )}

        {isLinkDoc && (
          <LinkDocument
            onClose={handleCloseLinkDoc}
            clientId={documentFormattedVal?.clientId}
            documentId={documentId}
          />
        )}

        {isAttachQuestion && (
          <AttachQuestionnaire
            onClose={handleCloseQuestionnaire}
            clientId={documentFormattedVal?.clientId}
            documentId={documentId}
          />
        )}

        {isTaskAndDocuments && (
          <AddDocumentTask
            onClose={onTaskDialogClose}
            documentId={documentId}
            authorStaffId={documentFormattedVal?.createdBy}
            taskAdded={onTaskAdded}
            task={null}
          />
        )}

        {isViewQueston && (
          <ViewLinkedQuestionire
            onClose={handleCloseQuestion}
            selectedQuestionId={selectedQuestionId}
          />
        )}

        {userIsAdmin(staffLoginInfo?.roleId) && isAssignSupervisorVisible && (
          <AssignSupervisor
            selectedDocumentId={documentId}
            selectedSupervisorId={selectedSupervisorId}
            handleAssignSupervisorClose={handleAssignSupervisorClose}
            fetchByDocumentId={fetchByDocumentId}
            title={"Assign Supervisor"}

          />
        )}

        {userIsAdmin(staffLoginInfo?.roleId) && showConfirmAssignSupervisorDelete && (
          <DeleteAssignedSupervisorModal
            onClose={hideDeleteAssignedSupervisor}
            title="Assigned Supervisor"
            message=""
            handleDelete={deleteAssignedSupervisor}
            activeType={false}

          />

        )}
        {
          isOpenServiceDateModel && <ServiceDateModel
            onClose={handleServiceDate}
            documentId={documentId}
            docSettings={docSettings}
            documentFormattedVal={documentFormattedVal} />
        }
        {
          isOpenDiagnosisModel && <DiagnosisModel
            onClose={handleDiagnosis}
            clientId={clientID || documentFormattedVal?.clientId}
            diagnosisListOfAllClient={diagnosisList}
            documentId={documentId}
            documentFormattedVal={documentFormattedVal} />
        }
        {
          isOpenSiteOfServiceModel && <SiteOfServiceModel
            onClose={handleSiteOfService}
            clientSiteList={clientSiteList}
            documentId={documentId}
            documentFormattedVal={documentFormattedVal} />
        }
        {
          isOpenServiceModel && <ServiceModel
            onClose={handleService}
            clientId={clientID}
            documentId={documentId}
            selectedServiceName={selectedServiceName}
            documentFormattedVal={documentFormattedVal} />
        }
        {
          isOpenPlaceOfServiceModel && <PlaceOfServiceModel
            onClose={handlePlaceOfService}
            documentId={documentId} />
        }
        {isOpenViewTrans && (
          <ViewTransModel
            onClose={handleViewTrans}
            selectedTransId={selectedTransId}
            srvUsageClientName={clientName}
            isCallingFromViewDoc={false}
          />
        )}
        
        {
          generateDocumentZip &&  <CreateDocZip preparedZipData={prepareDocZipData} onClose={setGenerateDocumentZip}/>
        }
        {openConfirmPopup && (
           <CommonActionDialog
           title="Confirm Save Action"
           message="A version of this file already exists in the client file manager. Are you sure you want to proceed with saving this new version?"
           btn1Text="Yes"
           btn2Text="No"
           onClose={handleCloseConfirmationDialog}
           handleOperation={handleYesOperation}
           className="sig-dialog"
         />)
        }

      </div>
    </>

  );
};

export default ViewDocument;
