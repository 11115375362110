import ApiHelper from "../helper/api-helper";
import { Encrption } from '../app-modules/encrption';
import { API_ENDPOINTS } from "./api-endpoints";



const getRoles = () => {
    return ApiHelper.getRequest(API_ENDPOINTS.GET_ROLE);
};

const getRoleModules = (id) => {
    return ApiHelper.getRequest(API_ENDPOINTS.GET_ROLE_MODULES + Encrption(id));
};


const updateRoleModulePermission = (roleId, selectedPermissions) => {

    var data = {
        "roleId": roleId,
        "permissions": selectedPermissions
    }

    return ApiHelper.postRequest(API_ENDPOINTS.UPDATE_ROLE_PERMISSIONS, data,);
};


export const RoleService = {
    getRoles,
    getRoleModules,
    updateRoleModulePermission,
    // getUserPermissions


}