import { AppSuccessResponse } from 'src/dataModels/apiSuccessResponse';
import { UserAuthRequest, UserObject } from '../../dataModels/authDataModels';
import { SettingsData } from '../../dataModels/userSettingsModel';

import * as TYPES from './types';
import { AdvanceSearchObject, DocumentListRequestObj, DocumentResponseObj, ChartResponse, DocumentsData, DocumentBillingHistory, BillingStatusResultData, DocumentServiceResult, UpdateDocumentService, RiskResult, LatestDoucmentByTemplateIdPayload, UpdateDocumentServiceDate, UpdateDocumentDiagnosis, UpdateDocumentSiteOfService } from 'src/dataModels/docListModel';
import { ComplianceResponse, ClinicServiceListResponse, ClientComplianceResponse, ServiceSetResult, AssignSetToStaffModel, InsertServiceSetModel, UpdateServiceSetModel, ServiceDDLByClinicIdResponse, DefaultGoalsTemplateListResultData, InsertDataModel, UpdateDataModel, AssignSetToClientModel, ClinicDocumentS3SignedUrlModel, ClinicStoredDocumentResult, ClinicianListModel, StaffTypesResult, ReviewGoalResult, TimeClockData, UpdateStaffTimeClockPayload, ServiceOrStaffPayload, clinicTpStaffOrServiceResultData, insertUpdateCalendarSettingModal } from 'src/dataModels/clinicResponseModels';
import { CheckDuplicateUsersRequests, ClientBillingClaims, ClientComplianceListResponse, ClientInfo, DuplicateDataResponse } from 'src/dataModels/clientResponse';
import { ServiceLine } from 'src/dataModels/billingResponses';
import { InsertReviewGoalModel, SaveDefaultClientObj, UpdateReviewGoalModel } from 'src/dataModels/clientDataModel';
import { BillingCountResultData, ServiceUnitRenderResultData, dateModel } from 'src/dataModels/dashboardResponseModel';
import { ComplianceReportList } from 'src/dataModels/complianceModel';
import { AuthSets, AuthSetsPostModel } from 'src/dataModels/authSetModels';
import { StaffNotificationPayloadModel } from 'src/dataModels/staffResponsesModels';
import { StaffGoals, StaffTemplateList, TemplateSettings } from 'src/dataModels/staffDocModel';
import { AuditLogData, EmailReminderSettings, PhoneReminderSettings, TextReminderSettings, UpdateReminderModel } from 'src/dataModels/reminder-model';
import { getReminderModel } from 'src/dataModels/reportsModel';


export const loginAction = (payload: UserAuthRequest) => ({
  type: TYPES['LOGIN_ACTION'],
  payload,
});

export const loaderAction = (payload: Boolean) => ({
  type: TYPES['LOADER'],
  payload,
});

export const roleModeAction = (payload: string) => ({
  type: TYPES['ROLE_MODE'],
  payload,
});

export const staffSettingsAction = (payload: number) => ({
  type: TYPES['STAFF_SETTINGS'],
  payload,
});


export const savePermissionsAction = (payload: any) => ({
  type: TYPES['USER_CAN_ACCESS'],
  payload,
});


export const saveStaffPermissions = (payload: SettingsData) => ({
  type: TYPES['GET_ROLE_PERMISSIONS'],
  payload,
});


export const loginUser = (payload: UserObject) => ({
  type: TYPES['LOGIN_USER'],
  payload,
});


export const getDraftCount = (payload: string) => ({
  type: TYPES['GET_DRAFT_COUNT'],
  payload,
});

export const saveDraftCounts = (payload: string) => ({
  type: TYPES['SAVE_DRAFT_COUNT'],
  payload,
});


export const deleteDocument = (payload: string) => ({
  type: TYPES['DELETE_DOCUMENT'],
  payload,
});

export const saveDeleteDocResponse = (payload?: AppSuccessResponse) => ({
  type: TYPES['SAVE_AND_REFRESH'],
  payload,
});


export const untrashDocument = (payload: string) => ({
  type: TYPES['REVERT_DOCUMENT'],
  payload,
});

export const getDocumentList = (payload?: DocumentListRequestObj) => ({
  type: TYPES['GET_DOC_LIST'],
  payload,
});


export const getStaffMostDocument = (payload?: DocumentListRequestObj) => ({
  type: TYPES['GET_STAFF_MOST_DOCUMENTS'],
  payload,
});

export const saveStaffMostDocument = (payload?: ChartResponse) => ({
  type: TYPES['SAVE_STAFF_MOST_DOCUMENTS'],
  payload,
});


export const getClinetMostDocument = (payload?: DocumentListRequestObj) => ({
  type: TYPES['GET_CLIENT_MOST_DOCUMENTS'],
  payload,
});

export const saveClientMostDocument = (payload?: ChartResponse) => ({
  type: TYPES['SAVE_CLIENT_MOST_DOCUMENTS'],
  payload,
});

export const getBillingStatusMetrics = (payload?: DocumentListRequestObj) => ({
  type: TYPES['GET_BILLING_STATUS_METRICS'],
  payload,
});

export const saveBillingMostDocument = (payload?: ChartResponse) => ({
  type: TYPES['SAVE_BILLING_STATUS_METRICS'],
  payload,
});

export const saveDocumentList = (payload?: DocumentResponseObj) => ({
  type: TYPES['SAVE_DOC_LIST'],
  payload,
});

export const getClinicLogo = (payload: any) => ({
  type: TYPES['GET_CLINIC_LOGO'],
  payload,
});


export const saveClinicLogoBytes = (payload: String) => ({
  type: TYPES['SAVE_CLINIC_LOGO_BYTES'],
  payload,
});

export const saveClinicLogoUrl = (payload: String) => ({
  type: TYPES['SAVE_CLINIC_LOGO_URL'],
  payload,
});


export const saveDocumentSearchFilter = (payload?: AdvanceSearchObject | null) => ({
  type: TYPES['GET_DOCUMENT_FILTER'],
  payload,
});


export const getSiteList = (payload?: any) => ({
  type: TYPES['GET_SITE_LIST'],
  payload,
});

export const saveSiteList = (payload?: any) => ({
  type: TYPES['SAVE_SITE_LIST'],
  payload,
});

export const getServiceList = (payload?: any) => ({
  type: TYPES['GET_SERVICE_LIST'],
  payload,
});

export const saveServiceList = (payload?: any) => ({
  type: TYPES['SAVE_SERVICE_LIST'],
  payload,
});

export const getClientList = (payload?: any) => ({
  type: TYPES['GET_CLIENT_LIST'],
  payload,
});

export const saveClientList = (payload?: any) => ({
  type: TYPES['SAVE_CLIENT_LIST'],
  payload,
});

export const getStaffList = (payload?: any) => ({
  type: TYPES['GET_STAFF_LIST'],
  payload,
});
export const getStaffListActiveInactive = (payload?: any) => ({
  type: TYPES['GET_STAFF_LIST_ACTIVE_INACTIVE'],
  payload,
});


export const saveStaffList = (payload?: any) => ({
  type: TYPES['SAVE_STAFF_LIST'],
  payload,
});

export const getBillingClaims = (payload?: any) => ({
  type: TYPES['GET_CLIENT_BILLING_CLAIMS'],
  payload,
});


export const saveBillingClaims = (payload?: ClientBillingClaims[]) => ({
  type: TYPES['SAVE_CLIENT_BILLING_CLAIMS'],
  payload,
});

export const getDocumentbyId = (payload: string) => ({
  type: TYPES['GET_DOCUMENT_BY_ID'],
  payload,
});

export const saveDocumentById = (payload?: DocumentsData) => ({
  type: TYPES['SAVE_DOCUMENT_BY_ID'],
  payload,
});

export const getClientCompliance = (payload: any) => ({
  type: TYPES['GET_CLIENT_COMPLIANCE_FOR_CLINIC'],
  payload
})

export const saveClientCompliance = (payload: ComplianceResponse) => ({
  type: TYPES['SAVE_CLIENT_COMPLIANCE_FOR_CLINIC'],
  payload
})

export const getClinicServiceList = (payload?: any) => ({
  type: TYPES['GET_CLINIC_SERVICES'],
  payload
})

export const saveClinicServiceList = (payload: ClinicServiceListResponse) => ({
  type: TYPES['SAVE_CLINIC_SERVICES'],
  payload
})

export const getDoumentTemplate = (payload: any) => ({
  type: TYPES['GET_DOCUMENT_TEMPLATE'],
  payload
})

export const saveDoumentTemplate = (payload: any) => ({
  type: TYPES['SAVE_DOCUMENT_TEMPLATE'],
  payload
})

export const getClientComplianceForClinicDDL = (payload: any) => ({
  type: TYPES['GET_CLIENT_COMPLIANCE'],
  payload
})
export const saveClientComplianceForClinicDDL = (payload: any) => ({
  type: TYPES['SAVE_CLIENT_COMPLIANCE'],
  payload
})


export const getClaimServiceLine = (payload: number) => ({
  type: TYPES['GET_CLAIM_SERVICE_LINE'],
  payload,
});

export const saveClaimServiceLine = (payload?: ServiceLine[]) => ({
  type: TYPES['SAVE_CLAIM_SERVICE_LINE'],
  payload,
});

export const getClientComplianceById = (payload: number) => ({
  type: TYPES['GET_CLIENT_COMPLIANCE_BY_ID'],
  payload,
});
export const saveClientComplianceById = (payload: ClientComplianceResponse) => ({
  type: TYPES['SAVE_CLIENT_COMPLIANCE_BY_ID'],
  payload,
});

export const getDocBillinHistory = (payload: string) => ({
  type: TYPES['GET_DOC_BIILING_HISTORY'],
  payload,
});

export const saveDocBillingHistory = (payload?: DocumentBillingHistory[]) => ({
  type: TYPES['SAVE_DOC_BIILING_HISTORY'],
  payload,
});

export const selectBatchId = (payload: number) => ({
  type: TYPES['SELECT_BATCH_ID'],
  payload,
});
export const selectPayerClaimId = (payload: number) => ({
  type: TYPES['SELECT_PAYER_CLAIM_ID'],
  payload,
});
export const selectEOBId = (payload: number) => ({
  type: TYPES['SELECT_EOB_ID'],
  payload,
});

export const getClientComplianceList = (payload: string) => ({
  type: TYPES['GET_CLIENT_COMPLIANCE_LIST'],
  payload,
});
export const saveClientComplianceList = (payload: ClientComplianceListResponse) => ({
  type: TYPES['SAVE_CLIENT_COMPLIANCE_LIST'],
  payload,
});

export const completeCompliance = (payload: any) => ({
  type: TYPES['COMPLETE_COMPLIANCE'],
  payload,
});

export const deleteManuallyCompletedCompliance = (payload: any) => ({
  type: TYPES['DELETE_MANUALLY_COMPLETED_COMPLIANCE'],
  payload,
});

export const getActiveStaffseats = (payload?: any) => ({
  type: TYPES['GET_ACTIVE_STAFF_SEATS'],
  payload,
});
export const saveActiveStaffseats = (payload?: any) => ({
  type: TYPES['SAVE_ACTIVE_STAFF_SEATS'],
  payload,
});
export const checkCompliance = (payload?: any) => ({
  type: TYPES['CHECK_COMPLIANCE'],
  payload,
});

export const getClinicFlag = (payload?: any) => ({
  type: TYPES['GET_CLINIC_FLAG'],
  payload,
});
export const saveClinicFlag = (payload?: any) => ({
  type: TYPES['SAVE_CLINIC_FLAG'],
  payload,
});

export const saveAssignFlagToClient = (payload: any) => ({
  type: TYPES['ASSIGN_FLAG_TO_CLIENT'],
  payload
})
export const storeAssignFlagToClient = (payload: any) => ({
  type: TYPES['SAVE_ASSIGN_FLAG_TO_CLIENT'],
  payload
})

export const getClientStatus = (payload?: any) => ({
  type: TYPES['GET_CLIENT_STATUS'],
  payload,
});
export const saveClientStatus = (payload?: any) => ({
  type: TYPES['SAVE_CLIENT_STATUS'],
  payload,
});

export const saveSelectedClientStatus = (payload: any) => ({
  type: TYPES['SAVE_SELECTED_CLIENT_STATUS'],
  payload
})
export const clientStatusLoadingState = (payload: any) => ({
  type: TYPES['CLIENT_STATUS_LOADING_STATE'],
  payload
})

export const saveSelectedClientEligibility = (payload: any) => ({
  type: TYPES['SAVE_SELECTED_CLIENT_ELIGIBILITY'],
  payload
})

export const getUnreadCount = (payload?: any) => ({
  type: TYPES['GET_UNREAD_COUNT'],
  payload,
});

export const saveUnreadCount = (payload?: any) => ({
  type: TYPES['SAVE_UNREAD_COUNT'],
  payload
})


// export const saveAddProductDiagnosis = (payload?: AddDocDxReq[]) => ({
//   type: TYPES['SAVE_ADD_DOC_DIAGNOSIS'],
//   payload
// })

// export const saveAddProductGloals = (payload?: AddDocGoalReq[]) => ({
//   type: TYPES['SAVE_ADD_DOC_GOALS'],
//   payload
// })


// export const saveAddProductClientInfo = (payload?: ClientInfo[]) => ({
//   type: TYPES['SAVE_ADD_DOC_PROFILE_INFO'],
//   payload
// })



export const saveDocumentPageSize = (payload: number) => ({
  type: TYPES['SAVE_DOCUMENT_PAGE_SIZE'],
  payload
})

export const unreadMessageCount = (payload: number) => ({
  type: TYPES['UNREAD_MESSAGE_COUNT'],
  payload
})

export const saveSwitchValue = (payload: boolean) => ({
  type: TYPES['SAVE_SWITCH_VALUE'],
  payload
})
export const saveSwitchStaffValue = (payload: boolean) => ({
  type: TYPES['SAVE_SWITCH_STAFF_VALUE'],
  payload
})



export const getMultipleClientList = (payload: string[]) => ({
  type: TYPES['GET_MULTIPLE_CLIENTS'],
  payload
})

export const saveMultipleClientList = (payload?: ClientInfo[]) => ({
  type: TYPES['SAVE_MULTIPLE_CLIENTS'],
  payload
})

export const getBillingTransItemByClaimId = (payload?: number) => ({
  type: TYPES['GET_BILLING_TRANS_ITEM_BY_CLAIMID'],
  payload
})

export const saveBillingTransItemByClaimId = (payload?: any) => ({
  type: TYPES['SAVE_BILLING_TRANS_ITEM_BY_CLAIMID'],
  payload
})

export const getclinicDefaultGoals = () => ({
  type: TYPES['GET_CLINIC_DEFAULT_GOALS'],
})

export const saveClinicDefaultGoals = (payload?: any) => ({
  type: TYPES['SAVE_CLINIC_DEFAULT_GOALS'],
  payload
})

export const deleteClinicDefaultGoals = (payload?: number) => ({
  type: TYPES['DELETE_CLINIC_DEFAULT_GOALS'],
  payload
})

export const assignDefaultGoalToClients = (payload?: any) => ({
  type: TYPES['ASSIGN_DEFAULT_GOALS_TO_CLIENT'],
  payload
})


export const getGoalById = (payload: number) => ({
  type: TYPES['GET_GOAL_BY_ID'],
  payload
})

export const saveGoalById = (payload?: SaveDefaultClientObj) => ({
  type: TYPES['SAVE_GOAL_BY_ID'],
  payload
})

export const getClientCount = (payload) => ({
  type: TYPES['GET_CLIENT_COUNT'],
  payload
})

export const saveClientCount = (payload: any) => ({
  type: TYPES['SAVE_CLIENT_COUNT'],
  payload
})

export const getServiceSets = () => ({
  type: TYPES['GET_SERVICE_SETS'],

})

export const saveServiceSets = (payload?: ServiceSetResult) => ({
  type: TYPES['SAVE_SERVICE_SETS'],
  payload
})

export const deleteServiceSets = (payload?: number) => ({
  type: TYPES['DELETE_SERVICE_SETS'],
  payload
})

export const InsertServiceSet = (payload?: InsertServiceSetModel) => ({
  type: TYPES['INSERT_SERVICE_SETS'],
  payload
})

export const UpdateServiceSet = (payload?: UpdateServiceSetModel) => ({
  type: TYPES['UPDATE_SERVICE_SETS'],
  payload
})

export const AssignSetStaff = (payload?: AssignSetToStaffModel) => ({
  type: TYPES['ASSIGN_SET_TO_STAFF'],
  payload
})

export const AssignSetClient = (payload?: AssignSetToClientModel) => ({
  type: TYPES['ASSIGN_SET_TO_CLIENT'],
  payload
})


export const GetAvailableServiceList = (payload?: number) => ({
  type: TYPES['GET_AVAILABLE_SERVICE_LIST'],
  payload
})

export const SaveAvailableServiceList = (payload?: any) => ({
  type: TYPES['SAVE_AVAILABLE_SERVICE_LIST'],
  payload
})

export const GetClientDiagnosisCount = () => ({
  type: TYPES['GET_CLIENT_DIAGNOSIS_COUNT'],
})

export const SaveClientDiagnosisCount = (payload?: any) => ({
  type: TYPES['SAVE_CLIENT_DIAGNOSIS_COUNT'],
})
export const GetServiceUnitsRender = (payload?: dateModel) => ({
  type: TYPES['GET_SERVICE_UNITS_RENDER'],
  payload
})
export const SaveServiceUnitsRender = (payload?: ServiceUnitRenderResultData) => ({
  type: TYPES['SAVE_SERVICE_UNITS_RENDER'],
  payload
})

export const GetBillingCount = (payload?: dateModel) => ({
  type: TYPES['GET_BILLING_COUNTS'],
  payload
})
export const SaveBillingCount = (payload?: BillingCountResultData) => ({
  type: TYPES['SAVE_BILLING_COUNTS'],
  payload
})

export const GetServiceDDLByClinicId = () => ({
  type: TYPES['GET_SERVICE_DDL_BY_CLINIC_ID']
})

export const SaveServiceDDLByClinicId = (payload?: ServiceDDLByClinicIdResponse) => ({
  type: TYPES['SAVE_SERVICE_DDL_BY_CLINIC_ID'],
  payload
})

export const GetServiceSetDDL = (payload?: any) => ({
  type: TYPES['GET_SERVICE_SET_DDL'],
  payload
})

export const SaveServiceSetDDL = (payload?: any) => ({
  type: TYPES['SAVE_SERVICE_SET_DDL'],
  payload
})

export const SaveAssigntoStaffResponse = (payload?: any) => ({
  type: TYPES['SAVE_ASSIGN_TO_STAFF_RESPONSE'],
  payload
})
export const saveSelectedDocId = (payload: number) => ({
  type: TYPES['SAVE_SELECTED_DOC_ID'],
  payload
})

export const SaveBillingDate = (payload?: any) => ({
  type: TYPES['SAVE_BILLING_DATE'],
  payload
})



export const getComplianceReport = (payload?: any) => ({
  type: TYPES['GET_COMPLIANCE_REPORT'],
  payload
})


export const saveComplianceReport = (payload: ComplianceReportList[]) => ({
  type: TYPES['SAVE_COMPLIANCE_REPORT'],
  payload
})

export const getAuthSetList = (payload?: string) => ({
  type: TYPES['GET_AUTH_SET'],
  payload
})


export const saveAuthSetList = (payload: AuthSets[]) => ({
  type: TYPES['SAVE_AUTH_SET'],
  payload
})

export const deleteAuthSet = (payload?: number) => ({
  type: TYPES['DELETE_AUTH_SET'],
  payload
})


export const createNewAuthSet = (payload?: AuthSetsPostModel) => ({
  type: TYPES['CREATE_NEW_AUTH_SET'],
  payload
})


export const updateAuthSet = (payload?: AuthSetsPostModel) => ({
  type: TYPES['UPDATE_AUTH_SET'],
  payload
})

export const getBillingAction = (payload?: string) => ({
  type: TYPES['GET_BILLING_ACTION'],
  payload
})
export const saveBillingAction = (payload?: BillingStatusResultData[]) => ({
  type: TYPES['SAVE_BILLING_ACTION'],
  payload
})

export const saveDocLoadingState = (payload: any) => ({
  type: TYPES['LOADER_DOC_LIST'],
  payload
})

export const saveAssignGoalResponse = (payload: number) => ({
  type: TYPES['SAVE_ASSIGN_DEFAULT_GOALS_TO_CLIENT'],
  payload
})

export const saveDefaultGoalTemplate = (payload: InsertDataModel) => ({
  type: TYPES['SAVE_DEFAULT_GOALS_TEMPLATES'],
  payload
})

export const getClinicDefaultGoalsTemplates = () => ({
  type: TYPES['GET_CLINIC_DEFAULT_GOALS_TEMPLATES']
})

export const saveClinicDefaultGoalsTemplates = (payload: DefaultGoalsTemplateListResultData[]) => ({
  type: TYPES['SAVE_CLINIC_DEFAULT_GOALS_TEMPLATES'],
  payload
})

export const deleteClinicDefaultGoalsTemplate = (payload?: number) => ({
  type: TYPES['DELETE_DEFAULT_GOALS_TEMPLATES'],
  payload
})

export const updateClinicDefaultGoalsTemplateInfo = (payload?: UpdateDataModel) => ({
  type: TYPES['UPDATE_DEFAULT_GOALS_TEMPLATES'],
  payload
})

// clinic file
export const getClinicStoredDocumentByClinicId = () => ({
  type: TYPES['GET_CLINIC_STORED_DOCUMENT_BY_CLINIC_ID'],
})

export const saveClinicStoredDocumentByClinicId = (payload: ClinicStoredDocumentResult[]) => ({
  type: TYPES['SAVE_CLINIC_STORED_DOCUMENT_BY_CLINIC_ID'],
  payload
})

export const insertClinician = (payload?: any) => ({
  type: TYPES['INSERT_CLINICIAN'],
  payload
})

export const saveInsertClinicianResponse = (payload?: UpdateDataModel) => ({
  type: TYPES['SAVE_INSERT_CLINICIAN'],
  payload
})


export const getClinicDocumentS3SignedUrl = (payload: ClinicDocumentS3SignedUrlModel) => ({
  type: TYPES['GET_CLINIC_DOCUMENT_S3_SIGNED_URL'],
  payload
})

export const saveClinicDocumentS3SignedUrl = (payload: any) => ({
  type: TYPES['SAVE_CLINIC_DOCUMENT_S3_SIGNED_URL'],
  payload
})

export const getClinicS3DocumentUrlById = (payload: number) => ({
  type: TYPES['GET_CLINIC_S3_DOCUMENT_URL_BYID'],
  payload
})

export const getClinicDocumentById = (payload: any) => ({
  type: TYPES['GET_CLINIC_DOCUMENT_BYID'],
  payload
})
export const saveClinicDocumentById = (payload: any) => ({
  type: TYPES['SAVE_CLINIC_DOCUMENT_BYID'],
  payload
})

export const deleteClinicDocumentTag = (payload: number) => ({
  type: TYPES['DELETE_CLINIC_DOCUMENT_TAG'],
  payload
})

export const deleteClinicDocument = (payload: any) => ({
  type: TYPES['DELETE_CLINIC_DOCUMENT'],
  payload
})

export const getClinicTags = (payload: any) => ({
  type: TYPES['GET_CLINIC_TAGS'],
  payload
})
export const saveClinicTags = (payload: any) => ({
  type: TYPES['SAVE_CLINIC_TAGS'],
  payload
})

export const assignClinicTags = (payload?: any) => ({
  type: TYPES['ASSIGN_CLINIC_TAGS'],
  payload
})

export const getClinicianList = (payload?: any) => ({
  type: TYPES['GET_CLINICIAN_LIST'],
  payload
})

export const saveClinicianList = (payload?: ClinicianListModel[]) => ({
  type: TYPES['SAVE_CLINICIAN_LIST'],
  payload
})

export const saveAuthFilters = (payload?: any) => ({
  type: TYPES['SAVE_AUTHORIZATION_FILTER'],
  payload
})

export const TaskFilters = (payload?: any) => ({
  type: TYPES['TASK_FILTER'],
  payload
})
export const SaveTaskFilters = (payload?: any) => ({
  type: TYPES['SAVE_TASK_FILTER'],
  payload
})
export const getTpStaffTypeDll = () => ({
  type: TYPES['GET_TP_STAFF_TYPE_DLL'],
})
export const saveTpStaffTypeDll = (payload?: StaffTypesResult[]) => ({
  type: TYPES['SAVE_TP_STAFF_TYPE_DLL'],
  payload
})
export const getEdiBybatchId = (payload?: number) => ({
  type: TYPES['GET_EDI_BY_BATCH_ID'],
  payload
})

export const saveEdiBybatchId = (payload?: any) => ({
  type: TYPES['SAVE_EDI_BY_BATCH_ID'],
  payload
})

export const saveEncryptedRedirectDocId = (payload?: number) => ({
  type: TYPES['SAVE_REDIRECTED_DOC_ID'],
  payload
})

export const setAppImpersonateFlag = (payload?: boolean) => ({
  type: TYPES['SET_APP_IS_IMPERSONATE_FLAG'],
  payload
})

export const insertReviewGoal = (payload?: InsertReviewGoalModel) => ({
  type: TYPES['INSERT_REVIEW_GOAL'],
  payload
})
export const updateReviewGoal = (payload?: UpdateReviewGoalModel) => ({
  type: TYPES['UPDATE_REVIEW_GOAL'],
  payload
})

export const deleteReviewGoal = (payload?: number) => ({
  type: TYPES['DELETE_REVIEW_GOAL'],
  payload
})

export const getReviewGoalByGoalId = (payload?: number) => ({
  type: TYPES['GET_REVIEW_GOAL_BY_GOAL_ID'],
  payload
})
export const saveReviewGoalByGoalId = (payload?: ReviewGoalResult[]) => ({
  type: TYPES['SAVE_REVIEW_GOAL_BY_GOAL_ID'],
  payload
})

export const getStaffNotificationSetting = (payload?: any) => ({
  type: TYPES['GET_STAFF_NOTIFICATION_SETTING'],
  payload
})

export const getStaffTaskNotificationSetting = (payload?: any) => ({
  type: TYPES['GET_STAFF_TASK_NOTIFICATION_SETTING'],
  payload
})

export const getStaffSchedulerNotificationSetting = (payload?: any) => ({
  type: TYPES['GET_STAFF_SCHEDULER_NOTIFICATION_SETTING'],
  payload
})

export const saveStaffNotificationSetting = (payload?: any) => ({
  type: TYPES['SAVE_STAFF_NOTIFICATION_SETTING'],
  payload
})

export const saveStaffTaskNotificationSetting = (payload?: any) => ({
  type: TYPES['SAVE_STAFF_TASK_NOTIFICATION_SETTING'],
  payload
})

export const saveStaffSchedulerNotificationSetting = (payload?: any) => ({
  type: TYPES['SAVE_STAFF_SCHEDULER_NOTIFICATION_SETTING'],
  payload
})

export const saveStaffNotification = (payload?: StaffNotificationPayloadModel) => ({
  type: TYPES['SAVE_STAFF_NOTIFICATION'],
  payload
})

export const getStaffNewNotificationSetting = (payload?: any) => ({
  type: TYPES['GET_STAFF_NEW_NOTIFICATION_SETTING'],
  payload
})
export const saveStaffNewNotificationSetting = (payload?: any) => ({
  type: TYPES['SAVE_STAFF_NEW_NOTIFICATION_SETTING'],
  payload
})

export const getClinicNotificationSetting = (payload?: any) => ({
  type: TYPES['GET_CLINIC_NOTIFICATION_SETTING'],
  payload
})
export const saveClinicNotificationSetting = (payload?: any) => ({
  type: TYPES['SAVE_CLINIC_NOTIFICATION_SETTING'],
  payload
})

export const saveClinicNotification = (payload?: any) => ({
  type: TYPES['SAVE_CLINIC_NOTIFICATION_PAYLOAD'],
  payload
})

export const getClinicNewAuthAlertSetting = (payload?: any) => ({
  type: TYPES['GET_CLINIC_NEW_AUTH_ALERT_SETTING'],
  payload
});

export const saveClinicNewAuthAlertSetting = (payload?: any) => ({
  type: TYPES['SAVE_CLINIC_NEW_AUTH_ALERT_SETTING'],
  payload
});



export const getAuthTransactions = (payload?: number) => ({
  type: TYPES['GET_AUTH_TRANSACTIONS'],
  payload
})
export const saveAuthTransactions = (payload?: any) => ({
  type: TYPES['SAVE_AUTH_TRANSACTIONS'],
  payload
})
export const getClockStatus = (payload?: any) => ({
  type: TYPES['GET_CLOCK_STATUS'],
  payload
})

export const saveClockStatus = (payload?: any) => ({
  type: TYPES['SAVE_CLOCK_STATUS'],
  payload
})

export const insertNewTImeClock = (payload?: any) => ({
  type: TYPES['UPDATE_TIME_CLOCK'],
  payload
})

export const AssignNotificationSetting = (payload?: any) => ({
  type: TYPES['ASSIGN_NOTIFICATION_SETTING_TO_STAFF_ROLE'],
  payload
})

export const getRole = (payload?: any) => ({
  type: TYPES['GET_AUTH_LIST'],
  payload
})
export const saveRole = (payload?: any) => ({
  type: TYPES['SAVE_ROLE_LIST'],
  payload
})

export const saveClientAuthFilters = (payload?: any) => ({
  type: TYPES['SAVE_CLIENT_AUTHORIZATION_FILTER'],
  payload
})

export const storeComplianceReport = (payload?: any) => ({
  type: TYPES['STORE_COMPLIANCE_REPORT'],
  payload
})
export const getStoreComplianceReport = (payload?: any) => ({
  type: TYPES['GET_STORE_COMPLIANCE_REPORT'],
  payload
})

export const getDocumentServiceList = (payload?: string) => ({
  type: TYPES['GET_DOCUMENT_SERVICES'],
  payload
})
export const saveDocumentServiceList = (payload?: DocumentServiceResult[]) => ({
  type: TYPES['SAVE_DOCUMENT_SERVICES'],
  payload
})
export const updateDocumentService = (payload?: UpdateDocumentService) => ({
  type: TYPES['UPDATE_DOCUMENT_SERVICE'],
  payload
})
export const updateDocumentServiceDate = (payload?: UpdateDocumentServiceDate) => ({
  type: TYPES['UPDATE_DOCUMENT_SERVICE_DATE'],
  payload
})
export const updateDocumentDiagnosis = (payload?: UpdateDocumentDiagnosis) => ({
  type: TYPES['UPDATE_DOCUMENT_DIAGNOSIS'],
  payload
})
export const updateDocumentSiteOfService = (payload?: UpdateDocumentSiteOfService) => ({
  type: TYPES['UPDATE_DOCUMENT_SITE_OF_SERVICE'],
  payload
})

export const saveEditDocData = (payload?: any[]) => ({
  type: TYPES['SAVE_EDIT_DOC_DATA'],
  payload
})

export const getDocumentById = (payload?: any[]) => ({
  type: TYPES['SAVE_EDIT_DOC_DATA'],
  payload
})

export const getPOS = (payload?: any) => ({
  type: TYPES['GET_PLACE_OF_SERVICE'],
  payload
})
export const savePOS = (payload?: any) => ({
  type: TYPES['SAVE_PLACE_OF_SERVICE'],
  payload
})

export const savePOSPayload = (payload?: any) => ({
  type: TYPES['UPDATE_PLACE_OF_SERVICE_PAYLOAD'],
  payload
})

export const saveTemplateSetting = (payload?: any) => ({
  type: TYPES['SAVE_TEMPLATE_SETTING'],
  payload
})

export const successCall = (payload?: any) => ({
  type: TYPES['SUCCESS'],
  payload
})

export const getRiskDDl = (payload?: string) => ({
  type: TYPES['GET_RISK_DDL'],
  payload
})
export const saveRiskDDl = (payload?: RiskResult[]) => ({
  type: TYPES['SAVE_RISK_DDL'],
  payload
})
export const getRiskactorDDl = (payload?: string) => ({
  type: TYPES['GET_RISK_FACTOR_DDL'],
  payload
})
export const saveRiskfactorDDl = (payload?: RiskResult[]) => ({
  type: TYPES['SAVE_RISK_FACTOR_DDL'],
  payload
})
export const getRiskProtectiveDDl = (payload?: string) => ({
  type: TYPES['GET_RISK_PROTECTIVE_DDL'],
  payload
})
export const saveRiskProtectiveDDl = (payload?: RiskResult[]) => ({
  type: TYPES['SAVE_RISK_PROTECTIVE_DDL'],
  payload
})
export const getStaffNotificationMediumSettings = (payload?: any) => ({
  type: TYPES['GET_STAFF_NOTIFICATION_MEDIUM_SETTING'],
  payload
})
export const saveStaffNotificationMediumSettings = (payload?: any) => ({
  type: TYPES['SAVE_STAFF_NOTIFICATION_MEDIUM_SETTING'],
  payload
})
export const saveStaffNotificationMedium = (payload?: any) => ({
  type: TYPES['STAFF_NOTIFICATION_MEDIUM_SETTING'],
  payload
})

export const getAssignedRolesForNotificationSetting = (payload: any) => ({
  type: TYPES['GET_ASSIGNED_NOTIFICATION_SETTINGS'],
  payload
})

export const saveAssignedRolesForNotificationSetting = (payload?: any) => ({
  type: TYPES['SAVE_ASSIGNED_ROLES_FOR_NOTIFICATION_SETTINGS'],
  payload
})

export const getTimeClockById = (payload: Number) => ({
  type: TYPES['GET_TIME_CLOCK_BY_ID'],
  payload
})

export const saveTimeClockById = (payload?: TimeClockData) => ({
  type: TYPES['SAVE_TIME_CLOCK_BY_ID'],
  payload
})

export const updateStaffTimeClockById = (payload?: UpdateStaffTimeClockPayload) => ({
  type: TYPES['UPDATE_STAFF_TIME_CLOCK'],
  payload
})
export const saveUpdateStaffTimeClockByIdResponse = (payload?: Boolean) => ({
  type: TYPES['SAVE_UPDATE_STAFF_TIME_CLOCK_RESPONSE'],
  payload
})
export const savePrePareCount = (payload: number) => ({
  type: TYPES['SET_PREP_PDF_COUNT'],
  payload
})

export const saveExportCount = (payload: number) => ({
  type: TYPES['SET_EXPORT_PDF_COUNT'],
  payload
})

export const getClientByGenderCount = (payload) => ({
  type: TYPES['GET_CLIENT_BY_GENDER_COUNT'],
  payload
})

export const saveClientByGenderCount = (payload?: any) => ({
  type: TYPES['SAVE_CLIENT_BY_GENDER_COUNT'],
  payload
})
export const getLatestDocumentBytemplateId = (payload: LatestDoucmentByTemplateIdPayload) => ({
  type: TYPES['GET_LATEST_DOCUMENT_BY_TEMPLATE_ID'],
  payload
})

export const saveLatestDocumentBytemplateId = (payload?: any) => ({
  type: TYPES['SAVE_LATEST_DOCUMENT_BY_TEMPLATE_ID'],
  payload
})


export const saveAutoPopulateData = (payload?: any) => ({
  type: TYPES['SAVE_DATA_FOR_AUTO_POPULATE_IN_TEMP'],
  payload
})

export const getClinicTpServiceTypes = () => ({
  type: TYPES['GET_CLINIC_TP_SERVICE_TYPES'],
})
export const saveClinicTpServiceTypes = (payload?: clinicTpStaffOrServiceResultData[]) => ({
  type: TYPES['SAVE_CLINIC_TP_SERVICE_TYPES'],
  payload
})

export const getClinicTpStaffTypes = () => ({
  type: TYPES['GET_CLINIC_TP_STAFF_TYPES'],
})
export const saveClinicTpStaffTypes = (payload?: clinicTpStaffOrServiceResultData[]) => ({
  type: TYPES['SAVE_CLINIC_TP_STAFF_TYPES'],
  payload
})

export const insertOrUpdateClinicTpServiceTypes = (payload: ServiceOrStaffPayload) => ({
  type: TYPES['INSERT_OR_UPDATE_CLINIC_TP_SERVICE_TYPES'],
  payload
})

export const deleteClinicTpServiceType = (payload: any) => ({
  type: TYPES['DELETE_CLINIC_TP_SERVICE_TYPES'],
  payload
})
export const insertOrUpdateClinicTpStaffTypes = (payload: ServiceOrStaffPayload) => ({
  type: TYPES['INSERT_OR_UPDATE_CLINIC_TP_STAFF_TYPES'],
  payload
})

export const deleteClinicTpStaffType = (payload: any) => ({
  type: TYPES['DELETE_CLINIC_TP_STAFF_TYPES'],
  payload
})


export const getBillingByPayerMetrics = (payload?: DocumentListRequestObj) => ({
  type: TYPES['GET_BILLING_BY_PAYERS_METRICS'],
  payload,
});

export const saveBillingByPayerMetrics = (payload?: ChartResponse) => ({
  type: TYPES['SAVE_BILLING_BY_PAYERS_METRICS'],
  payload,
});

export const checkDuplicateClients = (payload?: CheckDuplicateUsersRequests) => ({
  type: TYPES['CHECK_DUPLICATE_CLIENTS'],
  payload,
});

export const saveDuplicateClients = (payload?: DuplicateDataResponse[]) => ({
  type: TYPES['SAVE_DUPLICATE_CLIENTS_DATA'],
  payload,
});

export const LockUnlockDocuments = (payload) => ({
  type: TYPES['LOCK_UNLOCK_DOCUMENTS'],
  payload,
})

export const ApproveOrDisapproveDocuments = (payload) => ({
  type: TYPES['APPROVE_DISAPPROVE_DOCUMENTS'],
  payload,
})

export const saveApproveDisapproveDocuments = (payload?: number) => ({
  type: TYPES['SAVE_APPROVE_DISAPPROVE_DOCUMENTS'],
  payload,
})

export const getTimeClockArchiveById = (payload) => ({
  type: TYPES['GET_TIME_CLOCK_ARCHIVE_BY_ID'],
  payload,
});


export const saveTimeClockArchiveById = (payload) => ({
  type: TYPES['SAVE_TIME_CLOCK_ARCHIVE'],
  payload
})


export const assignCertificateToStaffs = (payload?: any) => ({
  type: TYPES['ASSIGN_CERTIFICATE_TO_STAFFS'],
  payload
})

export const getStaffDocTemplates = (payload?: any) => ({
  type: TYPES['GET_STAFF_DOC_TEMPLATES'],
  payload
})

export const saveStaffDocTemplates = (payload?: StaffTemplateList[]) => ({
  type: TYPES['SAVE_STAFF_DOC_TEMPLATES'],
  payload
})

export const getStaffDocStaffList = (payload?: any) => ({
  type: TYPES['GET_STAFF_DOC_STAFF_LIST'],
  payload
})

export const saveStaffDocStaff = (payload?: StaffTemplateList[]) => ({
  type: TYPES['SAVE_STAFF_DOC_STAFF_LIST'],
  payload
})

export const getStaffDocTemplateSettings = (payload?: any) => ({
  type: TYPES['GET_STAFF_DOC_TEMPLATE_SETTING'],
  payload
})

export const saveTemplateSettings = (payload?: TemplateSettings) => ({
  type: TYPES['SAVE_STAFF_DOC_TEMPLATE_SETTING'],
  payload
})

// export const getStaffDocSetting = (payload?: any) => ({
//   type: TYPES['GET_STAFF_DOC_SETTING'],
//   payload
// })

// export const saveStaffDocSetting = (payload?: DocumentSettings[]) => ({
//   type: TYPES['SAVE_STAFF_DOC_SETTING'],
//   payload
// })

// export const getStaffDocTemplateDetail = (payload?: any) => ({
//   type: TYPES['GET_STAFF_DOC_TEMPLATE_DETAIL'],
//   payload
// })

// export const saveStaffTemplateDetail  = (payload?: DocumentSettings) => ({
//   type: TYPES['SAVE_STAFF_DOC_TEMPLATE_DETAIL'],
//   payload
// })

export const getStaffGoals = (payload?: any) => ({
  type: TYPES['GET_STAFF_GOALS'],
  payload
})

export const saveStaffGoals = (payload?: StaffGoals[]) => ({
  type: TYPES['SAVE_STAFF_GOALS'],
  payload
})

export const getAllSkills = (payload?: any) => ({
  type: TYPES['GET_SKILL_SET'],
  payload
})

export const saveSkillSet = (payload?: StaffGoals[]) => ({
  type: TYPES['SAVE_SKILL_SET'],
  payload
})

export const insertStaffDoc = (payload?: any) => ({
  type: TYPES['INSERT_STAFF_DOC'],
  payload
})

export const updateStaffDoc = (payload?: any) => ({
  type: TYPES['UPDATE_STAFF_DOC'],
  payload
})


export const saveStaffDoc = (payload?: any) => ({
  type: TYPES['SAVE_STAFF_DOC'],
  payload
})

export const getStaffDocListing = (payload?: any) => ({
  type: TYPES['GET_STAFF_DOC_LISTING'],
  payload
})

export const saveStaffDocListing = (payload?: any) => ({
  type: TYPES['SAVE_STAFF_DOC_LISTING'],
  payload
})

export const saveStaffDocLoading = (payload?: any) => ({
  type: TYPES['SAVE_STAFF_DOC_LOADING_STATE'],
  payload
})

export const saveStaffDocCreateLoading = (payload?: any) => ({
  type: TYPES['SAVE_STAFF_DOC_CREATE_LOADING_STATE'],
  payload
})

export const getStaffDocDetailById = (payload?: any) => ({
  type: TYPES['GET_STAFF_DOC_BY_ID'],
  payload
})

export const saveStaffDocDetails = (payload?: any) => ({
  type: TYPES['SAVE_STAFF_DOC_BY_ID'],
  payload
})

export const getStaffDocSignature = (payload?: any) => ({
  type: TYPES['GET_STAFF_DOC_SIGNATURE'],
  payload
})

export const saveStaffDocSignature = (payload?: any) => ({
  type: TYPES['SAVE_STAFF_DOC_SIGNATURE'],
  payload
})

export const getStaffDocAttachments = (payload?: any) => ({
  type: TYPES['GET_STAFF_DOC_ATTACHMENTS'],
  payload
})

export const saveStaffDocAttachments = (payload?: any) => ({
  type: TYPES['SAVE_STAFF_DOC_ATTACHMENTS'],
  payload
})
export const applyStaffDocSignature = (payload?: any) => ({
  type: TYPES['APPLY_STAFF_DOC_SIGNATURE'],
  payload
})
export const getTrainingLinks = (payload?: any) => ({
  type: TYPES['GET_TRAINING_LINKS'],
  payload
})
export const saveTrainigLinks = (payload?: any) => ({
  type: TYPES['SAVE_TRAINING_LINKS'],
  payload
})

export const saveStaffDocumentSearchFilter = (payload) => ({
  type: TYPES['SAVE_STAFF_DOC_SEARCH_FILTERS'],
  payload,
});
export const getCalenderCategory = () => ({
  type: TYPES['GET_CALENDAR_CATEGORY'],
})
export const saveCalenderCategory = (payload: any) => ({
  type: TYPES['SAVE_CALENDAR_CATEGORY'],
  payload,
});
export const deleteCalendarCategory = (payload: any) => ({
  type: TYPES['DELETE_CALENDAR_CATEGORY'],
  payload
})
export const insertCalendarCategory = (payload?: insertUpdateCalendarSettingModal) => ({
  type: TYPES['INSERT_CALENDAR_CATEGORY'],
  payload
})
export const updateCalendarCategory = (payload?: insertUpdateCalendarSettingModal) => ({
  type: TYPES['UPDATE_CALENDAR_CATEGORY'],
  payload
})
export const getDefaultCalendarCategories = () => ({
  type: TYPES['GET_DEFAULT_CALENDAR_CATEGORYS'],
})
export const saveDefaultCalendarCategories = (payload: any) => ({
  type: TYPES['SAVE_DEFAULT_CALENDAR_CATEGORYS'],
  payload,
});
export const updateDefaultCalendarCategories = (payload?: any) => ({
  type: TYPES['UPDATE_DEFAULT_CALENDAR_CATEGORYS'],
  payload
})

export const getDocMetricsByTemplate = (payload?: DocumentListRequestObj) => ({
  type: TYPES['GET_METRICS_BY_TEMPLATE'],
  payload,
});

export const saveDocMetricsByTemplate = (payload?: ChartResponse) => ({
  type: TYPES['SAVE_METRICS_BY_TEMPLATE'],
  payload,
});

export const getDocMetricsByStatus = (payload?: DocumentListRequestObj) => ({
  type: TYPES['GET_METRICS_BY_DOC_STATUS'],
  payload,
});

export const saveDocMetricsByStatus = (payload?: ChartResponse) => ({
  type: TYPES['SAVE_METRICS_BY_DOC_STATUS'],
  payload,
});

export const getDocMetricsByServiceCode = (payload?: DocumentListRequestObj) => ({
  type: TYPES['GET_DOC_METRICS_BY_SERVICE_CODE'],
  payload,
});

export const saveDocMetricsByServiceCode = (payload?: ChartResponse) => ({
  type: TYPES['SAVE_DOC_METRICS_BY_SERVICE_CODE'],
  payload,
});

export const deleteStaffPlanAttachment = (payload?: any) => ({
  type: TYPES['DELETE_STAFF_PLAN_DOC_ATTACHMENT'],
  payload,
});

export const getServiceRuleList = () => ({
  type: TYPES['GET_SERVICE_RULE_LIST'],

})

export const saveServiceRulesList = (payload?: ServiceSetResult) => ({
  type: TYPES['SAVE_SERVICE_RULE_LIST'],
  payload
})

export const saveServiceRuleDataLoading = (payload?: boolean) => ({
  type: TYPES['SAVE_SERVICE_RULE_DATA_LOADING'],
  payload
})

export const deleteServiceRule = (payload?: number) => ({
  type: TYPES['DELETE_SERVICE_RULE'],
  payload
})

export const InsertServiceRule = (payload?: any) => ({
  type: TYPES['INSERT_SERVICE_RULE'],
  payload
})

export const updateServiceRule = (payload?: any) => ({
  type: TYPES['UPDATE_SERVICE_RULE'],
  payload
})

export const changeAppRoute = (payload?: any) => ({
  type: TYPES['DYNAMIC_CHANGE_APP_ROUTE'],
  payload
})

export const getUpcomingAppointments = (payload?: any) => ({
  type: TYPES['GET_UPCOMING_APPOINTMENTS'],
  payload
})

export const saveUpcomingAppointments = (payload?: any) => ({
  type: TYPES['SAVE_UPCOMING_APPOINTMENTS'],
  payload
})

export const getUpcomingComplianceCountData = (payload?: any) => ({
  type: TYPES['GET_UPCOMING_COMPLIANCE_COUNT_DATA'],
  payload
})

export const saveUpcomingComplianceCountData = (payload?: any) => ({
  type: TYPES['SAVE_UPCOMING_COMPLIANCE_COUNT_DATA'],
  payload
})

export const getSuperBill = (payload?: any) => ({
  type: TYPES['GET_CLIENT_DOC_SUPER_BILL'],
  payload
})

export const saveSuperBill = (payload?: any) => ({
  type: TYPES['SAVE_CLIENT_DOC_SUPER_BILL'],
  payload
})

export const clientLedgerFilterObj = (payload?: any) => ({
  type: TYPES['CLIENT_LEDGER_FILTER_OBJ'],
  payload
})


export const getClientLedgerByClientId = (payload?: any) => ({
  type: TYPES['GET_CLIENT_LEDGER_BY_CLIENT_ID'],
  payload
})

export const getClientLedgerById = (id?: any) => {
  return {
    type: TYPES['GET_CLIENT_LEDGER_BY_ID'],
    id
  };
};


export const saveClientLedgerById = (payload?: any) => {
  return {
    type: TYPES['SAVE_CLIENT_LEDGER_BY_ID'],
    payload
  };
};


export const saveClientLedgerByClientId = (payload?: any) => {
  return {
    type: TYPES['SAVE_CLIENT_LEDGER_BY_CLIENT_ID'],
    payload
  };
};

export const insertClientLedgerRecord = (payload?: any) => {
  return {
    type: TYPES['INSERT_CLIENT_LEDGER_RECORD'],
    payload
  };
};

export const deleteClientLedgerWithId = (id?: any) => {
  return {
    type: TYPES['DELETE_CLIENT_LEDGER_RECORD'],
    id
  };
};


export const getClientLedgerPaymentTypes = (isActive) => {
  return {
    type: TYPES['GET_CLIENT_LEDGER_PAYMENT_TYPES'],
    isActive
  };
};

export const saveClientLedgerPaymentTypes = (payload?: any) => {
  return {
    type: TYPES['SAVE_CLIENT_LEDGER_PAYMENT_TYPES'],
    payload
  };
};

export const saveStaffCredentialInfo = (payload?: any) => ({
  type: TYPES['SAVE_STAFF_CREDENTIAL_INFO'],
  payload
})

export const deleteStaffCredential = (payload: any) => ({
  type: TYPES['DELETE_STAFF_CREDENTIAL'],
  payload
})

export const getStaffCredentialList = (payload?: any) => ({
  type: TYPES['GET_STAFF_CREDENTIAL_LIST'],
  payload
})

export const saveStaffCredentialList = (payload?: any) => ({
  type: TYPES['SAVE_STAFF_CREDENTIAL_LIST'],
  payload
})

export const updateStaffCredential = (payload?: any) => ({
  type: TYPES['UPDATE_STAFF_CREDENTIAL'],
  payload
})

export const saveClientListChangedBoolean = (payload?: any) => ({
  type: TYPES['CLIENT_LIST_CHANGED'],
  payload
})

export const getCurrentLedgerBalance = (payload?: any) => ({
  type: TYPES['GET_CURRENT_LEDGER_BALANCE'],
  payload
})

export const saveCurrentLedgerBalance = (payload?: any) => ({
  type: TYPES['SAVE_CURRENT_LEDGER_BALANCE'],
  payload
})

export const clientLedgerBalanceChanged = (payload?: any) => ({
  type: TYPES['CLIENT_LEDGER_BALANCE_CHANGED'],
  payload
})

export const updateClientLedger = (payload?: any) => ({
  type: TYPES['UPDATE_CLIENT_LEDGER'],
  payload
})

export const saveStaffAuditFilters = (payload?: any) => ({
  type: TYPES['STAFF_AUDIT_FILTER'],
  payload
})

export const saveClientAuditFilters = (payload?: any) => ({
  type: TYPES['CLIENT_AUDIT_FILTER'],
  payload
})

export const saveTimeClockFilters = (payload?: any) => ({
  type: TYPES['TIME_CLOCK_FILTER'],
  payload
})

export const getStaffAuditLogList = (payload?: any) => ({
  type: TYPES['GET_STAFF_AUDIT_LOG_LIST'],
  payload
})

export const saveStaffAuditLogList = (payload?: any) => ({
  type: TYPES['SAVE_STAFF_AUDIT_LOG_LIST'],
  payload
})

export const saveStaffAuditLogMetaData = (payload?: any) => ({
  type: TYPES['SAVE_STAFF_AUDIT_LOG_META_DATA'],
  payload
})

export const staffAuditLogListChangedAction = (payload?: any) => ({
  type: TYPES['STAFF_AUDIT_LOG_LIST_CHANGED'],
  payload
})

export const getClientAuditLogList = (payload?: any) => ({
  type: TYPES['GET_CLIENT_AUDIT_LOG_LIST'],
  payload
})

export const saveClientAuditLogList = (payload?: any) => ({
  type: TYPES['SAVE_CLIENT_AUDIT_LOG_LIST'],
  payload
})

export const saveClientAuditLogMetaData = (payload?: any) => ({
  type: TYPES['SAVE_CLIENT_AUDIT_LOG_META_DATA'],
  payload
})

export const clientAuditLogListChangedAction = (payload?: any) => ({
  type: TYPES['CLIENT_AUDIT_LOG_LIST_CHANGED'],
  payload
})

export const assignAdditionalClientsToStaff = (payload?: any) => ({
  type: TYPES['ASSIGN_ADDITIONAL_CLIENTS_TO_STAFF'],
  payload
})

export const assignStaffToSite = (payload?: any) => ({
  type: TYPES['ASSIGN_STAFF_TO_SITE'],
  payload
})

export const fetchStaffListAgainAction = (payload?: any) => ({
  type: TYPES['FETCH_STAFF_LIST_AGAIN'],
  payload
})

export const getAttachedDocForCompliance = (payload?: any) => ({
  type: TYPES['GET_COMPLIANCE_ATTACHED_DOC_LIST'],
  payload
})

export const saveAttachedDocForCompliance = (payload?: any) => ({
  type: TYPES['SAVE_COMPLIANCE_ATTACHED_DOC_LIST'],
  payload
})

export const saveAdminBoardFilters = (payload?: any) => ({
  type: TYPES['ADMIN_BOARD_FILTERS'],
  payload
})

export const getSitesForClinic = (payload?: any) => ({
  type: TYPES['GET_SITES_FOR_CLINIC'],
  payload
})

export const saveSitesForClinic = (payload?: any) => ({
  type: TYPES['SAVE_SITES_FOR_CLINIC'],
  payload
})

export const getReminderSettings = (payload?: any) => ({
  type: TYPES['GET_REMINDER_SETTINGS'],
  payload
})

export const saveReminderSettings = (payload?: any) => ({
  type: TYPES['SAVE_REMINDER_SETTINGS'],
  payload
})

export const insertReminderSetting = (payload?: UpdateReminderModel) => ({
  type: TYPES['INSERT_REMINDER_SETTINGS'],
  payload
})

export const insertReminderEmailSetting = (payload?: EmailReminderSettings) => ({
  type: TYPES['INSERT_REMINDER_EMAIL_SETTINGS'],
  payload
})

export const insertReminderTextSetting = (payload?: TextReminderSettings) => ({
  type: TYPES['INSERT_REMINDER_TEXT_SETTINGS'],
  payload
})

export const insertReminderPhoneSetting = (payload?: PhoneReminderSettings) => ({
  type: TYPES['INSERT_REMINDER_PHONE_SETTINGS'],
  payload
})

export const getAuditLogs = (payload?: string) => ({
  type: TYPES['GET_AUDIT_LOGS'],
  payload
})

export const saveAuditLogs = (payload?: AuditLogData) => ({
  type: TYPES['SAVE_AUDIT_LOGS'],
  payload
})


export const getCpAccAccess = (payload?: any) => ({
  type: TYPES['GET_CP_ACC_ACCESS_LIST'],
  payload
})

export const saveCreateResponse = (payload?: any) => ({
  type: TYPES['SAVE_CP_ACCESS_CREATE_RESP'],
  payload
})

export const saveCpAccountAccess = (payload?: any) => ({
  type: TYPES['SAVE_CP_ACC_ACCESS_LIST'],
  payload
})

export const getCpAccAccessDetail = (payload?: any) => ({
  type: TYPES['GET_CP_ACC_ACCESS_DETAIL'],
  payload
})

export const createCpAccountAccess = (payload?: any) => ({
  type: TYPES['CREATE_CP_ACC_ACCESS'],
  payload
})

export const updateCpAccountAccess = (payload?: any) => ({
  type: TYPES['UPDATE_CP_ACC_ACCESS'],
  payload
})

export const loadingInClient = (payload: boolean) => ({
  type: TYPES['LOADING_IN_CLIENT'],
  payload
})


export const resetClientPortalAccessPwd = (payload: any) => ({
  type: TYPES['RESET_CP_ACCESS_PWD'],
  payload
})


export const insertclientMedication = (payload: any) => ({
  type: TYPES['INSERT_CLIENT_MEDICATION'],
  payload
})

export const updateClientMedication = (payload: any) => ({
  type: TYPES['UPDATE_CLIENT_MEDICATION'],
  payload
})

export const deleteClientMedication = (payload: any) => ({
  type: TYPES['DELETE_CLIENT_MEDICATION'],
  payload
})

export const getClientMedicationByClientId = (payload: any) => ({
  type: TYPES['GET_CLIENT_MEDICATION_BY_CLIENT_ID'],
  payload
})

export const getClientMedicationById = (payload: any) => ({
  type: TYPES['GET_CLIENT_MEDICATION_BY_ID'],
  payload
})

export const saveClientMedicationByClientId = (payload: any) => ({
  type: TYPES['SAVE_CLIENT_MEDICATION_BY_CLIENT_ID'],
  payload
})

export const saveClientMedicationById = (payload: any) => ({
  type: TYPES['SAVE_CLIENT_MEDICATION_BY_ID'],
  payload
})

export const updateOpenedPopUp = (payload?: number) => ({
  type: TYPES['SAVE_SELECTED_ITEM_IN_CALENDER'],
  payload
})

export const exportCaseloadReportToExcel = (payload: any) => ({
  type: TYPES['EXPORT_CASELOAD_REPORT_TO_EXCEL'],
  payload,
});

export const saveCaseloadReportToExcel = (payload: Boolean) => ({
  type: TYPES['SAVE_EXPORT_CASELOAD_REPORT_TO_EXCEL'],
  payload,
});

export const getInsuranceTypes = () => ({
  type: TYPES.GET_INSURANCE_TYPES,
});


export const saveInsuranceTypes = (payload: any) => ({
  type: TYPES['SAVE_INSURANCE_TYPES'],
  payload,
});

export const getDocumentTemplateTypes = () => ({
  type: TYPES.GET_DOCUMENT_TEMPLATE_TYPE,
});


export const saveDocumentTemplateTypes = (payload: any) => ({
  type: TYPES['SAVE_DOCUMENT_TEMPLATE_TYPE'],
  payload,
});

export const saveSocialDeterminants = (payload: any) => ({
  type: TYPES['SAVE_SOCIAL_DETERMINANTS'],
  payload,
});

export const getSocialDeterminants = (payload: any) => ({
  type: TYPES['GET_SOCIAL_DETERMINANTS'],
  payload,
});

export const saveSocialDeterminantsResponse = (payload: any) => ({
  type: TYPES['SAVE_SOCIAL_DETERMINANTS_RESPONSE'],
  payload,
});

export const updateSocialDeterminant = (payload: any) => ({
  type: TYPES['UPDATE_SOCIAL_DETERMINANTS'],
  payload,
});

export const loadingIndicatorAction = (payload: Boolean) => ({
  type: TYPES['LODING_INDICATOR'],
  payload,
});

export const getGlobalClinicSites = (payload?: any) => ({
  type: TYPES['GET_GLOBAL_SITES_FOR_CLINIC'],
  payload
})

export const saveGlobalClinicSites = (payload?: any) => ({
  type: TYPES['SAVE_GLOBAL_SITES_FOR_CLINIC'],
  payload
})

export const updateCustomRole = (payload?: any) => ({
  type: TYPES['UPDATE_CUSTOM_ROLE'],
  payload
})

export const customRoleChangedAction = (payload?: any) => ({
  type: TYPES['CUSTOM_ROLE_CHANGED'],
  payload
})

export const getDocAttachment = (payload?: any) => ({
  type: TYPES['GET_DOCUMENT_ATTACHMENT'],
  payload
})

export const saveDocAttachment = (payload?: any) => ({
  type: TYPES['SAVE_DOCUMENT_ATTACHMENT'],
  payload
})
export const getClientMessageLabels = (payload?: any) => ({
  type: TYPES['GET_CLIENT_MESSAGE_LABELS'],
  payload
})


export const saveClientMessageLabels = (payload?: any) => ({
  type: TYPES['SAVE_CLIENT_MESSAGE_LABELS'],
  payload
})

export const addNewClientMessageLabels = (payload?: any) => ({
  type: TYPES['ADD_CLIENT_MESSAGE_LABELS'],
  payload
})
export const updateClientMessageLabels = (payload?: any) => ({
  type: TYPES['UPDATE_CLIENT_MESSAGE_LABELS'],
  payload
})

export const deleteClientMessageLabels = (payload?: any) => ({
  type: TYPES['DELETE_CLIENT_MESSAGE_LABELS'],
  payload
})

export const moveClientMessageToLevel = (payload?: any) => ({
  type: TYPES['MOVE_CLIENT_MESSAGE_TO_LEVEL'],
  payload
})

export const SaveClientTabAdminBoardFilters = (payload?: any) => ({
  type: TYPES['SAVE_CLIENT_TAB_ADMIN_BOARD_FILTERS'],
  payload
})

export const getContactTypeList = (payload?: any) => ({
  type: TYPES['GET_CONTACT_TYPE_LIST'],
  payload
})
export const saveContactTypeList = (payload?: any) => ({
  type: TYPES['SAVE_CONTACT_TYPE_LIST'],
  payload
})
export const getCollateralContact = (payload?: any) => ({
  type: TYPES['GET_CLIENT_COLLATERAL_CONTACT'],
  payload
})
export const saveCollateralContact = (payload?: any) => ({
  type: TYPES['SAVE_CLIENT_COLLATERAL_CONTACT'],
  payload
})
export const saveCollateralContactInfo = (payload?: any) => ({
  type: TYPES['SAVE_CLIENT_COLLATERAL_CONTACT_INFO'],
  payload
})
export const updateCollateralContactInfo = (payload?: any) => ({
  type: TYPES['UPDATE_CLIENT_COLLATERAL_CONTACT_INFO'],
  payload
})
export const deleteCollateralContact = (payload?: any) => ({
  type: TYPES['DELETE_CLIENT_COLLATERAL_CONTACT'],
  payload
})
export const getCollateralContactById = (payload?: any) => ({
  type: TYPES['GET_CLIENT_COLLATERAL_CONTACT_BY_ID'],
  payload
})
export const saveCollateralContactById = (payload?: any) => ({
  type: TYPES['SAVE_CLIENT_COLLATERAL_CONTACT_BY_ID'],
  payload
})

export const setDefaultRoleToCustom = (payload?: any) => ({
  type: TYPES['SET_DEFAULT_ROLE_TO_CUSTOM'],
  payload
})
export const getClientDocAttachments = (payload?: any) => ({
  type: TYPES['GET_CLIENT_DOCUMENT_ATTACHMENTS'],
  payload
})
export const saveClientDocAttachments = (payload?: any) => ({
  type: TYPES['SAVE_CLIENT_DOCUMENT_ATTACHMENTS'],
  payload
})

export const loaderClients = (payload?: any) => ({
  type: TYPES['LOADER_CLIENTS'],
  payload
})

export const getComplianceAuditHistory = (payload?: any) => ({
  type: TYPES['GET_COMPLIANCE_AUDIT_HISTORY'],
  payload
})
export const saveComplianceAuditHistory = (payload?: any) => ({
  type: TYPES['SAVE_COMPLIANCE_AUDIT_HISTORY'],
  payload
})

export const removeDocUnits = (payload?: any) => ({
  type: TYPES['REMOVE_DOC_UNITS'],
  payload
})

export const recalculateDocUnits = (payload?: any) => ({
  type: TYPES['RECALCULATE_DOC_UNITS'],
  payload
})

export const docUnitsChangedAction = (payload?: any) => ({
  type: TYPES['DOC_UNITS_CHANGED'],
  payload
})


export const saveReminderLoadings = (payload?: any) => ({
  type: TYPES['SAVE_REMINDER_LOADINGS'],
  payload
})

export const updateTransSrvLineBillingStatus = (payload?: any) => ({
  type: TYPES['UPDATE_TRANSSRVLINE_BILLING_STATUS'],
  payload
})

export const getBillingStatus = (payload?: any) => ({
  type: TYPES['GET_BILLING_STATUSES'],
  payload
})

export const saveBillingStatus = (payload?: any) => ({
  type: TYPES['SAVE_BILLING_STATUSES'],
  payload
})

export const saveBillingStatusId = (payload?: any) => ({
  type: TYPES['SAVE_BILLING_STATUS_ID'],
  payload
})

export const getPosList = (payload?: any) => ({
  type: TYPES['GET_POS_LIST'],
  payload
})

export const savePosList = (payload?: any) => ({
  type: TYPES['SAVE_POS_LIST'],
  payload
})

export const addUpdateStaffComment = (payload?: any) => ({
  type: TYPES['SAVE_UPDATE_STAFF_COMMENT'],
  payload
})

export const getStaffComment = (payload?: any) => ({
  type: TYPES['GET_STAFF_COMMENT'],
  payload
})

export const saveStaffComment = (payload?: any) => ({
  type: TYPES['SAVE_STAFF_COMMENT'],
  payload
})

export const referralSourceDelete = (payload?: any) => ({
  type: TYPES['REFERRAL_SOURCE_DELETE'],
  payload
})

export const referralProviderDelete = (payload?: any) => ({
  type: TYPES['REFERRAL_PROVIDER_DELETE'],
  payload
})

export const SaveReferralSourceDelete = (payload?: any) => ({
  type: TYPES['SAVE_REFERRAL_SOURCE_DELETE_RESP'],
  payload
})

export const SaveReferralProviderDelete = (payload?: any) => ({
  type: TYPES['SAVE_REFERRAL_PROVIDER_DELETE_RESP'],
  payload
})

export const getMinServiceRuleList = () => ({
  type: TYPES['GET_MIN_SERVICE_RULE_LIST'],

})

export const saveMinServiceRulesList = (payload?: ServiceSetResult) => ({
  type: TYPES['SAVE_MIN_SERVICE_RULE_LIST'],
  payload
})

export const saveMinServiceRuleDataLoading = (payload?: boolean) => ({
  type: TYPES['SAVE_MIN_SERVICE_RULE_DATA_LOADING'],
  payload
})

export const deleteMinServiceRule = (payload?: number) => ({
  type: TYPES['DELETE_MIN_SERVICE_RULE'],
  payload
})

export const InsertMinServiceRule = (payload?: any) => ({
  type: TYPES['INSERT_MIN_SERVICE_RULE'],
  payload
})

export const updateMinServiceRule = (payload?: any) => ({
  type: TYPES['UPDATE_MIN_SERVICE_RULE'],
  payload
})

export const SaveAuthPage = (payload?: any) => ({
  type: TYPES['SAVE_AUTH_PAGE'],
  payload
})

export const getCms1500ByClaimId = (payload?: any) => ({
  type: TYPES['GET_CMS_1500_BY_CLAIM_ID'],
  payload
})

export const saveCms1500ByClaimId = (payload?: any) => ({
  type: TYPES['SAVE_CMS_1500_BY_CLAIM_ID'],
  payload
})


export const getServiceMinReportByClientId = (payload?: any) => ({
  type: TYPES['GET_SERVICE_MIN_REPORT_BY_CLIENT_ID'],
  payload
})

export const saveServiceMinReportByClientId = (payload?: any) => ({
  type: TYPES['SAVE_SERVICE_MIN_REPORT_BY_CLIENT_ID'],
  payload
})

export const getCms1500ForBatch = (payload?: any) => ({
  type: TYPES['GET_CMS_1500_FOR_BATCH'],
  payload
})

export const saveCms1500ForBatch = (payload?: any) => ({
  type: TYPES['SAVE_CMS_1500_FOR_BATCH'],
  payload
})


export const getAllStaffReports = (payload?: any) => ({
  type: TYPES['GET_ALL_STAFF_REPORTS'],
  payload
})

export const getStaffReports = (payload?: any) => ({
  type: TYPES['GET_STAFF_REPORTS'],
  payload
})

export const saveAllStaffReports = (payload?: any) => ({
  type: TYPES['SAVE_ALL_STAFF_REPORTS'],
  payload
})

export const saveStaffReports = (payload?: any) => ({
  type: TYPES['SAVE_STAFF_REPORTS'],
  payload
})

export const postStaffReports = (payload?: any) => ({
  type: TYPES['POST_STAFF_REPORTS'],
  payload
})

export const fetchStaffReportsAction = (payload?: any) => ({
  type: TYPES['FETCH_STAFF_REPORTS'],
  payload
})

export const fetchAllStaffReportsAction = (payload?: any) => ({
  type: TYPES['FETCH_ALL_STAFF_REPORTS'],
  payload
})

export const getCurrentTasks = (payload?: any) => ({
  type: TYPES['GET_CURRENT_TASK'],
  payload
})
export const saveCurrentTasks = (payload?: any) => ({
  type: TYPES['SAVE_CURRENT_TASK'],
  payload
})
export const updateTaskStatusValue = (payload?: any) => ({
  type: TYPES['UPDATE_TASK_STATUS_VALUE'],
  payload
})
export const currentTaskLodingState = (payload: any) => ({
  type: TYPES['CURRENT_TASK_LODING_STATE'],
  payload
})


export const getReportTypes = (payload?: any) => ({
  type: TYPES['GET_REPORT_TYPES'],
  payload
})

export const getReportsByReportType = (payload?: any) => ({
  type: TYPES['GET_REPORTS_BY_REPORT_TYPE'],
  payload
})

export const saveReportTypes = (payload?: any) => ({
  type: TYPES['SAVE_REPORT_TYPES'],
  payload
})

export const saveReportsByReportType = (payload?: any) => ({
  type: TYPES['SAVE_REPORTS_BY_REPORT_TYPE'],
  payload
})

export const fetchReportTypes = (payload?: any) => ({
  type: TYPES['FETCH_REPORT_TYPES'],
  payload
})

export const getReportColumns = (payload?: any) => ({
  type: TYPES['GET_REPORT_COLUMNS'],
  payload
})

export const saveReportColumns = (payload?: any) => ({
  type: TYPES['SAVE_REPORT_COLUMNS'],
  payload
})

export const getStaffReportColumnPreferences = (payload?: any) => ({
  type: TYPES['GET_STAFF_REPORT_COLUMN_PREFERENCES'],
  payload
})

export const saveStaffReportColumnPreferences = (payload?: any) => ({
  type: TYPES['SAVE_STAFF_REPORT_COLUMN_PREFERENCES'],
  payload
})

export const getRoleReports = (payload?: any) => ({
  type: TYPES['GET_ROLE_REPORTS'],
  payload
})

export const saveRoleReports = (payload?: any) => ({
  type: TYPES['SAVE_ROLE_REPORTS'],
  payload
})

export const assignReportsToRole = (payload?: any) => ({
  type: TYPES['ASSIGN_REPORTS_TO_ROLE'],
  payload
})

export const insertStaffReportColPreferences = (payload?: any) => ({
  type: TYPES['INSERT_STAFF_REPORT_COLUMN_PREFERENCES'],
  payload
})

export const getAssignedStaffOfReport = (payload?: any) => ({
  type: TYPES['GET_ASSIGNED_STAFF_OF_REPORT'],
  payload
})

export const saveAssignedStaffOfReport = (payload?: any) => ({
  type: TYPES['SAVE_ASSIGNED_STAFF_OF_REPORT'],
  payload
})

export const assignReportToMultipleStaff = (payload?: any) => ({
  type: TYPES['ASSIGN_REPORT_TO_MULTIPLE_STAFF'],
  payload
})

export const getStaffReportsWithTypes = (payload?: any) => ({
  type: TYPES['GET_STAFF_REPORTS_WITH_TYPES'],
  payload
})

export const saveStaffReportsWithTypes = (payload?: any) => ({
  type: TYPES['SAVE_STAFF_REPORTS_WITH_TYPES'],
  payload
})

export const getClientsForCaseloadMap = (payload?: any) => ({
  type: TYPES['GET_CLIENTS_FOR_CASELOAD_MAP'],
  payload
})

export const saveClientsForCaseloadMap = (payload?: any) => ({
  type: TYPES['SAVE_CLIENTS_FOR_CASELOAD_MAP'],
  payload
})

export const getAnnouncements = (payload?: any) => ({
  type: TYPES['GET_ANNOUNCEMENTS'],
  payload
})

export const saveAnnouncements = (payload?: any) => ({
  type: TYPES['SAVE_ANNOUNCEMENTS'],
  payload
})

export const deleteAnnouncements = (payload?: any) => ({
  type: TYPES['DELETE_ANNOUNCEMENTS'],
  payload
})

export const getAnnouncementsById = (payload?: any) => ({
  type: TYPES['GET_ANNOUNCEMENTS_BY_ID'],
  payload
})

export const saveAnnouncementsById = (payload?: any) => ({
  type: TYPES['SAVE_ANNOUNCEMENTS_BY_ID'],
  payload
})

export const saveAnnouncementsList = (payload?: any) => ({
  type: TYPES['SAVE_ANNOUNCEMENTS_LIST'],
  payload
})

export const updateAnnouncementsList = (payload?: any) => ({
  type: TYPES['UPDATE_ANNOUNCEMENTS_LIST'],
  payload
})

export const announcementRefreshState = (payload?: any) => ({
  type: TYPES['ANNOUNCEMENTS_REFRESH_STATE'],
  payload
})

export const setClientReportFilters = (payload?: any) => ({
  type: TYPES['SET_CLIENT_REPORT_FILTERS'],
  payload
})

export const getClientGeneralReport = (payload?: getReminderModel) => ({
  type: TYPES['GET_CLIENT_GENERAL_REPORT'],
  payload
})

export const saveClientGeneralReport = (payload?: any) => ({
  type: TYPES['SAVE_CLIENT_GENERAL_REPORT'],
  payload
})


export const saveReportLoading = (payload?: any) => ({
  type: TYPES['REPORT_LOADING'],
  payload
})

export const saveButtonPressed = (payload?: any) => ({
  type: TYPES['SAVE_BUTTON_PRESSED'],
  payload
})

export const getMileagePayRateByStaffId = (payload?: any) => ({
  type: TYPES['GET_MILEAGE_PAY_RATE_BY_STAFF_ID'],
  payload
})

export const saveMileagePayRateByStaffId = (payload?: any) => ({
  type: TYPES['SAVE_MILEAGE_PAY_RATE_BY_STAFF_ID'],
  payload
})

export const saveExportButtonPressed = (payload?: any) => ({
  type: TYPES['SAVE_CLIENT_REPORT_EXPORT_BUTTON'],
  payload
})

export const updateAuthBatchEndDate = (payload?: any) => ({
  type: TYPES['UPDATE_AUTH_BATCH_END_DATE'],
  payload
})

export const saveUpdateAuthBatchEndDate = (payload?: any) => ({
  type: TYPES['SAVE_UPDATE_AUTH_BATCH_END_DATE'],
  payload
})

export const reactivateTreatmentPlan = (payload?: any) => ({
  type: TYPES['REACTIVATE_TREATMENT_PLAN'],
  payload
})


export const getCustomerInvoices = (payload?: any) => ({
  type: TYPES['GET_SUBSCRIPTIONS_INVOICES'],
  payload
})

export const saveCustomerInvoices = (payload?: any) => ({
  type: TYPES['SAVE_SUBSCRIPTIONS_INVOICES'],
  payload
})

export const getCustomerPaymentMethods = (payload?: any) => ({
  type: TYPES['GET_CUSTOMER_PAYMENTS_METHODS'],
  payload
})

export const saveCustomerPaymentMethods = (payload?: any) => ({
  type: TYPES['SAVE_CUSTOMER_PAYMENTS_METHODS'],
  payload
})

export const getCustomerSubscriptions = (payload?: any) => ({
  type: TYPES['GET_CUSTOMER_SUBSCRIPTIONS'],
  payload
})

export const saveCustomerSubscriptions = (payload?: any) => ({
  type: TYPES['SAVE_CUSTOMER_SUBSCRIPTIONS'],
  payload
})

export const subsCriptionsLoading = (payload?: any) => ({
  type: TYPES['SUBSCRIPTIONS_LOADING'],
  payload
})

export const get837ClaimById = (payload?: any) => ({
  type: TYPES['GET_837_CLAIM_BY_ID'],
  payload
})

export const save837ClaimById = (payload?: any) => ({
  type: TYPES['SAVE_837_CLAIM_BY_ID'],
  payload
})

export const saveRemittanceData = (payload?: any) => ({
  type: TYPES['SAVE_REMITTANCE_DATA'],
  payload
})

export const getFullRemittanceById = (payload?: any) => ({
  type: TYPES['GET_FULL_REMITTANCE_BY_ID'],
  payload
})

export const saveFullRemittanceById = (payload?: any) => ({
  type: TYPES['SAVE_FULL_REMITTANCE_BY_ID'],
  payload
})


export const getClientsMedication = (payload?: any) => ({
  type: TYPES['GET_CLIENTS_MEDICATION'],
  payload
})

export const saveClientsMedication = (payload?: any) => ({
  type: TYPES['SAVE_CLIENTS_MEDICATION'],
  payload
})

export const attachPaymentMethods = (payload?: any) => ({
  type: TYPES['ATTACH_SUBSCRIPTION_PAY_METHOD'],
  payload
})


export const getStripeCustomer = (payload?: any) => ({
  type: TYPES['GET_STRIPE_CUSTOMER'],
  payload
})

export const saveStripeCustomer = (payload?: any) => ({
  type: TYPES['SAVE_STRIPE_CUSTOMER'],
  payload
})

export const updateDefaultPaymentMethod = (payload?: any) => ({
  type: TYPES['UPDATE_DEFAULT_PAYMENT_METHOD'],
  payload
})

export const refreshTP = (payload?: any) => ({
  type: TYPES['REFRESH_TP'],
  payload
})

export const refreshBillingClaim = (payload?: any) => ({
  type: TYPES['REFRESH_BILLING_CLAIM'],
  payload
})

export const savePaymentToken = (payload?: any) => ({
  type: TYPES['SAVE_PAYMENT_TOKEN'],
  payload
})

export const getClientPrintedDocuments = (payload?: any) => ({
  type: TYPES['GET_CLIENTS_PRINTED_DOCUMENT'],
  payload
})

export const saveClientPrintedDocuments = (payload?: any) => ({
  type: TYPES['SAVE_CLIENTS_PRINTED_DOCUMENT'],
  payload
})

export const deleteClientPrintedDocuments = (payload?: any) => ({
  type: TYPES['DELETE_CLIENTS_PRINTED_DOCUMENT'],
  payload
})

export const saveInitiateDocModel = (payload?: any) => ({
  type: TYPES['SAVE_INITIATE_DOC_MODEL'],
  payload
})

export const getDischargeReasons = (payload?: any) => ({
  type: TYPES['GET_DISCHARGE_REASONS'],
  payload
})

export const saveDischargeReasons = (payload?: any) => ({
  type: TYPES['SAVE_DISCHARGE_REASONS'],
  payload
})
