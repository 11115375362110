import axios from "axios";
import { useEffect, useState } from "react";
import "./style.scss";

interface Article {
    id: number;
    title: string;
    html_url: string;
  }
  
  interface ApiResponse {
    articles: Article[];
  }

export const KnowledgeBase = () => {

    const [articles, setArticles] = useState<Article[]>([]);
  
    useEffect(() => {
      fetchArticles();

    }, []);

    async function fetchArticles() {
        try {
            const response = await axios.get<ApiResponse>("https://notenetic.zendesk.com/api/v2/help_center/en-us/articles?sort_by=title&sort_order=asc");
            if (response && response.data) {
                setArticles(response.data.articles);
            }
        } catch (error) {
      
        }
    }

  return (
    <div>
      <h4 className="address-title text-grey  ml-2 mb-3">
        <span className="f-24">Knowledge Base</span>
      </h4>
      <hr className="custom-hr" /> 
      <ul className="list-unstyled mb-0 details-info mt-2">
        {articles.map((article) => (
          <li key={article.id} className="ml-2 mx-1 cursor-pointer valueColor fw-600 d-flex align-items-center">
            <p className="mb-0 col-md-6 labelColor mt-2  ">
                <a href={article.html_url} target="blank" className="kblinks">{article.title}</a>
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
}