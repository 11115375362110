/**
 * App.js Layout Start Here
 */
import React, {useEffect } from "react";
import { Error } from "@progress/kendo-react-labels";
import { ComboBox, ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";

export interface DropDownKendoRctProps {
  data?: any,
  textField?: string,
  onChange?: (newValue: ComboBoxChangeEvent) => void
  error?: any,
  name?: string,
  value?: any,
  label?: string,
  validityStyles?: any,
  required?: boolean,
  dataItemKey?: string,
  defaultValue?: string,
  disabled?: boolean,
  suggest?: boolean,
  placeholder?: string,
  itemRender?: any,
  valueRender?: any,
  className?: any,
  autoClose?: boolean
  ref?: any
  onFocus?: any
  onBlur?: any
  popupSettings?: any
  onOpen?: any
  opened?: boolean
  onClose?: any
  loading?: boolean
}

function DropDownKendoRct(props: DropDownKendoRctProps) {
  const {
    data,
    textField,
    onChange,
    error,
    name,
    value,
    label,
    validityStyles,
    required,
    dataItemKey,
    defaultValue,
    disabled,
    suggest,
    placeholder,
    itemRender,
    valueRender,
    className,
    ref,
    onFocus,
    onBlur,
    popupSettings,
    onOpen,
    opened,
    onClose,
    loading
  } = props


  // useEffect()

  const [listData, setListData] = React.useState(data.slice());
  useEffect(() => {
    if (data?.length > 0) {
      setListData(data.slice())
    }
  }, [data])

  const filterData = (filter) => {
    const newData = data.slice();
    return filterBy(newData, filter);
  };

  const filterChange = (event) => {
    setListData(filterData(event.filter));
  };

  return (
    <div>
      <label>{label}</label>
      <ComboBox
        data={listData.length > 0 ? listData : data}
        onChange={onChange}
        textField={textField}
        name={name}
        value={value}
        label={""}
        dataItemKey={dataItemKey}
        validityStyles={validityStyles}
        required={required}
        defaultValue={defaultValue}
        filterable={true}
        onFilterChange={filterChange}
        disabled={disabled}
        suggest={suggest}
        itemRender={itemRender}
        placeholder={placeholder}
        valueRender={valueRender}
        className={className}
        ref={ref}
        onFocus={onFocus}
        onBlur={onBlur}
        popupSettings={popupSettings}
        onOpen={onOpen}
        opened={opened}
        onClose={onClose}
        loading={loading}

      />
      {error && <Error>{error}</Error>}
    </div>
  );
}
export default DropDownKendoRct;
