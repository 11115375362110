import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { ClientService } from "../../../../../../services/clientService";
import NotificationManager from "react-notifications/lib/NotificationManager";
import APP_ROUTES from "src/helper/app-routes";
import { useLocation } from "react-router";
import { renderErrors } from "src/helper/error-message-helper";

const GeneralAnxietyDisorder = ({ list, questionId, response,isPrint }) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const selectedClientId = useSelector((state) => state.selectedClientId);
  const [loading, setLoading] = useState(false);
  const [listItems, setListItems] = useState(list);
  const OPTIONS_MAP = {
    "0": "0 - Not at all",
    "1": "1 - Several days",
    "2": "2 - More than half day",
    "3": "3 - Nearly every day"
  };
  useEffect(() => {
    calculateSum();
  }, [listItems]);

  useEffect(() => {
    setListItems(list);
  }, [list]);

  useEffect(() => {
    const updatedGAD = list.map((question) => {
      const matchingQuestion = response?.find(
        (item) => item.questionId === question.questionId
      );
      if (matchingQuestion) {
        return { ...question, value: parseInt(matchingQuestion.value) };
      }
      return question;
    });
    setListItems(updatedGAD);
  }, [response]);

  // State for storing the total sum
  const [totalSum, setTotalSum] = useState(0);

  // Function to handle change in dropdown values
  const handleDropdownChange = (itemId, value) => {
    // Update the listItems state with the new value
    const updatedListItems = listItems?.map((item) => {
      if (item.id === itemId) {
        return { ...item, value: parseInt(value) };
      }
      return item;
    });
    setListItems(updatedListItems);
  };
  const renderAnswer = (item) => {
    const value = item.value;
    return (
          <select className="form-select" disabled>
            <option>{OPTIONS_MAP[value]}</option>
          </select>
        );
  }
  // Function to calculate the sum of all dropdown values
  const calculateSum = () => {
    const sum = listItems?.reduce(
      (acc, currentItem) => acc + currentItem.value,
      0
    );
    setTotalSum(sum);
  };

  const handleSubmit = async () => {
    await ClientService.insertClientQuestionnaire(
      selectedClientId,
      totalSum,
      listItems,
      questionId
    )
      .then((result) => {
        let questionList = result.resultData;
        navigate(APP_ROUTES.QUESTIONNAIRE);
        NotificationManager.success("Add successfully");
      })
      .catch((error) => {
        setLoading(false);
        renderErrors(error.message);
      });
  };

  return (
    <div className="col-12 selectMinWidth">
      <div className="mt-3   phq_main_table ">
      <div className="head-4">GAD7 - General Anxiety Disorder</div>
        <label className="border-bottom pb-2">
          Over the last two weeks, How often have you been bothered by any of
          the following? (Use the drop down list to select the application
          amount.)
        </label>
      </div>
      {listItems?.map((item) => (
      <div
        key={item.id}
        className={`row mb-3 py-2 border-bottom ml-1 ${
          [4].includes(item.id) ? "q-mb-70 " : ""
        }`}
      >
        <div className="col-12">
          <div>
            <label>
              {item?.id}. {item?.name}:
            </label>
          </div>
        </div>
        <div className="col-12  select_table_phq select-table-drops">
          {isPrint
            ? renderAnswer(item)
            : (
              <select
                value={item?.value}
                onChange={(e) => handleDropdownChange(item?.id, e.target.value)}
                disabled={state == null ? false : true}
              >
                <option value={0}>0 - Not at all</option>
                <option value={1}>1 - Several days</option>
                <option value={2}>2 - More than half day</option>
                <option value={3}>3 - Nearly every day</option>
              </select>
            )}
        </div>
      </div>
    ))}
      <div className="text-start justify-content-start total_phq col-12">
        <label className="mb-0">Total Score: </label>
        <p className="submitButon mb-0">{totalSum}</p>
      </div>
      {/* <div style={{ display: "flex", justifyContent: "end" }}> */}
      {state == null && ! isPrint? (
        <div className="text-start mt-3 col-12">
          <button onClick={handleSubmit} className="submitButon ">
            Submit
          </button>
        </div>
      ) : (
        ""
      )}
      {/* </div> */}
      <label className=" mt-3">
        Copyright © 1999 Pfizer Inc. All rights reserved. Reproduced with
        permission.
      </label>
    </div>
  );
};

export default GeneralAnxietyDisorder;
