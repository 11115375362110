import { useSelector } from "react-redux";
export const permissionEnum = {

  /* Client Module */
  ADD_CLIENTS: 100,
  DISCHARGE_REACTIVATE_CLIENT: 101,
  EDIT_CLIENT_PROFILE: 102,
  MANAGE_CLIENT_SERVICES: 103,
  MANAGE_TREATMENT_PLAN: 104,
  MANAGE_CONTACT_NOTES: 105,
  MANAGE_CLIENT_FILES: 106,
  UPDATE_CLIENT_COMPLIANCE: 132,
  /* Client Module */

  /* Staff Module */
  ADD_STAFF: 107,
  DEACTIVATE_REACTIVATE_STAFF: 108,
  EDIT_STAFF_PROFILE: 109,
  MANAGE_STAFF_SERVICES: 110,
  MANAGE_STAFF_CASELOAD: 111,
  MANAGE_CERTIFICATIONS: 112,
  MANAGE_STAFF_FILES: 113,
  MANAGE_STAFF_SITES: 114,
  MANAGE_STAFF_SETTINGS: 115,
  MANAGE_STAFF_TEAM: 116,
  MANAGE_STAFF_TEMPLATE: 117,
  VIEW_STAFF_PROFILE: 131,
  EDIT_DEMOGRAPHICS: 134,
  CAN_SEE_STAFF_PERMISSION_ID: 131,
  CAN_EDIT_STAFF_PERMISSION_ID: 109,
  STAFF_MODULE_NAME: 'STAFF',
  /* Staff Module */

  /* Calender Module */
  CREATE_EVENTS_OWN_EVENT: 118,
  CREATE_EVENTS_FOR_OTHER_STAFF: 119,
  MANAGE_CALENDAR_SETTINGS: 120,
  CAN_SEE_ALL_EVENTS_OTHER_THAN_CASELOAD: 121,
  /* Calender Module */

  /* Document Module */
  TRASH_UN_TRASH_DOCUMENT: 122,
  CAN_EDIT_MY_OWN_DOCUMENTS: 123,
  CAN_LOCK_UNLOCK_MY_OWN_DOCUMENTS: 124,
  VIEW_HISTORY: 125,
  CAN_SEE_OTHER_STAFF_DOC: 127,
  CAN_EDIT_OTHER_STAFF_DOC: 128,
  CAN_LOCK_UNLOCK_OTHER_STAFF_DOC: 129,
  CAN_SEE_STAFF_DOC_PERMISSION_ID: 127,
  CAN_EDIT_STAFF_DOC_PERMISSION_ID: 128,
  DOC_MODULE_NAME: 'DOCUMENTS',
  /* Document Module */

  /* Miscellaneous Module */
  MANAGE_AUTHORIZATIONS: 126,
  SHOW_CLINIC_FILES: 130,
  SHOW_CLINIC_FILES: 130,
  MANAGE_CLIENT_COMPLIANCE: 133,
  DOCUMENT_BILLING_STATUS_PAID: 108,
  MANAGE_DEFAULT_GOALS:135,
  /* Miscellaneous Module */


}

export const PermissionHelper = (data) => {
  if (data.length > 0) {
    let modulePerm = [];
    for (var i = 0; i < data.length; i++) {
      let modulePermissionList = data[i].modulePermissionList
      if (modulePermissionList.length > 0) {
        for (const permission of modulePermissionList) {
          modulePerm[permission.enumId] = permission.isHavePermissions
        }
      }
    }
    return modulePerm;
  };
}

export const userIsAdmin = (roleId) => {
  if (roleId === 1 || roleId === 2) {
    return true
  } else {
    return false
  }
}


export const userIsSuperAdmin = (roleId) => {
  if (roleId?.toString() === '1') {
    return true
  } else {
    return false
  }
}

export const userIsClinician = (roleId) => {
  if (roleId === 3) {
    return true
  } else {
    return false
  }
}

export const userIsQA = (roleId) => {
  if (roleId === 4) {
    return true
  } else {
    return false
  }
}

export const staffCanViewOtherStaffProfile = (canViewStaffProfile, roleId, isHrManager, loginStaffId, staffId) => {
  if (canViewStaffProfile
    || roleId === 1
    || isHrManager
    || loginStaffId === staffId)
    return true
  else
    return false
}

export const CanCheckEligibility = () => {
  const isBillingManager = useSelector((state) => state.getRolePermission.isBillingManager);
  const staffLoginInfo = useSelector((state) => state.getStaffReducer);
  //Billing Mgr or SA or AllowEligibiliyCheck within staff profile.
  return (isBillingManager
    || staffLoginInfo.roleId === 1
    || staffLoginInfo.allowEligibilityChecks)

}

export const userCanAccessStaffInfo = (staffLoginInfo, userAccessPermission) => {
  const hasSuperAdminAccess = userIsSuperAdmin(staffLoginInfo?.roleId);
  const hasPermissionAccess = userAccessPermission[permissionEnum.ADD_STAFF] ||
    userAccessPermission[permissionEnum.EDIT_STAFF_PROFILE] ||
    userAccessPermission[permissionEnum.VIEW_STAFF_PROFILE];
  return hasSuperAdminAccess || hasPermissionAccess;
};


export const payStatusEnum = {
  PAID: 'paid',
  UNPAID: 'unpaid',
  SUSPENDED:'suspended'
}