import { useDispatch, useSelector } from "react-redux";
import ReportDrawerContainer from "./report-drawer";
import { useEffect, useState } from "react";
import { getReportColumns, getStaffReportColumnPreferences } from "src/redux/actions";
import { RoleService } from "src/services/rolesService";
import { userIsSuperAdmin } from "src/helper/permission-helper";
import { TabStripTab, TabStrip } from "@progress/kendo-react-layout";
import Tab2 from "./tab2reportManager";
import Tab1 from "./tab1ReportManager";

const SELECTED_FIELD = "selected";
function ReportManager() {
  const dispatch = useDispatch();
  const { staffReportWithTypeResp } = useSelector((state: any) => state.StaffReducer);
  const [selectedreport, setSelectedReport] = useState(null);
  const staffId = useSelector((state: any) => state.loggedIn?.staffId);
  const staffLoginInfo = useSelector((state: any) => state.getStaffReducer);

  const canManageReports = useSelector((state: any) => state.getRolePermission.canManageReports);
  const [selected, setSelected] = useState(0);

  function onReportChange(state: any) {
    const data = {
      reportId: state,
      staffId: staffId
    }
    const data1 = {
      reportId: state
    }
    dispatch(getReportColumns(data1))
    dispatch(getStaffReportColumnPreferences(data))
    setSelectedReport(state)
  }

  function renderLeftPanel() {
    return (
      <div className="inner-dt col-md-3 col-lg-2">
        <ReportDrawerContainer onStateChange={onReportChange} />
      </div>
    );
  }

  const [isAccepted, setIsAccepted] = useState(false)

  const [roleData, setRoleData] = useState([])

  const getRoles = async () => {
    await RoleService.getRoles()
      .then((result) => {
        let list = result.resultData;
        setRoleData(list);
      })
      .catch((error) => {
        // renderErrors(error.message);
      });
  };

  useEffect(() => {
    if (!isAccepted) {
      getRoles()
      setIsAccepted(true)
    }
  }, [])


  const handleSelect = (e: any) => {
    setSelected(e.selected);
  };



  return (
    <div>
      
      <div className="d-flex flex-wrap">
        {renderLeftPanel()}

        <div className="col-md-9 col-lg-10 padding_zero">
          <div className="message-page minHeightTabs">
            <>
              <TabStrip className="setting-tabs-staff" selected={selected} onSelect={handleSelect}>
                <TabStripTab title="Report">
                  <Tab1 selectedReport={staffReportWithTypeResp[0]?.reports[0]?.reportId} />
                </TabStripTab>

                {(canManageReports || userIsSuperAdmin(staffLoginInfo?.roleId)) &&
                  <TabStripTab title="Reports Settings">
                    <Tab2 selectedReport={staffReportWithTypeResp[0]?.reports[0]?.reportId} roleData={roleData} />
                  </TabStripTab>
                }
              </TabStrip>
            </>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ReportManager