import { takeLatest, put } from 'redux-saga/effects';
import * as TYPES from '../actions/types';
import { SaveBillingCount, SaveServiceUnitsRender, SaveTaskFilters, getClockStatus, loaderAction, saveClientByGenderCount, saveClientCount, saveClockStatus, saveTimeClockArchiveById, saveTimeClockById, saveUpdateStaffTimeClockByIdResponse,saveTrainigLinks ,saveUpcomingAppointments,saveUpcomingComplianceCountData, saveInsuranceTypes, customRoleChangedAction,saveCurrentTasks,currentTaskLodingState,saveAnnouncements,saveAnnouncementsById,announcementRefreshState, saveDischargeReasons} from '../actions';
import { dashboardServices } from 'src/services/dashboardService';
import { BillingCountResponse, ClientCountResponse, ServiceUnitRenderResponse ,TrainingLinksResponse,UpcomingAppointmentsResponse,UpcomingComplianceCountResponse} from 'src/dataModels/dashboardResponseModel';
import { GenericResponse } from 'src/dataModels/authSetModels';
import { TimeClockedByIdResponse } from 'src/dataModels/clinicResponseModels';
import { NotificationManager } from "react-notifications";
import NOTIFICATION_MESSAGE from 'src/helper/notification-messages';


export function* getClientCountSaga() {
    yield takeLatest(TYPES.GET_CLIENT_COUNT, getClientCountWorker)
}

function* getClientCountWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: ClientCountResponse = yield dashboardServices.clientCount(param['payload']);
        yield put(loaderAction(false));
        yield put(saveClientCount(response))
    }
    catch (err) {
    }

}

export function* getClientByGenderCountSaga() {
    yield takeLatest(TYPES.GET_CLIENT_BY_GENDER_COUNT, getClientByGenderCountWorker)
}

function* getClientByGenderCountWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: any = yield dashboardServices.clientByGenderCount(param['payload']);
        yield put(loaderAction(false));
        yield put(saveClientByGenderCount(response))
    }
    catch (err) {
    }

}

// export function* getDiagnosisCountSaga() {
//     yield takeLatest(TYPES.GET_CLIENT_DIAGNOSIS_COUNT, getDiagnosisCountWorker)
// }

// function* getDiagnosisCountWorker(param: any) {
//     try {
//         yield put(loaderAction(true));
//         const response: any = yield dashboardServices.diagnosisCount();
//         yield put(loaderAction(false));
//         yield put(SaveClientDiagnosisCount(response))
//     }
//     catch (err) {
//     }

// }

export function* getServiceUnitsRenderSaga() {
    yield takeLatest(TYPES.GET_SERVICE_UNITS_RENDER, getServiceUnitsRenderWorker)
}

function* getServiceUnitsRenderWorker(param: any) {
    try {
        // yield put(loaderAction(true));
        const response: ServiceUnitRenderResponse = yield dashboardServices.serviceUnitsRender(param['payload']);
        // yield put(loaderAction(false));
        yield put(SaveServiceUnitsRender(response.resultData))
    }
    catch (err) {
    }

}

export function* getBillingCountSaga() {
    yield takeLatest(TYPES.GET_BILLING_COUNTS, getBillingCountWorker)
}

function* getBillingCountWorker(param: any) {
    try {
        // yield put(loaderAction(true));
        const response: BillingCountResponse = yield dashboardServices.billingCounts(param['payload']);
        // yield put(loaderAction(false));
        yield put(SaveBillingCount(response.resultData))
    }
    catch (err) {
    }

}
export function* saveTaskFilterSaga() {
    yield takeLatest(TYPES.TASK_FILTER, saveTaskFilterWorker)
}

function* saveTaskFilterWorker(param: any) {
    try {
        yield put(SaveTaskFilters(param))
    }
    catch (err) {
    }

}


export function* getClockStatusSaga() {
    yield takeLatest(TYPES.GET_CLOCK_STATUS, getClockStatusWorker)
}

function* getClockStatusWorker(param: any) {
    try {
        const response: GenericResponse = yield dashboardServices.getStaffClockStatus();
        yield put(saveClockStatus(response.resultData))
    }
    catch (err) {
    }
}


export function* updateTImeClockSaga() {
    yield takeLatest(TYPES.UPDATE_TIME_CLOCK, updateTImeClockWorker)
}

function* updateTImeClockWorker(param: any) {
    try {
        const response: GenericResponse = yield dashboardServices.insertClockStatus();
        yield put(getClockStatus())
    }
    catch (err) {
    }
}

export function* getTimeClockByIdSaga() {
    yield takeLatest(TYPES.GET_TIME_CLOCK_BY_ID, getTimeClockByIdworker)
}

function* getTimeClockByIdworker(param: any) {
    try {
        // yield put(loaderAction(true));
        const response: TimeClockedByIdResponse = yield dashboardServices.getTimeClockById(param['payload']);
        yield put(saveTimeClockById(response?.resultData));
        // yield put(loaderAction(false));
    }
    catch (err) {
    }
}

export function* updateTimeClockByIdSaga() {
    yield takeLatest(TYPES.UPDATE_STAFF_TIME_CLOCK, updateTimeClockByIdworker)
}

function* updateTimeClockByIdworker(param: any) {
    try {
        // yield put(loaderAction(true));
        const response: any = yield dashboardServices.updateStaffTimeClockById(param['payload']);
        // yield put(loaderAction(false));
        if (response?.statusCode === 200) {
            yield put(saveUpdateStaffTimeClockByIdResponse(response?.resultData))
            NotificationManager.success(NOTIFICATION_MESSAGE.SUCCESS);
        }
    }
    catch (err) {
    }
}

export function* getTimeClockArchiveByIdSaga() {
    yield takeLatest(TYPES.GET_TIME_CLOCK_ARCHIVE_BY_ID, getTimeClockArchiveByIdWorker)
}

function* getTimeClockArchiveByIdWorker(param: any) {
    try {
        // yield put(loaderAction(true));
        const response: any = yield dashboardServices.getTimeClockArchiveById(param['payload']);
        // yield put(loaderAction(false));
        if (response?.statusCode === 200) {
            yield put (saveTimeClockArchiveById(response?.resultData))
        }
    }
    catch (err) {
    }
}


export function* getTrainingLinksSaga() {
    yield takeLatest(TYPES.GET_TRAINING_LINKS, getTrainingLinks)
}

function* getTrainingLinks(param: any) {
    try {
        // yield put(loaderAction(true));
        const response: TrainingLinksResponse = yield dashboardServices.getTrainingLinks(param['payload']);
        // yield put(loaderAction(false));
        if (response?.statusCode === 200) {
            yield put (saveTrainigLinks(response?.resultData))
        }
    }
    catch (err) {
    }
}

export function* getUpcomingAppointmentsSaga() {
    yield takeLatest(TYPES.GET_UPCOMING_APPOINTMENTS, getUpcomingAppointmentsWorker)
}

function* getUpcomingAppointmentsWorker(param: any) {
    try {
        // yield put(loaderAction(true));
        const response: UpcomingAppointmentsResponse = yield dashboardServices.getUpcomingAppointments(param['payload']);
        // yield put(loaderAction(false));
        if (response?.statusCode === 200) {
            yield put (saveUpcomingAppointments(response?.resultData))
        }
    }
    catch (err) {
    }
}

export function* getUpcomingComplianceCountDataSaga() {
    yield takeLatest(TYPES.GET_UPCOMING_COMPLIANCE_COUNT_DATA, getUpcomingComplianceCountDataWorker)
}

function* getUpcomingComplianceCountDataWorker(param: any) {
    try {
        // yield put(loaderAction(true));
        const response: UpcomingComplianceCountResponse = yield dashboardServices.getUpcomingComplianceCount(param['payload']);
        // yield put(loaderAction(false));
        if (response?.statusCode === 200) {
            yield put (saveUpcomingComplianceCountData(response?.resultData))
        }
    }
    catch (err) {
    }
}

export function* getInsuranceTypesSaga() {
    yield takeLatest(TYPES.GET_INSURANCE_TYPES, getInsuranceTypesWorker)
}

function* getInsuranceTypesWorker() {
    try {
        // yield put(loaderAction(true));
        const response: any = yield dashboardServices.getInsuranceTypes();
        // yield put(loaderAction(false));
        if (response?.statusCode === 200) {
            yield put (saveInsuranceTypes(response?.resultData))
        }
    }
    catch (err) {
    }
}

export function* updateCustomRoleSaga() {
    yield takeLatest(TYPES.UPDATE_CUSTOM_ROLE, updateCustomRoleWorker)
}

function* updateCustomRoleWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: any = yield dashboardServices.updateCustomRole(param['payload']);
        if (response?.statusCode === 200) {
            yield put (customRoleChangedAction(true))
            yield put(loaderAction(false));
        }
    }
    catch (err) {
    }
}


export function* getCurrentTasksSaga() {
    yield takeLatest(TYPES.GET_CURRENT_TASK, getCurrentTasksWorker)
}

function* getCurrentTasksWorker(param: any) {
    try {
        const response: any = yield dashboardServices.getCurrentTasks(param['payload']);
        if (response?.statusCode === 200) {
            yield put (saveCurrentTasks(response?.resultData))
        }
    }
    catch (err) {
    }
}

export function* updateTaskStatusValueSaga() {
    yield takeLatest(TYPES.UPDATE_TASK_STATUS_VALUE, updateTaskStatusValueWorker)
}

function* updateTaskStatusValueWorker(param: any) {
    try {
        yield put (currentTaskLodingState(false));
        const response: any = yield dashboardServices.updateTaskStatusValue(param['payload']);
        if (response?.statusCode === 200) {
            yield put (currentTaskLodingState(true))
        }
    }
    catch (err) {
    }
}


export function* getAnnouncementsSaga() {
    yield takeLatest(TYPES.GET_ANNOUNCEMENTS, getAnnouncementsWorker)
}

function* getAnnouncementsWorker(param: any) {
    try {
        const response: any = yield dashboardServices.getAnnouncements(param['payload']);
        if (response?.statusCode === 200) {
            yield put (saveAnnouncements(response?.resultData))
        }
    }
    catch (err) {
    }
}


export function* getAnnouncementsByIdSaga() {
    yield takeLatest(TYPES.GET_ANNOUNCEMENTS_BY_ID, getAnnouncementsByIdWorker)
}

function* getAnnouncementsByIdWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: any = yield dashboardServices.getAnnouncementsById(param['payload']);
        if (response?.statusCode === 200) {
            yield put (saveAnnouncementsById(response?.resultData))
            yield put(loaderAction(false));
        }
    }
    catch (err) {
    }
}

export function* deleteAnnouncementsSaga() {
    yield takeLatest(TYPES.DELETE_ANNOUNCEMENTS, deleteAnnouncementsWorker);
  }
  
  function* deleteAnnouncementsWorker(param: any) {
    try {
     yield put (announcementRefreshState(false));
      yield put(loaderAction(true));
      const response: any = yield dashboardServices.deleteAnnouncements(param['payload']);
      yield put (announcementRefreshState(true));
      yield put(loaderAction(false));
      NotificationManager.success(NOTIFICATION_MESSAGE.DELETE_ANNOUNCEMENTS);
    }
    catch (err) {
    }
  }

  export function* saveAnnouncementsListSaga() {
    yield takeLatest(TYPES.SAVE_ANNOUNCEMENTS_LIST, saveAnnouncementsListWorker);
  }
  
  function* saveAnnouncementsListWorker(param: any) {
    try {
      yield put (announcementRefreshState(false));
      yield put(loaderAction(true));
      const response: any = yield dashboardServices.saveAnnouncementsList(param['payload']);
      yield put (announcementRefreshState(true));
      yield put(loaderAction(false));
      NotificationManager.success(NOTIFICATION_MESSAGE.SAVE_ANNOUNCEMENTS_LIST);
  
    }
    catch (err) {
    }
  }

  export function* updateAnnouncementsListSaga() {
    yield takeLatest(TYPES.UPDATE_ANNOUNCEMENTS_LIST,updateAnnouncementsListWorker);
  }
  
  function* updateAnnouncementsListWorker(param: any) {
    try {
      yield put (announcementRefreshState(false));
      yield put(loaderAction(true));
      const response: any = yield dashboardServices.updateAnnouncementsList(param['payload']);
      yield put (announcementRefreshState(true));
      yield put(loaderAction(false));
      NotificationManager.success(NOTIFICATION_MESSAGE.UPDATE_ANNOUNCEMENTS_LIST);
  
    }
    catch (err) {
    }
  }

  export function* getDischargeReasonsSaga() {
    yield takeLatest(TYPES.GET_DISCHARGE_REASONS,getDischargeReasonsWorker);
  }
  
  function* getDischargeReasonsWorker(param: any) {
    try {
      yield put(loaderAction(true));
      const response: any = yield dashboardServices.getDischargeReasons(param['payload']);
      yield put (saveDischargeReasons(response.resultData));
      yield put(loaderAction(false));
    }
    catch (err) {
    }
  }

  
