import React, { useState } from "react";
import { Tooltip } from "@progress/kendo-react-tooltip";
 
const TruncatedText = ({ text }) => {
  const [expanded, setExpanded] = useState(false);
 
 
  const toggleExpanded = () => {
    setExpanded(!expanded);
  };
 
  return (
    <td className="cursor-default t" style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
      <Tooltip content={text} position="top">
        {expanded || text.length <= 50 ? (
          text
        ) : (
          <>
            {text.substr(0, 50)}
            ...
          </>
        )}
      </Tooltip>
      {text.length > 50 && (
        <span onClick={toggleExpanded} style={{ cursor: "pointer", color: "#021f54", marginLeft: 1, fontWeight: 800 }}>
          {expanded ? "Show Less" : "Show More"}
        </span>
      )}
    </td>
  );
};
 
export default TruncatedText;