import React from 'react';
import { Dialog } from "@progress/kendo-react-dialogs";
import { Checkbox } from '@progress/kendo-react-inputs';

const MultipleDocsLockDialog = (props: MultipleLockDialogProps) => {
    const {
        title,
        onClose,
        activeType,
        handleDelete,
        handleReactive,
        setDeleteAssociateSign,
        isDeleteAssociateSign,
       
        checkedDocuments,
    } = props;

    const onChange = (e) => {
        setDeleteAssociateSign(e.target.value)
    }

    return (
        <Dialog className='blue_theme' onClose={onClose} title={activeType === true ? `Unlock ${title}` : `Lock ${title}`}>
            <div className="mb-3">
                {activeType === true ? 'Are you sure you want to unlock and allow editing again. Continue?' : props.textForDialogBox}
                {checkedDocuments && checkedDocuments
                    .filter(doc => (doc.isLocked && !activeType) || (!doc.isLocked && activeType))
                    .map(doc => (
                        <ul className='mt-3 px-3'>
                        <li className="mt-3" key={doc.id}>
                            {doc.isLocked && !activeType ? (
                                <div>Document #{doc.id} is already <span style={{ color: '#FFC107' }}>locked</span></div>
                            ) : null}
                            {!doc.isLocked && activeType ? (
                                <div>Document #{doc.id} is already <span style={{ color: '#FFC107' }}>unlocked</span></div>
                            ) : null}
                        </li>
                        </ul>
                    ))}

                {
                    activeType === true &&
                    <p className="f-12 d-flex align-items-center mt-4 mb-2">
                        <Checkbox
                            name="isDeleteAssociate"
                            value={isDeleteAssociateSign}
                            onChange={onChange}
                            style={{ marginRight: "5px", marginTop: "0px" }}
                        />
                        Delete associate signatures?
                    </p>
                }
                <div className="row mt-4 text-end justify-content-end mx-1">

                    <div className=""> {activeType ? (
                        <button
                            className="submitButon px-4"
                            onClick={handleReactive}
                        >
                            Yes
                        </button>
                    ) : (
                        <button
                            className="submitButon px-4"
                            onClick={handleDelete}
                        >
                            Yes
                        </button>
                    )}
                    </div>
                    <div className="">
                        <button
                            className="cancelBtn px-4"
                            onClick={onClose}
                        >
                            No
                        </button>
                    </div>

                </div>
            </div>
        </Dialog>
    );
};

export default MultipleDocsLockDialog;

export interface MultipleLockDialogProps {
    title?,
    onClose?,
    activeType?,
    handleDelete?,
    handleReactive?,
    setDeleteAssociateSign?,
    isDeleteAssociateSign?,
    textForDialogBox?,
    checkedDocuments?
}
