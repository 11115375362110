
import { RadioGroup, Switch } from "@progress/kendo-react-inputs";
import moment from "moment";
import React, { useEffect, useState, useRef, ComponentState } from "react";
import DatePickerKendoRct from "src/control-components/date-picker/date-picker";
import calender from "../../assets/images/calendar-03.png";
import MultiSelectDropDown from "src/control-components/multi-select-drop-down/multi-select-drop-down";
import { filterBy } from "@progress/kendo-data-query";
import { useDispatch, useSelector } from "react-redux";
import { getSiteList, saveButtonPressed, saveExportButtonPressed, setClientReportFilters } from "src/redux/actions";
import { Tooltip } from "@progress/kendo-react-tooltip";
import SettingTemplateReport from "./settingTemplateReport";



function renderToItem(li, itemProps) {
    const itemChildren = (
        <span>
            <input
                type="checkbox"
                name={itemProps.dataItem}
                checked={itemProps.selected}
                onChange={(e) => itemProps.onClick(itemProps.index, e)}
            />
            &nbsp;{li.props.children}
        </span>
    );
    return React.cloneElement(li, li.props, itemChildren);
}

function ClientReportFilters({ selectedReport, canExport }: { selectedReport: number, canExport: boolean }) {
    const state: ComponentState = useSelector(states => {
        return states;
    });
    const [filter, setFilter] = useState<any>();
    const { sitesList } = state['DocumentReducer'];
    const [siteList, setSiteList] = useState([]);
    const [loading, setLoading] = useState(false)
    const [isDataLoadStart, setDataLoadStart] = useState(false);
    const [openDateFilter, setOpenDateFilter] = useState(false);
    const [openSiteFilter, setOpenSiteFilter] = useState(false);
    const [setting, setSetting] = useState(false);
    const [applyButtonClicked, setApplyButtonClicked] = useState(false);
    const [serviceStartDate, setServiceStartDate] = useState<any>();
    const [serviceEndDate, setServiceEndDate] = useState<any>();
    const [tempInitialDate, setTempInitialDate] = useState(serviceStartDate);
    const [tempPrevDate, setTempPrevDate] = useState(serviceEndDate);
    const [changedSiteList, setChangeSiteList] = useState<any[]>([]);
    const [selectedSites, setSelectedSites] = useState<any[]>([]);
    const [radioLabelFilter, setRadioLabelFilter] = useState("Last Seen Date");
    const [isActiveCheck, setIsActiveCheck] = useState(false);
    const [isFilterLoaded, setIsFilterLoaded] = useState(false);
    const dropDownRef = useRef<HTMLInputElement>(null);
    const [errors, setErrors] = useState<any>({
        serviceStartDate: "",
        serviceEndDate: "",
    });
    const [radioDateFilter, setRadioDateFilter] = useState("dateLastSeen");
    const refPayerClose = useRef(null);
    const refSiteClose = useRef<any>(null);
    const refSite = useRef<any>(null);
    const dateFilterTypes = [
        {
            label: "Last Seen Date",
            value: "dateLastSeen",
            condition: true,
        },
        {
            label: "Client Start Date",
            value: "dateStart",
            condition: true,
        },

        {
            label: "Date of Birth",
            value: "dob",
            condition: true,
        },
    ];
    const filteredDateFilterTypes = dateFilterTypes.filter(
        (option) => option.condition
    );

    function handleSettingTemplateView() {
        setSetting(!setting);
    }

    const handleHoldDate = (e) => {
        const name = e.target.name;
        let value = e.target.value;
        setErrors({
            serviceStartDate: "",
            serviceEndDate: "",
        });
        if (name === "serviceStartDate") {
            if (tempPrevDate && value <= tempPrevDate) {
                setTempInitialDate(value);
            } else if (!tempPrevDate) {
                setTempInitialDate(value);
            }
        } else {
            if (tempInitialDate && value >= tempInitialDate) {
                setTempPrevDate(value);
            } else if (!tempInitialDate) {
                setTempPrevDate(value);
            }
        }
    };

    const handleExportClick = () => {
        dispatch(saveExportButtonPressed(true))
    }

    const handleChangeDateFilterTypes = (e) => {
        const selectedValue = e.value;
        setRadioDateFilter(e.value);
        const selectedFilter = dateFilterTypes.find(
            (filter) => filter.value === selectedValue
        );
        setRadioLabelFilter(selectedFilter ? selectedFilter.label : "");
    };

    const handleApplyFilter = () => {
        if (tempInitialDate && tempPrevDate) {
            setServiceStartDate(tempInitialDate);
            setServiceEndDate(tempPrevDate);
            setTimeout(() => {
                setOpenDateFilter(false)
            }, 300)

            setErrors({});
        } else {
            setErrors({
                ...errors,
                serviceStartDate: "Please select a valid start and end Date.",
            });
        }
    };

    const handleSwitch = (e) => {
        var changeVal = e.target.value;
        setIsActiveCheck(changeVal);
    };

    const handleClearSite = () => {
        setChangeSiteList([])
        setSelectedSites([])
    }



    const handleGenerateReportClick = () => {
        const reportFilterParams = {
            dateRangeType: radioDateFilter,
            startDate: serviceStartDate ? moment(serviceStartDate).format("M/D/yyyy") : undefined
            , endDate: serviceEndDate ? moment(serviceEndDate).format("M/D/yyyy") : undefined,
            isActive: !isActiveCheck, sites: selectedSites.map((site) => site.id)
        }
        dispatch(setClientReportFilters(reportFilterParams))
        dispatch(saveButtonPressed(true))
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickListener);
        return () => {
            document.removeEventListener('mousedown', handleClickListener);
        };
    }, []);

    const handleClickListener = async (event) => {
        if (refSite.current && !refSite.current?.contains(event.target)) {
            setTimeout(() => {
                // setOpenSiteFilter(false)
            }, 200);
        }
    }

    useEffect(() => {
        if (!isDataLoadStart) {
            setLoading(true)
            callSiteListApiData()
            setDataLoadStart(true)
        }
        if (sitesList != null) {
            setLoading(false)
            setSiteList(sitesList);
        }
    }, [isDataLoadStart, sitesList])


    const dispatch = useDispatch()
    const callSiteListApiData = async () => {
        dispatch(getSiteList({}))
    }

    function clearAllFilter() {
        const reportFilterParams = {
            isActive: true, sites: []
        }
        setServiceStartDate(undefined)
        setServiceStartDate(undefined)
        setTempPrevDate(undefined)
        setTempInitialDate(undefined)
        setIsActiveCheck(false)
        setSelectedSites([])
        dispatch(setClientReportFilters(reportFilterParams))
        dispatch(saveButtonPressed(true))

    }


    useEffect(() => {
        if (isFilterLoaded) {
            handleGenerateReportClick()
        } else {
            setIsFilterLoaded(true)
        }
    }, [serviceStartDate, serviceEndDate, isActiveCheck, selectedSites, radioDateFilter])

    return (<>
        <div className="searchBoxShadowFilter mt-2 " >
            <div className="mx-2 row align-items-center">
                <div className="col-lg-9 col-sm-12 pl-0 blueTheme" >
                    <div className="content-search-filter  px-0 filter-drop-down">
                        <div className="major-filter align-items-center gapresponsive">
                            <div className="border-dashed-cus position-relative"
                                onClick={() => setOpenDateFilter(true)}
                                onBlur={() => {
                                    if (!applyButtonClicked) {
                                        setOpenDateFilter(false);
                                    }
                                }}
                            >
                                {serviceStartDate && serviceEndDate ? (
                                    <button className="btn  btn-size-cus pl-0 position-relative" type="button">
                                        <i
                                            ref={refPayerClose}
                                            onClick={() => {
                                                setServiceStartDate(undefined);
                                                setServiceEndDate(undefined);
                                                setTempPrevDate(undefined);
                                                setTempInitialDate(undefined);
                                                setRadioLabelFilter("Last Seen Date");
                                                setRadioDateFilter("dateLastSeen");

                                            }}
                                            className={"fa fa-times cross-icon mr-2 "}
                                        ></i>
                                        Date ({radioLabelFilter})
                                        <span className="border-spann">
                                            {moment(serviceStartDate).format("M/D/yyyy") +
                                                " - " +
                                                moment(serviceEndDate).format("M/D/yyyy")}
                                        </span>
                                    </button>
                                ) : (
                                    <button className="btn  btn-size-cus pl-0  position-relative" type="button">
                                        <i className={"fa fa-plus cross-icon "}></i>
                                        Select Date
                                    </button>
                                )}

                                {openDateFilter && (
                                    <div
                                        className="dropdown-service "
                                        onFocus={() => setOpenDateFilter(true)}
                                    >
                                        <div className="row staffButton">
                                            <div className="col-md-6 position-relative ">
                                                <DatePickerKendoRct
                                                    validityStyles={false}
                                                    value={tempInitialDate && new Date(tempInitialDate)}
                                                    onChange={handleHoldDate}
                                                    name="serviceStartDate"
                                                    label="From"
                                                    placeholder="M/D/YYYY"
                                                    max={tempPrevDate ? new Date(tempPrevDate) : new Date()}
                                                />
                                                <img
                                                    src={calender}
                                                    alt=""
                                                    className="leftIcon "
                                                    style={{ top: "30px", left: "19px" }}
                                                />
                                            </div>
                                            <div className="col-md-6 position-relative">
                                                <DatePickerKendoRct
                                                    validityStyles={false}
                                                    value={tempPrevDate && new Date(tempPrevDate)}
                                                    onChange={handleHoldDate}
                                                    max={new Date()}
                                                    name="serviceEndDate"
                                                    label="To"
                                                    placeholder="M/D/YYYY"
                                                    min={tempInitialDate && new Date(tempInitialDate)}
                                                />
                                                <img
                                                    src={calender}
                                                    alt=""
                                                    className="leftIcon "
                                                    style={{ top: "30px", left: "19px" }}
                                                />
                                            </div>
                                            {errors?.serviceStartDate && (
                                                <div className="col-md-12 align-items-center justify-content-center">
                                                    <div className="col-12 text-center">
                                                        <p
                                                            style={{
                                                                color: "#d61923",
                                                                fontSize: "12px",
                                                                fontStyle: "italic",
                                                            }}
                                                        >
                                                            {errors?.serviceStartDate}
                                                        </p>
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        <div
                                            className="row mt-2"

                                            onMouseDown={() => setApplyButtonClicked(true)}
                                            onMouseUp={() => setApplyButtonClicked(false)}
                                        >
                                            <div className="col-lg-8 col-md-8 col-sm-12 blue_theme">
                                                <RadioGroup
                                                    data={filteredDateFilterTypes}
                                                    value={radioDateFilter}
                                                    onChange={handleChangeDateFilterTypes}
                                                    layout={"horizontal"}
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-8 col-sm-12">
                                                <div className="text-center">
                                                    <button
                                                        onClick={handleApplyFilter}
                                                        type="button"
                                                        className="submitButon px-4 m-0"
                                                    >
                                                        Apply
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div
                                ref={refSite}
                                onClick={(event: any) => {
                                    if (!refSiteClose.current?.contains(event.target)) {
                                        setOpenSiteFilter(true)
                                    }
                                }
                                }
                                className="service-main dropdown mb-1 email-filter border-dashed-cus position-relative staffButton "
                            >
                                <button className="btn  btn-size-cus pl-0 position-relative" type="button">
                                    {selectedSites?.length > 0 ? (
                                        <i
                                            ref={refSiteClose}
                                            onClick={handleClearSite}
                                            className={"fa fa-times cross-icon mr-2 "}
                                        ></i>
                                    ) : (
                                        <i
                                            className={"fa fa-plus cross-icon "}
                                        ></i>
                                    )}     Site
                                    {selectedSites.length !== 0 && (
                                        <span className={selectedSites?.length > 0 ? "border-spann" : ''}>
                                            {selectedSites?.at(0)?.name} {(selectedSites?.length && selectedSites?.length > 1) ? '(+' + (selectedSites.length - 1) + ')' : ''}
                                        </span>
                                    )}
                                </button>

                                {openSiteFilter && (
                                    <div className="dropdown-service dropInputField service-dropdown z-5">
                                        <div className="row justify-content-end">
                                            <div ref={refSiteClose} className="col-lg-4 col-md-8 col-sm-12 ">
                                                <div className="text-end ">
                                                    <button onClick={(event: any) => {
                                                        setOpenSiteFilter(false)
                                                        setSelectedSites(changedSiteList)
                                                        //   handleChangeService('service', changedServiceList)
                                                    }} type="button" className="submitButon mb-0">
                                                        Apply
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col-md-12 py-2 " ref={dropDownRef}>
                                                <div className="">
                                                    <MultiSelectDropDown
                                                        data={filterBy(siteList, filter ? filter.siteList : [])}
                                                        loading={loading}
                                                        textField="name"
                                                        label="Site"
                                                        name="site"
                                                        ref={dropDownRef}
                                                        value={changedSiteList}
                                                        onChange={(event) => {
                                                            setChangeSiteList(event.target.value)
                                                            setOpenSiteFilter(true)
                                                        }}
                                                        autoClose={false}
                                                        dataItemKey={"id"}
                                                        itemRender={renderToItem}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="blue_theme">
                                <Switch
                                    onChange={handleSwitch}
                                    checked={isActiveCheck}
                                    onLabel={""}
                                    offLabel={""}
                                    className="switch-on m-0"
                                />
                                <span className="switch-title-text mx-md-2 mt-1 text-right resposniveMX">
                                    {" "}
                                    {isActiveCheck
                                        ? "Show Inactive Clients"
                                        : "Show Inactive Clients"}
                                </span>
                            </div>

                            <div className="d-flex">
                                <button
                                    className="submitButon f-14 d-flex align-items-center my-2"
                                    onClick={handleGenerateReportClick}
                                >
                                    Run Report
                                </button>

                            </div>



                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-12 d-flex justify-content-end align-items-center clear-add-filter">
                    <div className="mr-3">
                        <p className="mb-0" onClick={clearAllFilter}>
                            Clear Filter
                        </p>
                    </div>

                </div>
            </div>
        </div>
        <div className="mb-1 my-2 row ">
            <div className="col-md-12 d-flex justify-content-end align-items-center">
                {canExport && <p
                    className="filter-export d-flex align-items-center my-2 mr-4 cursor-pointer"
                    onClick={handleExportClick}
                >
                    Export
                </p>
                }
                <div className="pr-4 mr-2 " style={{ cursor: 'pointer', fontSize: '20px' }} onClick={handleSettingTemplateView}>
                    <Tooltip anchorElement="target" position="top">
                        <i className="cursor-pointer fa fa-cog" title="Adjust Columns" />
                    </Tooltip>
                </div>
            </div>
        </div>


        {setting && (
            <SettingTemplateReport
                onClose={handleSettingTemplateView}
                selectedReport={selectedReport}
            />
        )}
    </>)

}
export default ClientReportFilters