import { SAVE_CUSTOMER_PAYMENTS_METHODS, SAVE_CUSTOMER_SUBSCRIPTIONS, SAVE_PAYMENT_TOKEN, SAVE_STRIPE_CUSTOMER, SAVE_SUBSCRIPTIONS_INVOICES, SUBSCRIPTIONS_LOADING } from "../actions/types";

const initialState = {
  customerInvoices: [],
  customerPaymentMethods: [],
  customerSubscriptions: [],
  loadingObject: [''],
  stripeCustomer: {},
  paymentToken: ''
};

export const SubsCriptionManagerReducer = (state = initialState, action) => {
  const oldState = { ...state };
  const { type, payload } = action;
  switch (type) {

    case SAVE_SUBSCRIPTIONS_INVOICES:
      return {
        ...oldState,
        customerInvoices: payload,
      };

    case SAVE_CUSTOMER_SUBSCRIPTIONS:
      return {
        ...oldState,
        customerSubscriptions: payload,
      };

    case SAVE_CUSTOMER_PAYMENTS_METHODS:
      return {
        ...oldState,
        customerPaymentMethods: payload,
      };

    case SAVE_STRIPE_CUSTOMER:
      return {
        ...oldState,
        stripeCustomer: payload,
      };

    case SUBSCRIPTIONS_LOADING:
      if (payload && payload.length === 2) {
        if (payload[1] === 1 && payload[0]) {
          const appended = state.loadingObject
          appended.push(payload[0])
          return {
            ...oldState,
            loadingObject: appended,
          };
        } else {
          const eject = state.loadingObject.filter((item) => item !== payload[0])
          return {
            ...oldState,
            loadingObject: eject,
          };
        }
      }

    case SAVE_PAYMENT_TOKEN:
      return {
        ...oldState,
        paymentToken: payload,
      };
  }
  return oldState;
};
