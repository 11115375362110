import { takeLatest, put } from 'redux-saga/effects';
import {
  loaderAction,
  loadingIndicatorAction,
  roleModeAction,
  staffSettingsAction, savePermissionsAction, saveStaffPermissions, loginUser, saveAuthTransactions,
  saveUpdateAuthBatchEndDate
} from '../actions';
import * as TYPES from '../actions/types';
import DEVELOPMENT_CONFIG from "../../helper/config";
import moment from 'moment';
import { PermissionHelper } from '../../helper/permission-helper';
import { Encrption } from '../../app-modules/encrption';
import { UserAuthResponse } from '../../dataModels/authDataModels';
import { getUser } from '../../services/authService';
import { UserPermissions } from '../../dataModels/userPermissionModels';
import { SettingsResponse } from '../../dataModels/userSettingsModel';
import { NotificationManager } from "react-notifications";



export function* LoginSaga() {
  yield takeLatest(TYPES.LOGIN_ACTION, loginWorker);
}


function* loginWorker(param: any) {
  // const navigate = useNavigate();
  try {
    // yield put(loaderAction(true));
    yield put(loadingIndicatorAction(true));
    const response: UserAuthResponse = yield getUser.auth(param['payload']);
    // yield put(loaderAction(false));
    yield put(loadingIndicatorAction(false));
   

    yield put(loginUser(response.resultData));
  } catch (err) {
  }
}

export function* getRoleSaga() {
  yield takeLatest(TYPES.ROLE_MODE, getRoleModulesWorker);
}

function* getRoleModulesWorker(param: any) {
  let response: UserPermissions = yield getUser.getUserPermissions()
  let list = response?.resultData;
  if (list.length > 0) {
    let data = yield PermissionHelper(response?.resultData);
    yield put(savePermissionsAction(data));
  }
}


export function* getStaffSettingsSaga() {
  yield takeLatest(TYPES.STAFF_SETTINGS, getStaffSettingListWorker);
}


function* getStaffSettingListWorker(param: any) {
  let id = Encrption(param['payload']);

  let response: SettingsResponse = yield getUser.getStaffSettings(id);
  yield put(saveStaffPermissions(response?.resultData));
}

export function* getAuthViewTransSaga() {
  yield takeLatest(TYPES.GET_AUTH_TRANSACTIONS, getAuthViewTransWorker);
}

function* getAuthViewTransWorker(param: any) {
  yield put(loaderAction(true));
  let response: any = yield getUser.getAuthTransaction(param['payload']);
  yield put(loaderAction(false));
  yield put(saveAuthTransactions(response?.resultData));
}

export function* updateAuthBatchEndDateSaga() {
  yield takeLatest(TYPES.UPDATE_AUTH_BATCH_END_DATE, updateAuthBatchEndDateWorker);
}

function* updateAuthBatchEndDateWorker(param: any) {
  yield put(loaderAction(true));
  let response: any = yield getUser.updateAuthBatchEndDate(param['payload']);
  if(response) {
    yield put(saveUpdateAuthBatchEndDate(response.resultData))
    if(Object.keys(response.resultData).length <= 0) {
      NotificationManager.success('Success')
    }
  }
  yield put(loaderAction(false));
}