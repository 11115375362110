import { saveAs } from "@progress/kendo-file-saver";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProgressLoading from "src/control-components/loader/loader_with_text";
import './document-styles.css';
import DocumentTemplatePdfItem from "./pdf-doc-temp-item";
import { saveExportCount } from "src/redux/actions";
import pako from 'pako';
import { onShowFileTempFields } from "../file-template-utility";
import { customAxios } from "src/services/useExPressApi";
import { API_ENDPOINTS } from "src/services/api-endpoints";
import { renderErrors } from "src/helper/error-message-helper";
import ApiHelper from "../../../helper/api-helper";
import { NotificationManager } from "react-notifications";
import { deleteClientPrintedDocuments } from "src/redux/actions";

function DocumentTemplatePdf({ documentPrintData, onlyView, metaDataDocs,isClientFileDoc ,onClose}) {

  const _elementRef = useRef(null);
  const [isFieldSet, setFieldSet] = useState(false);
  const [docCalDone, setDocCalDone] = useState(false);
  const dispatch = useDispatch();
  const [isPrepared, setIsPrePared] = useState(false);
  const state = useSelector(states => {
    return states;
  });
  const { docPreparedCount, docExportCount } = state['DocumentReducer'];
  const [isFileTempFieldMapped, setIsFileTempFieldMapped] = useState(false);
  const [isDocumentRendered, setIsDocumentRendered] = useState(false);
  const [isDocumentFileComponentLoaded, setIsDocumentFileComponentLoaded] = useState(false);
  const [documentFileComponentLoadCount, setDocumentFileComponentLoadCount] = useState(0);
  const [errorMessage, setErrorMessage] = useState("")
  const [isDocCountCalculated, setIsDocCountCalculated] = useState(false)

  useEffect(() => {
    if (!isDocCountCalculated) {
      setIsDocCountCalculated(true)
      const nonFileTemplateDocs = documentPrintData.filter(doc => {
        return !doc.isHtmlFileTypeTemplate
      }).length
      setDocumentFileComponentLoadCount(nonFileTemplateDocs);
    }

  }, [documentPrintData,]);

  useEffect(() => {

    if (documentFileComponentLoadCount === documentPrintData.length) {
      setIsDocumentFileComponentLoaded(true)
    }
  }, [documentFileComponentLoadCount]);

  const exportElement = async (elements, metaDataDocs) => {
    if (elements.length > 0) {
      const formattedElements = elements.map((optionItem, index) => {
        const cDocId = optionItem.getAttribute('id');
        const styles = [...document.styleSheets]
          .map(styleSheet => {
            try {
              return [...styleSheet.cssRules]
                .map(rule => rule.cssText)
                .join('');
            } catch (e) {
            }
            return '';
          })
          .join('\n');
        return {
          id: cDocId,
          html: optionItem.outerHTML,
          title: "Client Document Print",
          author: "Notenetic Team",
          styles,
          metaData: metaDataDocs.find((item) => +item.documentId === +cDocId),
          index
        };
      });

      try {
        dispatch(saveExportCount(formattedElements.length))

        const url = API_ENDPOINTS.PUPPETEER_BASE_URL + '/generate-pdf/document'
        // const url = 'http://localhost:3001/generate-pdf/document'

        const body = JSON.stringify({ elements: formattedElements });
        const compressedBody = pako.gzip(body);

        let retry = 1;
        let maxRetry = 3;

        while (retry <= maxRetry) {
          const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/octet-stream'
            },
            body: compressedBody,
          });

          if (!response.ok) {
            if (retry == maxRetry) {
              setErrorMessage(`Error: ${response?.statusText || response?.status}`)
              break;
            }
          } else {
            const blob = await response.blob();
            saveAs(blob, 'Document.pdf');
            window.close();
            break;
          }
          retry++;
        }
      } catch (error) {
        setErrorMessage(`Error: ${error}`)
      }
    }
  };

   const clientFileExportElement = async (elements, metaDataDocs) => {
      if (elements.length > 0) {
        try {
          for (const optionItem of elements) {
            const cDocId = optionItem.getAttribute("id");
            const selectedMetaDoc = metaDataDocs?.find((item) => +item.documentId.toString() === +cDocId.toString());
            const { clientId } = selectedMetaDoc;
            const styles = Array.from(document.styleSheets)
              .map((styleSheet) => {
                try {
                  return Array.from(styleSheet.cssRules)
                    .map((rule) => rule.cssText)
                    .join("");
                } catch (e) {
                  console.warn("Could not access cssRules for a stylesheet:", e);
                  return "";
                }
              })
              .join("\n");
  
            const formattedElement = {
              id: cDocId,
              html: optionItem.outerHTML,
              title: "Document Print",
              author: "Team",
              styles,
              metaData: metaDataDocs?.find((item) => +item.documentId.toString() === +cDocId.toString())
            };
            const body = JSON.stringify({ elements: [formattedElement] });
            const file = pako.gzip(body);
            const fileName = `document.zip`;
            await saveDocument(file, cDocId, clientId, fileName);
           
          }
          NotificationManager.success("File has been saved successfully in the client file manager.");
          onClose(false);
        } catch (error) {
          renderErrors(`Error: ${error}`);
        }
      }
    };

    const saveDocument = async (file,documentId,clientId,fileName) => {
      const data = {clientId,fileName,documentId,};
      try {
        const result = await ApiHelper.multipartPostRequest(
          API_ENDPOINTS.GET_CLIENT_S3_SIGNED_PRINTED_DOC_URL,
          data
        );
        await uploadFileToS3(result, file);
      } catch (error) {
        renderErrors(error.message);
      }
    };
  
    const uploadFileToS3 = async (presignedPostData, file) => {
      const result = await fetch(presignedPostData.resultData.presignedUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/octet-stream",
        },
        body: file,
      });
      if (result.ok) {
         const param = {
              id: presignedPostData?.resultData?.printedDocumentId,
              isActive: true,
            };
            dispatch(deleteClientPrintedDocuments(param))
      } else {
        renderErrors("Error uploading file to S3");
      }
    };
  

  React.useEffect(() => {
    // onFieldsSet();
    setFieldSet(true)
  }, []);


  React.useEffect(() => {

    if (_elementRef.current && !docCalDone) {
      handleSelect().then(() => { });
      setDocCalDone(true)
    }
  }, [isDocumentRendered, isFieldSet]);

  const handleSelect = async () => {
    if (!onlyView) {
      setIsPrePared(true)
      isClientFileDoc ? await clientFileExportElement(_element1, metaDataDocs):  await exportElement(_element1, metaDataDocs);
    }
  }

  let _element1 = [];

  useEffect(() => {
    if (isDocumentFileComponentLoaded) {
      mapDocumentFileFields();
    }
  }, [documentPrintData, isDocumentFileComponentLoaded])

  const mapDocumentFileFields = () => {

    if (!isFileTempFieldMapped && documentPrintData && documentPrintData.length > 0) {
      documentPrintData.map((currentDocumentData, index) => {

        onShowFileTempFields(currentDocumentData.fieldsMapping, currentDocumentData.documentId, true, currentDocumentData.htmlFileName, index);
        if (index === (documentPrintData.length - 1)) {
          setIsFileTempFieldMapped(true)

        }
      })
    } else {
      setIsFileTempFieldMapped(true)
    }
  }

  function onComponentLoaded() {
    setDocumentFileComponentLoadCount(x => x + 1);
  }

  const docComponent = () => {
    return (<div>
      {
        documentPrintData.map((currentDocumentData) => (
          <div
            key={currentDocumentData.documentId}
            id={currentDocumentData.documentId}
            className="k-pdf-export"
            ref={(div) => {
              if (!docCalDone && div != null) {
                _element1.push(div);
              }
            }}>
            <DocumentTemplatePdfItem
              onComponentLoaded={onComponentLoaded}
              staffInfo={currentDocumentData.staffInfo}
              documentDetail={currentDocumentData.documentDetail}
              diagnosisList={currentDocumentData.diagnosisList}
              docSignature={currentDocumentData.docSignature}
              docClientSignature={currentDocumentData.docClientSignature}
              isHtmlFileTypeTemplate={currentDocumentData.isHtmlFileTypeTemplate}
              htmlFileName={currentDocumentData.htmlFileName}
              template={currentDocumentData.template}
              fieldsMapping={currentDocumentData.fieldsMapping}
              documentId={currentDocumentData.documentId}
              docTreatmentPlans={currentDocumentData.docTreatmentPlans}
              historyList={currentDocumentData.historyList}
              documentCommonControls={currentDocumentData.documentCommonControls}
              metaData={metaDataDocs.find((i) => i.documentId === currentDocumentData.documentId)}
              docHasControlls={currentDocumentData.docHasControlls}
              mileagePayRate={currentDocumentData?.mileagePayRate}
            ></DocumentTemplatePdfItem>
          </div>
        ))
      }
    </div>)
  }

  return (
    errorMessage
      ? <ProgressLoading message={'Something went wrong, please try again later!' + errorMessage} />
      : (
        (!isFileTempFieldMapped)
          ? <div>
            {docComponent()}
            <ProgressLoading message={'Please wait while we are collecting information for all selected documents.'} />
          </div> :
          <div ref={(div) => {
            _elementRef.current = div;
            setIsDocumentRendered(true);
          }}>
            {docComponent()}
            {!onlyView && <ProgressLoading message={isPrepared ?
              `Exporting documents, It may take a while...`
              : `Prepared documents (${docPreparedCount}/${documentPrintData.length})`}>
            </ProgressLoading>}
          </div>
      )
  )
}

export default DocumentTemplatePdf;
