import Chip from '@mui/material/Chip';
import { groupBy } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import {
  setExpandedState,
  setGroupIds,
  useTableKeyboardNavigation,
} from "@progress/kendo-react-data-tools";
import {
  GRID_COL_INDEX_ATTRIBUTE,
  Grid,
  GridColumn,
} from "@progress/kendo-react-grid";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Tooltip } from "@progress/kendo-react-tooltip";
import moment from "moment";
import React, { ComponentState, useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { NumericFormat } from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import DeleteDialogModal from "../../control-components/custom-delete-dialog-box/delete-dialog";
import useModelScroll from "../../cutomHooks/model-dialouge-scroll";
import APP_ROUTES from "../../helper/app-routes";
import { permissionEnum } from "../../helper/permission-helper";
import {
  SELECTED_CLIENT_ID,
  SELECTED_STAFF_ID
} from "../../redux/actions/types";
import { showError } from "../../util/utility";
import { convertServerDocumentToLocal } from "./document-utility";
import DocumentSearchView from "./list-document-search";
import StaffServiceReport from "./staff-service-report";
import { ApproveOrDisapproveDocuments, LockUnlockDocuments, deleteDocument, getBillingByPayerMetrics, getBillingStatusMetrics, getClinetMostDocument, getClinicLogo, getDocMetricsByStatus, getDocMetricsByTemplate, getDocumentList, getDraftCount, getStaffMostDocument, saveApproveDisapproveDocuments, saveDocLoadingState, saveDocumentPageSize, saveDocumentSearchFilter, untrashDocument, getDocMetricsByServiceCode } from "../../redux/actions";
import { orderBy } from "@progress/kendo-data-query";
import { getter } from "@progress/kendo-react-common";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import MultipleDocsLockDialog from "src/control-components/custom-lock-unlock-dialouge-box/multiple-docs-lock-dialog";
import { TableRowsLoader } from "src/control-components/custom-skelton";
import CustomSplitButton from "src/control-components/split-button";
import { AdvanceSearchObject, DocumentListRequestObj, DocumentObj, DropDownObjectWithNumber, DropDownObjectWithString, MetaData } from "src/dataModels/docListModel";
import { selectBatchId } from "src/redux/actions";
import { API_ENDPOINTS } from "src/services/api-endpoints";
import { docs } from "src/services/documentService";
import { customAxios } from "src/services/useExPressApi";
import { userIsSuperAdmin } from "../../helper/permission-helper";
import {
  calcOffMins
} from "../../util/utility";
import AuthModel from "../client/client-profile/documents/auth-model";
import DocumentExportToExcel from "./ExportToExcel/document-export-to-excel";
import DocumentActionModel from "./document-action/doc-action";
import DocumentPayrollModel from "./document-action/doc-payrolls";
import DocumentListChart from "./document-list-chart";
import { mapDocumentTemplate } from "./template/document-template-utility";
import { Dialog } from '@progress/kendo-react-dialogs';
import TextAreaKendoRct from 'src/control-components/kendo-text-area/kendo-text-area';

import trashIcon from "../../assets/images/trash2.png";
import ViewAttachmentDialog from './ViewAttachmentDialog';
import TruncatedText from './truncate-cell';

const initialSort = [
  {
    field: 'serviceDateObj',
    dir: 'desc',
  },
];

const dateFilterTypes = [
  {
    label: "DOS",
    value: "DOS",
  },
  {
    label: "Date Submitted",
    value: "Submitted",
  },
  {
    label: "Payroll Date",
    value: "payroll",
  },
  {
    label: "Last Billed",
    value: "lastbilled",
  },
];

const staffFilterTypes = [
  {
    label: "Author",
    value: "Author",
    condition: true
  },
  {
    label: "Supervisor",
    value: "Supervisor",
    condition: true
  }
];

export const APPROVE_DOCUMENTS_OPERATION = 0;
export const LOCK_DOCUMENTS_OPERATION = 1;
export const UNLOCK_DOCUMENTS_OPERATION = 2;

const initialGroup = [];
const processWithGroups = (data, group) => {
  const newDataState = groupBy(data, group);
  setGroupIds({
    data: newDataState,
    group: group,
  });
  return newDataState;
};
const DATA_ITEM_KEY = "id";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);
const ListDocuments = () => {
  const state: ComponentState = useSelector(states => {
    return states;
  });
  const { draftCount, documentOperationResponse, documentResponse, documentSearchObject, documentPageSize, approveDisapproveResponse } = state['DocumentReducer'];
  const { loadingStateDocList } = state['LoaderReducer'];
  const rolePermisson = useSelector((state: ComponentState) => state.getRolePermission);
  const dispatch = useDispatch();
  const [documentList, setDocumentList] = useState([]);
  const [skip, setSkip] = useState(0);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = useState(documentPageSize ? documentPageSize : 25);
  const [openDateFilter, setOpenDateFilter] = useState(false);
  const [isAllSelected, setAllSelected] = useState(false);
  const [metaData, setMetaData] = React.useState<MetaData>();
  const globalSite = state.selectedAppSite;
  const [itemsToShow, setShowMore] = useState(3);
  const [group, setGroup] = React.useState(initialGroup);
  const [resultState, setResultState] = React.useState(processWithGroups(documentList, initialGroup));
  const [collapsedState, setCollapsedState] = React.useState([]);
  const [isICDData, setICDData] = useState([]);
  const [confirm, setConfirm] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [isActiveCheck, setIsActiveCheck] = useState(false);
  const [checkedDocuments, setCheckedDocuments] = React.useState<DocumentObj[]>([]);
  const resetCheckedDocuments = () => {
    setCheckedDocuments([]);
  };
  const [isOpenDocumentPayrollModel, setIsOpenDocumentPayrollModel] = useState<boolean>
    (false)
  const [listInitialLoad, setListInitialLoad] = React.useState(false);
  const [IsOpenAuthModel, setIsOpenAuthModel] = useState(false)
  const [selectAuthId, setSelectAuthId] = useState()
  const [loadingDocBatchPrint, setLoadingDocBatchPrint] = useState(false);
  const [modelScroll, setScroll] = useModelScroll();
  const [sort, setSort] = useState<any>(initialSort);
  const [selectedState, setSelectedState] = React.useState({});
  const [isOpenLock, setOpenLock] = useState(false);
  const [isLockDocument, setLockDocument] = useState(false);
  const [isDeleteAssociateSign, setDeleteAssociateSign] = useState(false);
  const docFilter = state.getDocumentFilter;
  const [showFilter, setShowFilter] = React.useState(false);
  const isBillingManager = state.getRolePermission.isBillingManager
  const isHumanResourcesManager = state.getRolePermission.isHumanResourcesManager
  const staffLoginInfo = state.getStaffReducer;
  const [selected, setSelected] = React.useState(0);
  const [openAttachmentDialog, setOpenAttachmentDialog] = useState(false);
  const initialExpanded = isICDData.reduce(
    (acc, cur: any) => ({ ...acc, [cur.id]: true }),
    {}
  );
  const [printItemCount, setPrintItemCount] = useState(0);
  const [AllActionItem, setAllActionItem] = useState<any>([]);
  const [showBillingCommentsDialogBox, setShowBillingCommentsDialogBox] = useState(false);
  const [billingComment, setBillingComment] = useState<any>('')

  useEffect(() => {
    if (userIsSuperAdmin(staffLoginInfo?.roleId)) {
      setAllActionItem(
        [
          { id: 1, name: "Update Billing Status" },
          { id: 2, name: "Set Payroll Status" },
          { id: 3, name: "Lock Documents" },
          { id: 4, name: "Unlock Documents" },
          { id: 5, name: "Approve Documents" },
          { id: 6, name: "Add Billing Comment" }
        ]
      )
    }
    else {
      if (isBillingManager) {
        setAllActionItem(oldArray => [...oldArray, { id: 6, name: "Add Billing Comment" }]);
      }
      if (isBillingManager || rolePermisson.canModifyBillingStatus)
        setAllActionItem(oldArray => [...oldArray, { id: 1, name: "Update Billing Status" }]);
      if (isHumanResourcesManager)
        setAllActionItem(oldArray => [...oldArray, { id: 2, name: "Set Payroll Status" }]);
      if (userAccessPermission[permissionEnum.CAN_LOCK_UNLOCK_OTHER_STAFF_DOC]) {
        setAllActionItem(oldArray => [...oldArray, { id: 3, name: "Lock Documents" }]);
        setAllActionItem(oldArray => [...oldArray, { id: 4, name: "Unlock Documents" }]);
      }
      if (staffLoginInfo?.canReviewDocuments)
        setAllActionItem(oldArray => [...oldArray, { id: 5, name: "Approve Documents" }]);
    }
  }, [staffLoginInfo?.roleId]);


  let currentDate = new Date();
  let pastDate = new Date();
  pastDate.setDate(pastDate.getDate() - 6);

  const [postBodyObject, setPostBodyObject] = React.useState<DocumentListRequestObj>();
  const [expandedModule, setExpandedModule] = React.useState({ ...initialExpanded });
  const handleChangeDateFilterTypes = (e) => {
    const selectedValue = e.value;
    setRadioDateFilter(e.value);
    const selectedFilter = dateFilterTypes.find(filter => filter.value === selectedValue);
    setRadioLabelFilter(selectedFilter ? selectedFilter.label : "")
  };
  const handleChangeStaffFilterTypes = (e) => {
    const selectedValue = e.value;
    setRadioStaffFilter(e.value);
    const selectedFilter = staffFilterTypes.find(filter => filter.value === selectedValue);
    setRadioStaffLabelFilter(selectedFilter ? selectedFilter.label : "")
  };
  const userAccessPermission = useSelector(
    (state: ComponentState) => state.userAccessPermission
  );
  const [canSeeOtherStaffDoc, setCanSeeOtherStaffDoc] = React.useState(
    userAccessPermission[permissionEnum.CAN_SEE_OTHER_STAFF_DOC] ||
    userAccessPermission[permissionEnum.CAN_EDIT_OTHER_STAFF_DOC]
  );
  const clearAdvSearchObj: AdvanceSearchObject = {
    documentId: null,
    client: [],
    serviceStartDate: pastDate, // "2022-08-08T16:00:17.362Z",
    serviceEndDate: currentDate, // "2022-08-08T16:00:17.362Z",
    staff: canSeeOtherStaffDoc ? [] : [{ id: staffLoginInfo.id, name: staffLoginInfo.lastName + ', ' + staffLoginInfo.firstName }],
    template: [],
    documentStatus: null,
    billStatus: [],
    showTrashNote: false,
    lockedValue: null,
    site: null,
    payer: [],
    signatures: null,
    flag: null,
    clientStatus: null,
    radioLabelFilter: null,
    radioStaffLabelFilter: null,
    denialReason: [],
    docTempType: [],
    hasAttachment: null,
    clientAge: null,
    billingBatchId: null,
    refName: null
  };
  const [advSearchFields, setAdvSearchFields] = useState(clearAdvSearchObj);
  const [docFilterHandled, setDocFilterHandled] = useState(false);
  const [displaySearchResult, setDisplaySearchResult] = useState(false);
  const [fetchState, setFetchState] = useState({ advSearchActive: false });
  const clinicId = state.loggedIn.clinicId;
  const [tempInitalDate, setTempInitialDate] = useState<Date>(advSearchFields?.serviceStartDate);
  const [tempPrevDate, setTempPrevDate] = useState<Date>(advSearchFields?.serviceEndDate);
  const [radioStaffFilter, setRadioStaffFilter] = useState("Author");
  const [radioStaffLabelFilter, setRadioStaffLabelFilter] = useState("Author");
  const [radioDateFilter, setRadioDateFilter] = useState("DOS");
  const [radioLabelFilter, setRadioLabelFilter] = useState("DOS");
  const [filterReadyToApply, setFilterReadyToApply] = useState(false)
  const [isOpenDocumentActionModel, setIsOpenDocumentActionModel] = useState<boolean>(false)
  const [documentActionUsed, setDocumentActionUsed] = useState(false);
  const [docId, setDocId] = useState()
  const [docClientName, setDocClientName] = useState()
  const [docDos, setDocDos] = useState('')

  // Variables
  const navigate = useNavigate();
  const onExpandChange = React.useCallback(
    (event) => {
      const item = event.dataItem;
      if (item.groupId) {
        const newCollapsedIds: any = !event.value
          ? [...collapsedState, item.groupId]
          : collapsedState.filter((groupId) => groupId !== item.groupId);
        setCollapsedState(newCollapsedIds);
      }
    },
    [collapsedState]
  );
  const newData = setExpandedState({
    data: resultState,
    collapsedIds: collapsedState,
  });

  /* ============================= useEffect functions ============================= */

  useEffect(() => {
    if (docFilterHandled) {
      setFilterReadyToApply(false)
      _fetchDocuments(null, null, false);
      fetchDraftCount();
      dispatch(getClinicLogo({ 'clinicId': clinicId, 'isBase64': true }));
    }
  }, [docFilterHandled, globalSite]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (docFilter) {
      setIsActiveCheck(docFilter?.showTrashNote ? docFilter?.showTrashNote : false);
      setAdvSearchFields({
        ...advSearchFields,
        client: docFilter?.client ? docFilter?.client : [],
        staff: docFilter?.staff ? docFilter?.staff : [],
        payer: docFilter?.payer ? docFilter?.payer : [],
        template: docFilter?.template ? docFilter?.template : [],
        docTempType: docFilter?.docTempType ? docFilter?.docTempType : [],
        site: docFilter?.site ? docFilter?.site : 0,
        clientAge: docFilter?.clientAge ? docFilter?.clientAge : null,
        documentId: docFilter?.documentId ? docFilter?.documentId : "",
        service: docFilter?.service ? docFilter?.service : [],
        documentStatus: docFilter?.documentStatus ? docFilter?.documentStatus : 0,
        billStatus: docFilter?.billStatus ? docFilter?.billStatus : [],
        lockedValue: docFilter?.lockedValue ? docFilter?.lockedValue : 0,
        isPayrollPaid: docFilter?.isPayrollPaid && docFilter?.isPayrollPaid,
        showTrashNote: docFilter?.showTrashNote ? docFilter?.showTrashNote : false,
        serviceStartDate: docFilter?.serviceStartDate
          ? docFilter?.serviceStartDate
          : pastDate,
        serviceEndDate: docFilter?.serviceEndDate ? docFilter?.serviceEndDate : currentDate,
        signatures: docFilter?.signatures,
        flag: docFilter?.flag,
        clientStatus: docFilter?.clientStatus,
        radioDateFilter: docFilter?.radioDateFilter,
        radioStaffFilter: docFilter?.radioStaffFilter,
        denialReason: docFilter?.denialReason ? docFilter?.denialReason : [],
        hasAttachment:docFilter?.hasAttachment,
        billingBatchId: docFilter?.billingBatchId ? docFilter?.billingBatchId : null,
        refName: docFilter?.refName ? docFilter?.refName : null,
      });

      setTempInitialDate(docFilter?.serviceStartDate
        ? docFilter?.serviceStartDate
        : pastDate)
      setRadioDateFilter(docFilter?.radioDateFilter ? docFilter?.radioDateFilter : "DOS")
      setRadioLabelFilter(docFilter?.radioLabelFilter ? docFilter?.radioLabelFilter : "DOS")
      setRadioStaffFilter(docFilter?.radioStaffFilter ? docFilter?.radioStaffFilter : "Author")
      setRadioStaffLabelFilter(docFilter?.radioStaffLabelFilter ? docFilter?.radioStaffLabelFilter : "Author")
    }
    setTempPrevDate(docFilter?.serviceEndDate ? docFilter?.serviceEndDate : currentDate)

    setDocFilterHandled(true);
  }, [docFilter]);

  const handleCloseBillingCommentDialogBox = () => {
    setShowBillingCommentsDialogBox(false);
  }
  const handleAddBillingComment = async () => {
    const body = {
      billingComment: billingComment,
      documentIds: checkedDocuments.map(doc => doc.documentId)
    };

    try {
      await customAxios.post(`${API_ENDPOINTS.ADD_BILLING_COMMENT}`, body);
      NotificationManager.success('Billing comment added successfully to the selected documents');
      _fetchDocuments(null, null, false);
      setShowBillingCommentsDialogBox(false);
      setBillingComment("");
    } catch (err) {
      console.error('Error adding billing comment:', err);
    }
  };

  const handleSetDocumentPrint = async () => {
    const body = checkedDocuments.map(doc => doc.documentId);
    try {
      await customAxios.post(`${API_ENDPOINTS.SET_DOCUMENT_PRINT}`, body);
    } catch (err) {
      console.error('Error Set Document Print:', err);
    }
  };

  const setDocumentListUsingResponse = async (responseData: any) => {
    if (responseData.resultData) {
      setFilterReadyToApply(true)
      let list = await responseData.resultData.map(convertServerDocumentToLocal);
      setDocumentList(list);
      const newDataState = processWithGroups(list, group);
      setResultState(newDataState);
      dispatch(saveDocLoadingState(false))
    }
  }

  useEffect(() => {
    if (documentResponse !== undefined && documentResponse.statusCode === 200) {
      setListInitialLoad(true)
      setMetaData(documentResponse?.metaData)
      setDocumentListUsingResponse(documentResponse)
    }
  }, [documentResponse]);

  useEffect(() => {
    if (approveDisapproveResponse === APPROVE_DOCUMENTS_OPERATION && documentActionUsed) {
      NotificationManager.success("Documents approved successfully")
      _fetchDocuments(null, null, false);
      setDocumentActionUsed(false)
    }
    else if (approveDisapproveResponse === LOCK_DOCUMENTS_OPERATION && documentActionUsed) {
      NotificationManager.success("Documents locked successfully")
      _fetchDocuments(null, null, false);
      const updatedDocuments = checkedDocuments.map(doc => ({ ...doc, isLocked: true }));
      setCheckedDocuments(updatedDocuments);
      setDocumentActionUsed(false)
    }
    else if (approveDisapproveResponse === UNLOCK_DOCUMENTS_OPERATION && documentActionUsed) {
      NotificationManager.success("Documents unlocked successfully")
      _fetchDocuments(null, null, false);
      const updatedDocuments = checkedDocuments.map(doc => ({ ...doc, isLocked: false }));
      setCheckedDocuments(updatedDocuments);
      setDocumentActionUsed(false)
    }
    dispatch(saveApproveDisapproveDocuments(-1));
  }, [approveDisapproveResponse])

  useEffect(() => {
    if (documentOperationResponse !== undefined && documentOperationResponse.statusCode !== undefined) {
      if (documentOperationResponse.type === 'trash') {
        NotificationManager.success("Document deleted successfully");
      } else {
        NotificationManager.success("Document restore successfully");
        setConfirm(false);
        setScroll('false')
      }
      _fetchDocuments(null, null, false);

    }
  }, [documentOperationResponse]);

  useEffect(() => {
    if (documentPageSize) {
      setPageSize(documentPageSize)
    }
  }, [documentPageSize, advSearchFields, docFilter])

  /* ============================= Private functions ============================= */

  const handleSelect = (e) => {
    setSelected(e.selected);
  };

  function fetchDraftCount() {
    // if (globalSite.siteId !== undefined) {
    //dispatch(getClinicLogo({ 'clinicId': clinicId, 'isBase64': true }));
    dispatch(getDraftCount(globalSite.siteId))
    // }
  }

  useEffect(() => {
    if ((documentSearchObject === undefined || documentSearchObject == null) && !docFilter) {
      setAdvSearchFields(clearAdvSearchObj)
    }
    if (filterReadyToApply) {
      setCheckedDocuments([])
      setAllSelected(false)
      _fetchDocuments(null, null, false);
    }
  }, [documentSearchObject, selected]);

  useEffect(() => {
    if (docFilterHandled) {
      _fetchDocuments(null, null, false)
    }
  }, [sort])

  function _fetchDocuments(take, finalValue, changeVal) {
    setOpenDateFilter(false);
    let clientArry: string[] = [];
    let staffArry: number[] = [];
    let serviceArry: number[] = [];
    let templateArry: number[] = [];
    let payerArray: number[] = [];
    let billStatusArry: number[] = [];
    let denialReasonArry: string[] =[];

    if (advSearchFields?.client) {
      advSearchFields?.client?.map((objType: DropDownObjectWithString) => clientArry.push(objType?.id));
    } else {
      docFilter?.client?.map((objType: DropDownObjectWithString) => clientArry.push(objType.id));
    }
    if (advSearchFields?.staff) {
      advSearchFields?.staff?.map((objType: DropDownObjectWithNumber) => staffArry.push(objType.id));
    } else {
      docFilter?.staff?.map((objType: DropDownObjectWithNumber) => staffArry.push(objType.id));
    }
    if (advSearchFields?.service) {
      advSearchFields?.service?.map((objType: DropDownObjectWithNumber) => serviceArry.push(objType.id));
    } else {
      docFilter?.service?.map((objType: DropDownObjectWithNumber) => serviceArry.push(objType.id));
    }
    if (advSearchFields?.template) {
      advSearchFields?.template?.map((objType: DropDownObjectWithNumber) => templateArry.push(objType.id));
    } else {
      docFilter?.template?.map((objType: DropDownObjectWithNumber) => templateArry.push(objType.id));
    }
    if (advSearchFields?.payer) {
      advSearchFields?.payer?.map((objType: DropDownObjectWithNumber) => payerArray.push(objType.id));
    } else {
      docFilter?.payer?.map((objType: DropDownObjectWithNumber) => payerArray.push(objType.id));
    }
    if(advSearchFields?.billStatus) {
      advSearchFields?.billStatus?.map((objType: DropDownObjectWithNumber) => billStatusArry.push(objType.id));
    } else {
      docFilter?.billStatus?.map((objType: DropDownObjectWithNumber) => billStatusArry.push(objType.id));
    }
    if (advSearchFields?.denialReason) {
      advSearchFields?.denialReason?.map((objType: any) => denialReasonArry.push(objType?.code));
    } else {
      docFilter?.denialReason?.map((objType: any) => denialReasonArry.push(objType.code));
    }
    
    const body: DocumentListRequestObj = {
      showTrashNote: isActiveCheck,
      clientId: clientArry,
      documentId: advSearchFields?.documentId ? advSearchFields?.documentId : 0,
      isPayrollPaid: advSearchFields?.isPayrollPaid?.id ? advSearchFields.isPayrollPaid.id : null,
      serviceId: serviceArry,
      siteId: advSearchFields?.site?.id,
      isAdult: advSearchFields?.clientAge ? (advSearchFields?.clientAge?.id == 2 ? true : false ) : null,
      startDate: moment(advSearchFields?.serviceStartDate).format("YYYY/MM/DD"),
      endDate: moment(advSearchFields?.serviceEndDate).format("YYYY/MM/DD"),
      pageNumber: finalValue ? finalValue : 1,
      pageSize: take ? take : documentPageSize ? documentPageSize : take == null ? pageSize : take,
      sortDirection: sort[0]?.dir ? sort[0]?.dir : "asc",
      sortBy: sort[0]?.field ? sort[0]?.field : "serviceDateObj",
      staffId: staffArry,
      documentTemplateIds: templateArry,
      docStatus: advSearchFields?.documentStatus?.id,
      billStatusIds: billStatusArry,
      isLocked: advSearchFields?.lockedValue ? advSearchFields?.lockedValue?.id : -1,
      dateRangeType: radioDateFilter,
      staffType: radioStaffFilter,
      offsetMins: calcOffMins(),
      payerIds: payerArray,
      isSignedByClient: advSearchFields?.signatures?.value === 1 ? true : false,
      isSignedByReviewer: advSearchFields?.signatures?.value === 2 ? true : false,
      isSignedByAuthor: advSearchFields?.signatures?.value === 3 ? true : false,
      isMultiSigned: advSearchFields?.signatures?.value === 4 ? true : false,
      isNotAuthorSigned: advSearchFields?.signatures?.value === 5 ? true : false,
      flagId: advSearchFields?.flag?.id,
      clientStatusId: advSearchFields?.clientStatus?.id,
      denialReasons:  denialReasonArry,
      documentTypeIds: [],
      hasAttachment: advSearchFields?.hasAttachment?.id ?? null,
      batchId: advSearchFields?.billingBatchId ? advSearchFields?.billingBatchId : 0,
      clientReferralSourceId: advSearchFields?.refName?.id ? advSearchFields?.refName?.id : 0
    };

    if (advSearchFields?.docTempType && advSearchFields?.docTempType.length > 0) {
      body.documentTypeIds = advSearchFields?.docTempType.map((tempType) => tempType.id)
    }
    setPostBodyObject(body);
    if (fetchState.advSearchActive) {
      if (advSearchFields?.documentId ?? 0 > 0) {
        body.documentId = advSearchFields.documentId?.toString() ?? null;
      }
      if (advSearchFields?.client) {
        body.clientId = clientArry;
      }
      if (advSearchFields?.service) {
        body.serviceId = serviceArry;
      }
      if (advSearchFields?.site) {
        body.siteId = advSearchFields.site.id;
      }
      if (advSearchFields?.clientAge) {
        if(advSearchFields?.clientAge?.id == 2) {
          body.isAdult = true
        }
        else if(advSearchFields?.clientAge?.id == 1) {
          body.isAdult = false
        }
      }
      else {
        body.isAdult = null;
      }
      if (advSearchFields?.serviceStartDate) {
        body.startDate = advSearchFields?.serviceStartDate;
      }
      if (advSearchFields?.serviceEndDate) {
        body.endDate = advSearchFields?.serviceEndDate;
      }
    }
    if (selected === 0) {
      dispatch(getDocumentList(body))
    }
    if (advSearchFields?.docTempType && advSearchFields?.docTempType.length > 0) {
      body.documentTypeIds = advSearchFields?.docTempType.map((tempType) => tempType.id)
    }

    if (selected === 1) {
      dispatch(getStaffMostDocument(body))
      dispatch(getClinetMostDocument(body))
      dispatch(getBillingStatusMetrics(body))
      dispatch(getBillingByPayerMetrics(body))
      dispatch(getDocMetricsByTemplate(body))
      dispatch(getDocMetricsByStatus(body))
      dispatch(getDocMetricsByServiceCode(body))
    }
  }

  /* ============================= Event functions ============================= */

  const handleConfirm = (id) => {
    setConfirm(true);
    setDeleteId(id);
    setScroll('true')
  };


  const hideConfirmPopup = () => {
    setConfirm(false);
    setDeleteId('');
    setScroll('false')
  };

  const handleDelete = () => {
    hideConfirmPopup();
    if (isActiveCheck === true) {
      dispatch(untrashDocument(deleteId))
    } else {
      dispatch(deleteDocument(deleteId))
    }
  };

  function pageChange(event) {
    let skip = event.page.skip;
    let take = event.page.take;
    setSkip(skip);
    setPageSize(take);
    let newValue = skip / take;
    let finalValue = newValue + 1;
    setPage(finalValue);
    _fetchDocuments(take, finalValue, isActiveCheck);
    dispatch(saveDocumentPageSize(take))
  }

  function handleDocumentView(event) {
    navigate(APP_ROUTES.DOCUMENT_VIEW, {
      state: {
        id: event.id,
        backRoute: APP_ROUTES.DOCUMENT_LIST,
        isActiveCheck: isActiveCheck,
        clientId: event?.clientId
      },
    });
    window.scrollTo(0, 0);
  }

  function handleClientView(e, field) {
    dispatch({
      type: SELECTED_CLIENT_ID,
      payload: field?.clientId,
    });
    navigate(APP_ROUTES.CLIENT_DASHBOARD);
  }

  function handleAuthorView(event) {
    dispatch({
      type: SELECTED_STAFF_ID,
      payload: event.createdBy,
    });
    navigate(APP_ROUTES.STAFF_PROFILE);
  }

  function viewMultipleDocument() {
    if (checkedDocuments && checkedDocuments?.length < 1) {
      showError("Please select any document!");
      return;
    }
    navigate(APP_ROUTES.DOCUMENT_MULTI_VIEW, {
      state: {
        idList: checkedDocuments?.map((x) => x.documentId),
        backRoute: APP_ROUTES.DOCUMENT_LIST,
        clientId: checkedDocuments?.map((item) => item?.clientId)
      },
    });
  }

  async function getDocumentPrintData() {
    let riskAreaList: any = await docs.getRiskDDL('riskArea')
    let riskFactorsList: any = await docs.getRiskDDL('riskFactors')
    let protectiveFactorsList: any = await docs.getRiskDDL('protectiveFactors')
    localStorage.setItem('riskList', JSON.stringify(riskAreaList?.resultData));
    localStorage.setItem('riskFactorList', JSON.stringify(riskFactorsList?.resultData));
    localStorage.setItem('riskProtectiveList', JSON.stringify(protectiveFactorsList?.resultData));

    if (checkedDocuments.length > 30) {
      showError(`You have selected ${checkedDocuments.length} documents but you can print max 30 documents.`);
      return;
    }
    let documentArrayItem: any = []
    const selectedDocumentsId = checkedDocuments?.map((x) => x.documentId)
    const chunkSize = 5;
    const chunks: any = [];

    for (let i = 0; i < selectedDocumentsId.length; i += chunkSize) {
      const chunk = selectedDocumentsId.slice(i, i + chunkSize);
      chunks.push(chunk);
    }

    setLoadingDocBatchPrint(true)
    const apiResponsesAfterChunks = await chunks.forEach(async (element: number[], indexMainArray) => {
      const multiDocData: any = await customAxios.post(`${API_ENDPOINTS.GET_MULTIPLE_DOCUMENT_DETAIL}`, element);
      if (multiDocData && multiDocData.status) {
        setPrintItemCount(printItem => printItem + element.length)
        await multiDocData.resultData.map((documentDetailItems: any) => {
          const hadDx = (documentDetailItems.documentDetails.documentCommonControls.documentGoals?.length ?? 0 > 0) ? true : false
          const hasGoals = (documentDetailItems.documentDetails.documentCommonControls.documentDiagnosis?.length ?? 0 > 0) ? true : false
          const docData = {
            documentDetail: convertServerDocumentToLocal(documentDetailItems.documentDetails),
            docSignature: documentDetailItems.staffSignatures,
            docClientSignature: documentDetailItems.clientSignatures,
            isHtmlFileTypeTemplate: documentDetailItems.documentDetails.isHtmlFileTypeTemplate,
            htmlFileName: documentDetailItems.documentDetails.htmlFileName,
            template: mapDocumentTemplate(documentDetailItems.template),
            fieldsMapping: documentDetailItems.documentDetails.documentFieldsMappings,
            documentId: documentDetailItems.documentId,
            docTreatmentPlans: documentDetailItems.documentDetails?.documentTreatmentPlans,
            docHasControlls: (hadDx || hasGoals),
            documentCommonControls: documentDetailItems.documentDetails.documentCommonControls,
            historyList: documentDetailItems.documentHistories,
            mileagePayRate: documentDetailItems.staffMileageRate
          }
          documentArrayItem.push(docData)
        })
      }
      if (documentArrayItem.length === selectedDocumentsId.length) {
        setPrintItemCount(0)
        setLoadingDocBatchPrint(false)
        localStorage.setItem('print-doc-state', JSON.stringify(documentArrayItem));

        window.open(APP_ROUTES.DOCUMENT_PRINT, '_blank', 'noopener');
        handleSetDocumentPrint();
      }
    });
  }

  function handleAddNewDocument() {
    navigate(APP_ROUTES.DOCUMENT_ADD);
  }

  function handleDocumentAction() {
    setIsOpenDocumentActionModel(!isOpenDocumentActionModel)
  }

  function handleDocumentDraftsClick() {
    navigate(APP_ROUTES.DOCUMENT_DRAFT_LIST);
  }

  function handleCloseAttachmentDialog() {
    setOpenAttachmentDialog(false)
  }

  const onHeaderSelectionChange = React.useCallback((event) => {
    const checkboxElement = event.syntheticEvent.target;
    const checked = checkboxElement.checked;
    setAllSelected(checked)
    let data = event.dataItems
    if (data.length > 0) {
      data.map(function (e) {
        e.isSelected = false;
      });
      if (checked) {
        setCheckedDocuments(data)
      } else {
        setCheckedDocuments([])
      }
    }
  }, []);

  function onCheckboxChange(e, docObj) {
    docObj.isSelected = false
    if (e.value) {
      setCheckedDocuments([...checkedDocuments, docObj]);
    }
    else {
      setCheckedDocuments(checkedDocuments?.filter((obj) => obj.documentId !== docObj.documentId));
    }
  }

  const handleSelectAction = (e) => {
    const value = e.item.text;
    if (value === "Update Billing Status") {
      setIsOpenDocumentActionModel(!isOpenDocumentActionModel)
    } else if (value === "Set Payroll Status") {
      setIsOpenDocumentPayrollModel(!isOpenDocumentPayrollModel)
    } else if (value === "Lock Documents") {
      setDocumentActionUsed(true);
      setLockDocument(false);
      setOpenLock(true);
    } else if (value === "Unlock Documents") {
      setDocumentActionUsed(true)
      setLockDocument(true);
      setOpenLock(true)
    } else if (value === "Approve Documents") {
      setDocumentActionUsed(true)
      let docArry: number[] = [];
      checkedDocuments?.forEach((document) => {
        if (document.docStatus !== "Approved ") {
          docArry.push(document?.documentId);
        }
      });
      const body = {
        docIds: docArry,
        isApproved: true,
      };
      dispatch(ApproveOrDisapproveDocuments(body))
    }
    else if (value === "Add Billing Comment") {
      setShowBillingCommentsDialogBox(true)
    }
  }

  const handleAuthId = (e: any, field: any, open: string) => {
    setIsOpenAuthModel(!IsOpenAuthModel)
    setSelectAuthId(field?.authId)
    if (open) {
      setScroll(true)
    }
    else {
      setScroll(false)
    }
  };

  const hideLockPopUp = () => {
    setOpenLock(false);
    setScroll('false');
  };

  function lockUnlockDocument() {
    let docArry: number[] = []
    if (isLockDocument) {
      checkedDocuments?.forEach((document) => {
        if (document.isLocked !== false) {
          docArry.push(document?.documentId);
        }
      });
    }
    if (!isLockDocument) {
      checkedDocuments?.forEach((document) => {
        if (document.isLocked !== true) {
          docArry.push(document?.documentId);
        }
      });
    }
    const body = {
      ids: docArry,
      isLock: !isLockDocument ? true : false,
      deleteAssociateSigns: isDeleteAssociateSign,
    };
    dispatch(LockUnlockDocuments(body))
    if (isLockDocument === true) {
      setDeleteAssociateSign(false);
    }
    hideLockPopUp();
  }

  const updateCheckedDocStatus = (selectedBillingStatus) => {
    setCheckedDocuments(list => list.map((docItem, i) => {
      return { ...docItem, 'billingStatusId': selectedBillingStatus.id }
    }))
  }
  /* ============================= Render functions ============================= */

  function renderSearchView() {
    return (
      <>
        <DocumentSearchView
          setAdvSearchFields={setAdvSearchFields}
          advSearchFields={advSearchFields}
          setDisplaySearchResult={setDisplaySearchResult}
          setPage={setSkip}
          canSeeOtherStaffDoc={canSeeOtherStaffDoc}
          clearSearchObject={clearAdvSearchObj}
          setPageSize={setPageSize}
          docFilter={docFilter}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          setIsActiveCheck={setIsActiveCheck}
          isActiveCheck={isActiveCheck}
          pastDate={pastDate}
          currentDate={currentDate}
          openDateFilter={openDateFilter}
          setOpenDateFilter={setOpenDateFilter}
          setTempInitialDate={setTempInitialDate}
          tempInitalDate={tempInitalDate}
          setTempPrevDate={setTempPrevDate}
          tempPrevDate={tempPrevDate}
          radioStaffFilter={radioStaffFilter}
          radioStaffLabelFilter={radioStaffLabelFilter}
          radioDateFilter={radioDateFilter}
          radioLabelFilter={radioLabelFilter}
          handleChangeDateFilterTypes={handleChangeDateFilterTypes}
          handleChangeStaffFilterTypes={handleChangeStaffFilterTypes}
        />
      </>
    );
  }

  function renderHeader() {
    return (
      <div className="row " >
        <div className="col-lg-6 col-sm-12 pl-0">
          <h4 className="address-title text-grey">
            <span className="f-24">Documents</span>
          </h4>
        </div>
        <div className="col-lg-6 col-sm-12 ">
          <div className="right-align-docs " style={{ display: "flex", justifyContent: "flex-end", textAlign: "end", flexWrap: "wrap", rowGap: "8px", alignItems: "stretch" }}>

            {(isHumanResourcesManager || isBillingManager || rolePermisson.canReviewDocuments || rolePermisson.canModifyBillingStatus || userIsSuperAdmin(staffLoginInfo?.roleId)) && checkedDocuments.length > 0 &&
              <div style={{ display: "inline" }} className="mx-2">
                <CustomSplitButton
                  items=
                  {AllActionItem}
                  text="Document Actions" onChange={handleSelectAction} size={"small"} />
              </div>
            }

            {postBodyObject?.startDate !== undefined ? <StaffServiceReport postBodyObject={postBodyObject} /> : null}
            <Button
              onClick={handleDocumentDraftsClick}
              className="cancelBtn"
            >
              {/* <img src={addIcon} alt="" className="mr-2 add-img" /> */}
              <i className="fa-brands fa-firstdraft"></i> Draft{" "}
              {draftCount > 0 ? `(${draftCount})` : ""}
            </Button>
            {<DocumentExportToExcel documentList={newData}></DocumentExportToExcel>}
            <Button onClick={handleAddNewDocument} className="submitButon">
              <i className="fa fa-plus "></i> Add Document
            </Button>
          </div>
        </div>
      </div>
    );
  }

  function renderDocuments() {
    const CustomActionCell = (props) => {
      const navigationAttributes = useTableKeyboardNavigation(props.id);
      return (
        <td
          colSpan={props.colSpan}
          role={"gridcell"}
          aria-colindex={props.ariaColumnIndex}
          aria-selected={props.isSelected}
          {...{ [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex }}
          {...navigationAttributes}
        >
          <div
            className="k-chip k-chip-md k-rounded-md k-chip-solid k-chip-solid-base" style={{ background: "#ffebee" }}
            onClick={() => {
              handleConfirm(props.dataItem.id);
            }}
          >
            <div className="k-chip-content">
              <Tooltip anchorElement="target" position="top">
                {/* <i
                  className={
                    isActiveCheck === true ? "fa fa-rotate-left" : "fa fa-trash"
                  } */}
                <img src={trashIcon} alt="trash icon" aria-hidden="true" className={
                  isActiveCheck === true ? "fa fa-rotate-left" : "fa fa-trash"
                }

                  title={isActiveCheck === true ? "Reactivate" : "Delete"}
                />
              </Tooltip>
            </div>
          </div>

        </td>
      );
    };

    const CustomCheckBox = (props) => {
      let docObj: DocumentObj = props.dataItem;
      return (
        <td>
          <div className="k-chip-content">
            <Checkbox
              label={""}
              value={!!checkedDocuments?.find((obj) => docObj?.documentId === obj?.documentId)}
              onChange={(e) => onCheckboxChange(e, docObj)}
            />
          </div>
        </td>
      );
    };

    const showMoreList = (id) => {
      setExpandedModule({ ...expandedModule, [id]: !expandedModule[id] });
    };


    const dataStateChange = (event) => {
      setPage(event.dataState.skip);
      setPageSize(event.dataState.take);
      setSort(event.dataState.sort);
    };

    const CustomHeaderCell = (props) => {
      return (
        <th className="k-header" style={{ cursor: 'default', top: "8px", left: "-10px" }}>
          {props.title}
        </th>
      );
    };

    const handlePayrollStatus = (fields) => {
      const comments = fields?.payrollComments ? ` (${fields.payrollComments})` : '';
     
      if (fields?.payrollPaid === 'YES') {
        return 'Paid on ' + moment(fields.payrollDate).format("M/DD/YY") + comments;
      } else if (fields?.payrollPaid === 'RO') {
        return 'RO ' + moment(fields.payrollDate).format("M/DD/YY") + comments;
      } else if (fields?.payrollPaid === 'NO') {
        return `No${comments}`;
      } else {
        return `NR${comments}`;
      }
    };

    return (
      <>
        <div className="grid-table table-heading-auth WhiteUnset blueThemeTable blue_theme">
          {
            loadingStateDocList ?
              <TableRowsLoader rowsNum={12} />
              :
              <Grid
                data={newData.map((item: any) => ({
                  ...item,
                  [SELECTED_FIELD]: selectedState[idGetter(item)],
                }))}
                skip={skip}
                take={pageSize}
                onPageChange={pageChange}
                total={documentList.length > 0 ? metaData?.totalCount : 0}
                pageable={{
                  pageSizes: [10, 25, 50, 100, 250, 500, 1000],
                }}
                selectedField={SELECTED_FIELD}
                dataItemKey={DATA_ITEM_KEY}
                onExpandChange={onExpandChange}
                onHeaderSelectionChange={onHeaderSelectionChange}
                sort={sort}
                sortable={true}
                onSortChange={(e: any) => {
                  if (e.sort) {
                    setSort(e.sort);
                  }
                }}
                onDataStateChange={dataStateChange}
              >
                <GridColumn
                  cell={CustomCheckBox}
                  // className="cursor-default  icon-align"
                  width="50px"
                  field={SELECTED_FIELD}
                  headerSelectionValue={isAllSelected}

                />
                <GridColumn
                  width="100px"
                  title="ID"
                  sortable={false}
                  cell={(props) => {
                    let field = props.dataItem;
                    return (
                      <td
                        className={"anchar-pointer text-theme"}
                        onClick={() => handleDocumentView(field)}
                      ><i className="fa fa-eye pr-1"></i>
                        {field.id}
                      </td>
                    );
                  }}
                  headerCell={CustomHeaderCell}
                  className="anchar-pointer text-theme"
                />

                <GridColumn
                  field="serviceDateObj"
                  width="100px"
                  title="DOS"
                  format="{0:d}"
                  className="cursor-default"
                />

                <GridColumn
                  title="Template"
                  width="200 px"
                  cell={(props) => {
                    let field = props.dataItem;
                    return (
                      <td>
                        <span>{field.documentTemplateName}</span>
                        <span className="fw-600" style={{ display: "block", color: '#C0C0C0' }}>{`(${field.templateTypeName})`}</span>
                      </td>
                    );
                  }}
                  className="anchar-pointer text-theme"
                />

                <GridColumn
                  field="clientLName"
                  title="Client"
                  width="130px"
                  cell={(props) => {
                    let field = props.dataItem;
                    return (
                      <td
                        className={"anchar-pointer text-theme"}
                        onClick={(e) => handleClientView(e, field)}
                      >
                        {field.clientLName + ", " + field.clientFName}
                      </td>
                    );
                  }}
                  className="anchar-pointer text-theme"
                />
                <GridColumn
                  field="dateOfBirth"
                  width="120px"
                  title="DOB"
                  format="{0:d}"
                  className="cursor-default"
                  sortable={false}
                  headerCell={CustomHeaderCell}

                />

                <GridColumn
                  width="150px"
                  field="service"
                  title="Service"
                  className="cursor-default wrap-text"
                />

                <GridColumn
                  field="timeStr"
                  width={180}
                  title="Duration"
                  className="cursor-default"
                  sortable={false}
                  headerCell={CustomHeaderCell}
                  cell={(props) => {
                    let field = props.dataItem;
                    if (field.timeStr === "-") {
                      return (
                        <td>
                          {field.timeStr}
                        </td>
                      );
                    } else {
                      return (
                        <td className="cursor-default">
                          <span>{field.timeStr}</span>
                          <br></br>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <span className="fw-600"
                            style={{
                              color: '#C0C0C0',
                            }}
                          >
                            ({field.timeDiff})
                          </span>
                        </td>
                      );
                    }
                  }}
                />

                <GridColumn
                  title="Place of Srv."
                  width="110px"
                  className="cursor-default"
                  sortable={false}
                  headerCell={CustomHeaderCell}
                  cell={(props) => {
                    let posList = props.dataItem.placeOfServiceList
                    let arry = posList.length > 0 && posList.slice(0,
                      expandedModule[props.dataItem.id] ? posList.length : itemsToShow
                    );
                    setICDData(posList);

                    return (
                      <td className="cursor-default">
                        <ul>
                          {arry.length > 0 &&
                            arry.map((obj, i) => (
                              <>
                                <li className="mb-0" key={i} title={obj?.placeOfServiceName}>
                                  {obj?.locCode}
                                  {posList.length > 3 && arry.at(-1).id === obj?.id && (
                                    <span
                                      className="plus-click  ml-2 cursor-pointer"
                                      onClick={() => {
                                        showMoreList(props.dataItem.id);
                                      }}
                                    >
                                      {expandedModule[props.dataItem.id] ? (
                                        <i className="fa fa-minus "></i>
                                      ) : (
                                        <i className="fa fa-plus"></i>
                                      )}
                                    </span>
                                  )}
                                </li>
                              </>
                            ))}
                        </ul>
                      </td>
                    );
                  }}
                />

                <GridColumn
                  field="createdByStaff"
                  width="180px"
                  title="Staff (Supervisor)"
                  cell={(props) => {
                    let field = props.dataItem;
                    return (
                      <td className={"anchar-pointer text-theme"} onClick={() => handleAuthorView(field)}>
                        {field.createdByStaff}
                        <br></br>
                        {field.supervisorStaffId > 0 ? <span className="fw-600" style={{ color: '#C0C0C0', }}>({field.supervisorName})</span> : ""}
                      </td>
                    );
                  }}
                  className="anchar-pointer text-theme"
                />

                <GridColumn
                  width="120px"
                  field="clientDiagnosisName"
                  title="Dx"
                  className="cursor-default"
                  sortable={false}
                  headerCell={CustomHeaderCell}
                />

                <GridColumn
                  field="docStatus"
                  width="130px"
                  title="Doc Status"
                  className="cursor-default"
                />

                <GridColumn
                  field="primaryInsurance"
                  title="Payer"
                  width="190px"
                  className="cursor-default"
                  sortable={false}
                  headerCell={CustomHeaderCell}
                />

                <GridColumn
                  field="numUnits"
                  width="50px"
                  title="Units"
                  className="cursor-default"
                  sortable={false}
                  headerCell={CustomHeaderCell}
                  cell={(props) => {
                    let field = props.dataItem;
                    return (
                      <td className="cursor-default">
                        {field.numUnits === 0 ? " " : field.numUnits}
                      </td>
                    );
                  }}
                />

                {
                  (isBillingManager || rolePermisson.showBillingAmounts) &&
                  <GridColumn
                    width="95px"
                    field="serviceRate"
                    title="Srv. Rate"
                    className="cursor-default"
                    sortable={false}
                    headerCell={CustomHeaderCell}
                    cell={(props) => {
                      let field = props.dataItem;
                      return (
                        <td className="cursor-default">
                          {field?.serviceRate > 0 ? <NumericFormat value={Number(field?.serviceRate).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> : ""}
                        </td>
                      );
                    }}
                  />
                }

                {
                  (isBillingManager || rolePermisson.showBillingAmounts) &&
                  <GridColumn
                    width="95px"
                    field="amtBilled"
                    title="Tot. Billed"
                    className="cursor-default"
                    sortable={false}
                    headerCell={CustomHeaderCell}
                    format="{0:c}"
                    cell={(props) => {
                      let field = props.dataItem;
                      return (
                        <td className="cursor-default">
                          {field?.amtBilled > 0 ? <NumericFormat value={Number(field?.amtBilled).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> : ""}
                        </td>
                      );
                    }}
                  />
                }
                {
                  (isBillingManager || rolePermisson.canModifyBillingStatus) &&
                  <GridColumn
                    width="150px"
                    field="billingStatusName"
                    title="Bill Status"
                    className="cursor-default"
                  />
                }

                {
                  (userIsSuperAdmin(staffLoginInfo?.roleId) || isBillingManager) &&
                  <GridColumn
                    width="150px"
                    title="Amt Paid"
                    className="cursor-pointer"
                    sortable={false}
                    headerCell={CustomHeaderCell}
                    cell={(props) => (
                      <>
                        <td className="cursor-default">
                          {props?.dataItem?.amtPaid > 0 ? <NumericFormat value={Number(props?.dataItem?.amtPaid).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> : ""}
                        </td>
                      </>
                    )}
                  />
                }

                {
                  isBillingManager &&
                  <GridColumn
                    width="150px"
                    title="Date Paid"
                    className="cursor-pointer"
                    sortable={false}
                    headerCell={CustomHeaderCell}
                    cell={(props) => (
                      <>
                        <td>
                          {props?.dataItem?.datePaid != null ? moment.utc(props.dataItem.datePaid).local().format("M/D/YYYY") : ""}
                          {props?.dataItem?.batchId === 0 ? "" : <td className="anchar-pointer text-theme"
                            onClick={(e) => {
                              dispatch(selectBatchId(props?.dataItem?.batchId))
                              navigate(APP_ROUTES.BILLING_TRANSACTION)
                            }}
                            style={{ textDecoration: "underLine" }}>{props?.dataItem?.batchId === 0 ? "" : props?.dataItem?.batchId}</td>}

                        </td>
                      </>
                    )}
                  />
                }


                {
                  (userIsSuperAdmin(staffLoginInfo?.roleId) || isBillingManager) &&
                  <GridColumn
                    width="150px"
                    title="Last Billed"
                    className="cursor-pointer"
                    sortable={false}
                    headerCell={CustomHeaderCell}
                    cell={(props) => (
                      <>
                        <td>
                          {props.dataItem.lastBilledDate != null ? moment.utc(props.dataItem.lastBilledDate).local().format("M/D/YYYY") : ""}
                          {props?.dataItem?.batchId === 0 ? "" : <td className="anchar-pointer text-theme"
                            onClick={(e) => {
                              dispatch(selectBatchId(props?.dataItem?.batchId))
                              navigate(APP_ROUTES.BILLING_TRANSACTION)
                            }}
                            style={{ textDecoration: "underLine" }}>{props?.dataItem?.batchId === 0 ? "" : props?.dataItem?.batchId}</td>}
                        </td>
                      </>
                    )}
                  />
                }
                <GridColumn
                  width="120px"
                  title="Auth ID"
                  sortable={false}
                  headerCell={CustomHeaderCell}
                  cell={(props) => {
                    let field = props.dataItem;
                    return (
                      <>
                        {field?.custAuthId == null ? <td className="cursor-default"></td> : <td style={{ textDecoration: "underLine" }} onClick={(e) => handleAuthId(e, field, "open")} className="anchar-pointer text-theme">
                          {field.custAuthId}
                        </td>
                        }
                      </>
                    );
                  }}
                />

                <GridColumn
                  field="siteName"
                  title="Site of Srv."
                  className="cursor-default"
                  width="130px"
                  sortable={false}
                  headerCell={CustomHeaderCell}
                />

                <GridColumn
                  field="utcDateCreated"
                  width="120px"
                  title="Date Submitted"
                  format="{0:d}"
                  className="cursor-default"
                  cell={(props) => (
                    <td>
                      {moment.utc(props.dataItem.utcDateCreated).local().format("M/D/YYYY")}
                    </td>
                  )}
                />
                <GridColumn
                  field="isLocked"
                  title="Locked"
                  width="65px"
                  className="cursor-default"
                  sortable={false}
                  headerCell={CustomHeaderCell}
                  cell={(props) => {
                    let field = props.dataItem.isLocked;
                    return (
                      <td className="cursor-default">
                        {field === true && (
                          <span
                            className="fa fa-lock cursor-default"
                            style={{ color: "#e9a01ae3" }}
                          ></span>
                        )}
                      </td>
                    );
                  }}
                />
                <GridColumn
                  title="Signed"
                  width="90px"
                  sortable={false}
                  headerCell={CustomHeaderCell}
                  cell={(props) => {
                    let field = props.dataItem;
                    return (
                      <td className="cursor-default ml-0 ">
                        {field && (
                          <div className="d-flex ">
                            {field?.isSignedByClient &&
                              <Tooltip anchorElement="target" position="top">
                                <span
                                  className="fa fa-check-circle cursor-default mr-1"
                                  style={{ color: "purple" }}
                                  aria-hidden="true"
                                  title={"Client Signed"}
                                >
                                </span>
                              </Tooltip>
                            }
                            {field?.isSignedByReviewer &&
                              <Tooltip anchorElement="target" position="top">
                                <span
                                  className="fa fa-check-circle cursor-default mr-1"
                                  style={{ color: "blue" }}
                                  aria-hidden="true"
                                  title={"Reviewer Signed"}
                                >
                                </span>
                              </Tooltip>
                            }
                            {field?.isSignedBySupervisor &&
                              <Tooltip anchorElement="target" position="top">
                                <span
                                  className="fa fa-check-circle cursor-default mr-1"
                                  style={{ color: "#e9a01ae3" }}
                                  aria-hidden="true"
                                  title={"Supervisor Signed"}
                                >
                                </span>
                              </Tooltip>
                            }
                            {field?.isSignedByAuthor &&
                              <Tooltip anchorElement="target" position="top">
                                <span
                                  className="fa fa-check-circle cursor-default mr-1"
                                  style={{ color: "green" }}
                                  aria-hidden="true"
                                  title={"Author Signed"}
                                >
                                </span>
                              </Tooltip>
                            }
                          </div>
                        )}
                      </td>
                    );
                  }}
                />
                <GridColumn
                  field="isPrinted"
                  title="Printed"
                  width="110px"
                  sortable={false}
                  headerCell={CustomHeaderCell}
                  cell={(props) => {
                    let field = props.dataItem;
                    return (
                      <td className='cursor-default'>
                        {field?.isPrinted &&
                          <Tooltip anchorElement="target" position="top">
                            <span
                              className="fa fa-check-circle cursor-default mr-1"
                              style={{ color: "green" }}
                              aria-hidden="true"
                            >
                            </span>
                          </Tooltip>
                        }
                      </td>
                    );
                  }}
                />
                <GridColumn
                  title="File Att'd"
                  sortable={false}
                  headerCell={CustomHeaderCell}
                  width = "85px"
                  cell={(props) => {
                    let field = props.dataItem.hasAttachment;
                    return (
                      <>
                        {field ? 
                        <td className = "ancher-pointer">
                          <div
                            onClick={() => {
                              setOpenAttachmentDialog(true);
                              setDocId(props.dataItem?.id)
                              setDocDos(moment.utc(props.dataItem.utcDateCreated).local().format("M/D/YYYY"))
                              setDocClientName(props.dataItem?.clientName)
                            }}
                          >
                            <span
                              className="fa fa-paperclip"
                              style={{ color: "#3e80ed", marginRight: "8px" }}
                            ></span>
                            <span style={{ textDecoration: "underLine" }} className = 'mr-2 text-theme'>View</span>
                            </div>
                          </td> : <td className="cursor-default"></td>}
                        
                      </>
                    );
                  }}
                />
                {
                  (isHumanResourcesManager || userIsSuperAdmin(staffLoginInfo?.roleId)) && <GridColumn
                  field="isSigned"
                  title="Payroll Status"
                  width="200px"
                  sortable={false}
                  headerCell={CustomHeaderCell}
                  cell={(props) => {
                    let field = props.dataItem;
                    const content = handlePayrollStatus(field);
                    return <TruncatedText text={content} />;
                  }}
                />
                }
                <GridColumn
                  cell={CustomActionCell}
                  width="65px"
                  className="cursor-default"
                  sortable={false}
                  headerCell={CustomHeaderCell}
                />


              </Grid>
          }
        </div>
      </>
    );
  }

  function AppliedFiltersContent() {
    return (

      ((docFilter?.template && docFilter?.template.length > 0) ||
        (docFilter?.payer && docFilter?.payer?.length > 0) ||
        (docFilter?.documentStatus && docFilter?.documentStatus != null) ||
        (docFilter?.billStatus && docFilter?.billStatus.length > 0) ||
        (docFilter?.lockedValue && docFilter?.lockedValue.name) ||
        (docFilter?.documentId && docFilter?.documentId != null) ||
        (docFilter?.isPayrollPaid && docFilter?.isPayrollPaid != null) ||
        (docFilter?.showTrashNote) ||
        (docFilter?.signatures && docFilter?.signatures != null) ||
        (docFilter?.flag && docFilter?.flag != null) ||
        (docFilter?.clientStatus && docFilter?.clientStatus != null) ||
        (docFilter?.denialReason && docFilter?.denialReason.length > 0) ||
        (docFilter?.docTempType && docFilter?.docTempType?.length > 0) ||
        (docFilter?.hasAttachment && docFilter?.hasAttachment !== null) ||
        (docFilter?.billingBatchId && docFilter?.billingBatchId != null) || 
        (docFilter?.clientAge && docFilter?.clientAge != null) ||
        (docFilter?.refName && docFilter?.refName != null)
      )
      &&
      <div className="switch-title-text mt-2 d-flex align-items-center">
        <span className="f-16 fw-600 ml-1 mb-2" style={{ width: "120px" }}>Additional Filters:</span>
        <div className="d-flex ml-2 flex-wrap ">
          {(docFilter?.template && docFilter?.template.length > 0) && (
            <div className="mb-2" style={{ marginRight: "3px" }}>
              <Chip
                sx={{
                  '& .MuiChip-deleteIcon': {
                  },
                }}
                onDelete={() => {
                  dispatch(saveDocumentSearchFilter({ ...advSearchFields, template: [] }))
                  setAdvSearchFields({ ...advSearchFields, template: [] })
                }}
                label={`Template - ${(docFilter.template.length === 1) ?
                  docFilter.template[0].name :
                  docFilter.template[0].name + "(+" + (docFilter.template.length - 1 + ")")
                  }`}
                variant="outlined"
              />
            </div>
          )}

          {(docFilter.payer && docFilter.payer.length > 0) && (
            <div className="mb-2" style={{ marginRight: "3px" }}>
              <Chip
                sx={{
                  '& .MuiChip-deleteIcon': {
                  },
                }}
                onDelete={() => {
                  dispatch(saveDocumentSearchFilter({ ...advSearchFields, payer: [] }))
                  setAdvSearchFields({ ...advSearchFields, payer: [] })
                }}
                label={`Payer - ${(docFilter.payer.length === 1) ?
                  docFilter.payer[0].name :
                  docFilter.payer[0].name + "(+" + (docFilter.payer.length - 1 + ")")
                  }`}
                variant="outlined"
              />
            </div>
          )}

          {(docFilter.documentStatus && docFilter.documentStatus != null) ? (
            <div className="mb-2" style={{ marginRight: "3px" }}>
              <Chip
                sx={{
                  '& .MuiChip-deleteIcon': {
                  },
                }}
                onDelete={() => {
                  setAdvSearchFields({ ...advSearchFields, documentStatus: null })
                  dispatch(saveDocumentSearchFilter({ ...advSearchFields, documentStatus: null }))
                }}
                label={`Doc Status - ${docFilter?.documentStatus.name}`}
                variant="outlined"
              />
            </div>
          ) : null}

          {(docFilter?.billStatus && docFilter?.billStatus.length > 0) ? (
            <div className="mb-2" style={{ marginRight: "3px" }}>
              <Chip
                sx={{
                  '& .MuiChip-deleteIcon': {
                  },
                }}
                onDelete={() => {
                  setAdvSearchFields({ ...advSearchFields, billStatus: [] })
                  dispatch(saveDocumentSearchFilter({ ...advSearchFields, billStatus: [] }))
                }}
                label={`Bill Status - ${(docFilter?.billStatus?.length === 1) ?
                  docFilter.billStatus[0]?.name :
                  docFilter.billStatus[0]?.name + "(+" + (docFilter?.billStatus?.length - 1 + ")")
                  }`}
                variant="outlined"
              />
            </div>
          ) : null}

          {(docFilter?.isPayrollPaid) ? (
            <div className="mb-2" style={{ marginRight: "3px" }}>
              <Chip
                sx={{
                  '& .MuiChip-deleteIcon': {
                  },
                }}
                onDelete={() => {
                  setAdvSearchFields({ ...advSearchFields, isPayrollPaid: undefined })
                  dispatch(saveDocumentSearchFilter({ ...advSearchFields, isPayrollPaid: undefined }))
                }}
                label={`Payroll Status - ${docFilter?.isPayrollPaid?.name}`}
                variant="outlined"
              />
            </div>
          ) : null}

          {(docFilter?.lockedValue && docFilter?.lockedValue.name) ? (
            <div className="mb-2" style={{ marginRight: "3px" }}>
              <Chip
                sx={{
                  '& .MuiChip-deleteIcon': {
                  },
                }}
                onDelete={() => {
                  setAdvSearchFields({ ...advSearchFields, lockedValue: null })
                  dispatch(saveDocumentSearchFilter({ ...advSearchFields, lockedValue: null }))
                }}
                label={`Locked - ${docFilter?.lockedValue.name}`}

                variant="outlined"
              />
            </div>
          ) : null}

          {(docFilter?.documentId && docFilter?.documentId != null) && (
            <div className="mb-2" style={{ marginRight: "3px" }}>
              <Chip
                sx={{
                  '& .MuiChip-deleteIcon': {
                  },
                }}
                onDelete={() => {
                  setAdvSearchFields({ ...advSearchFields, documentId: null })
                  dispatch(saveDocumentSearchFilter({ ...advSearchFields, documentId: null }))
                }}
                label={`Document ID - ${docFilter?.documentId}`}
                variant="outlined"
              />
            </div>
          )}

          {(docFilter?.showTrashNote) && (
            <div className="mb-2" style={{ marginRight: "3px" }}>
              <Chip
                sx={{
                  '& .MuiChip-deleteIcon': {
                  },
                }}
                onDelete={() => {
                  setAdvSearchFields({ ...advSearchFields, showTrashNote: false })
                  dispatch(saveDocumentSearchFilter({ ...advSearchFields, showTrashNote: false }))
                  setIsActiveCheck(false)
                }}
                label={`Show Trashed`}
                variant="outlined"
              />
            </div>
          )}

          {(docFilter?.signatures && docFilter?.signatures.name) ? (
            <div className="mb-2" style={{ marginRight: "3px" }}>
              <Chip
                sx={{
                  '& .MuiChip-deleteIcon': {
                  },
                }}
                onDelete={() => {
                  setAdvSearchFields({ ...advSearchFields, signatures: null })
                  dispatch(saveDocumentSearchFilter({ ...advSearchFields, signatures: null }))
                }}
                label={`Signatures - ${docFilter?.signatures.name}`}
                variant="outlined"
              />
            </div>
          ) : null}

          {(docFilter?.flag && docFilter?.flag.flagName) ? (
            <div className="mb-2" style={{ marginRight: "3px" }}>
              <Chip
                sx={{
                  '& .MuiChip-deleteIcon': {
                  },
                }}
                onDelete={() => {
                  setAdvSearchFields({ ...advSearchFields, flag: null })
                  dispatch(saveDocumentSearchFilter({ ...advSearchFields, flag: null }))
                }}
                label={`Flag - ${docFilter?.flag.flagName}`}
                variant="outlined"
              />
            </div>
          ) : null}

          {(docFilter?.clientStatus && docFilter?.clientStatus.statusName) ? (
            <div className="mb-2" style={{ marginRight: "3px" }}>
              <Chip
                sx={{
                  '& .MuiChip-deleteIcon': {
                  },
                }}
                onDelete={() => {
                  setAdvSearchFields({ ...advSearchFields, clientStatus: null })
                  dispatch(saveDocumentSearchFilter({ ...advSearchFields, clientStatus: null }))
                }}
                label={`Client Status - ${docFilter?.clientStatus.statusName}`}
                variant="outlined"
              />
            </div>
          ) : null}

          {(docFilter?.denialReason && docFilter?.denialReason.length > 0) ? (
            <div className="mb-2" style={{ marginRight: "3px" }}>
              <Chip
                sx={{
                  '& .MuiChip-deleteIcon': {
                  },
                }}
                onDelete={() => {
                  setAdvSearchFields({ ...advSearchFields, denialReason: [] })
                  dispatch(saveDocumentSearchFilter({ ...advSearchFields, denialReason: [] }))
                }}
                label={`Denial Reason - ${(docFilter?.denialReason?.length === 1) ?
                  docFilter.denialReason[0]?.code :
                  docFilter.denialReason[0]?.code + "(+" + (docFilter?.denialReason?.length - 1 + ")")
                  }`}
                variant="outlined"
              />
            </div>
          ) : null}

          {(docFilter?.docTempType && docFilter?.docTempType.length > 0) ? (
            <div className="mb-2" style={{ marginRight: "3px" }}>
              <Chip
                sx={{
                  '& .MuiChip-deleteIcon': {
                  },
                }}
                onDelete={() => {
                  dispatch(saveDocumentSearchFilter({ ...advSearchFields, docTempType: [] }))
                  setAdvSearchFields({ ...advSearchFields, docTempType: [] })
                }}
                label={`Doc Type - ${(docFilter.docTempType.length === 1) ?
                  docFilter.docTempType[0].name :
                  docFilter.docTempType[0].name + "(+" + (docFilter.docTempType.length - 1 + ")")
                  }`}
                variant="outlined"
              />
            </div>
          ) : null}

          {(docFilter.hasAttachment && docFilter.hasAttachment != null) ? (
            <div className="mb-2" style={{ marginRight: "3px" }}>
              <Chip
                sx={{
                  '& .MuiChip-deleteIcon': {
                  },
                }}
                onDelete={() => {
                  setAdvSearchFields({ ...advSearchFields, hasAttachment: null })
                  dispatch(saveDocumentSearchFilter({ ...advSearchFields, hasAttachment: null }))
                }}
                label={`Has Attachment - ${docFilter?.hasAttachment.name}`}
                variant="outlined"
              />
            </div>
          ) : null}

        {(docFilter?.billingBatchId && docFilter?.billingBatchId != null) && (
          <div className="mb-2" style={{ marginRight: "3px" }}>
            <Chip
              sx={{
                '& .MuiChip-deleteIcon': {
                },
              }}
              onDelete={() => {
                setAdvSearchFields({ ...advSearchFields, billingBatchId: null })
                dispatch(saveDocumentSearchFilter({ ...advSearchFields, billingBatchId: null }))
              }}
              label={`Billing Batch ID - ${docFilter?.billingBatchId}`}
              variant="outlined"
            />
          </div>
        )}

        {(docFilter?.clientAge && docFilter?.clientAge != null) && (
                  <div className="mb-2" style={{ marginRight: "3px" }}>
                    <Chip
                      sx={{
                        '& .MuiChip-deleteIcon': {
                        },
                      }}
                      onDelete={() => {
                        setAdvSearchFields({ ...advSearchFields, clientAge: null })
                        dispatch(saveDocumentSearchFilter({ ...advSearchFields, clientAge: null }))
                      }}
                      label={`Client Age - ${docFilter?.clientAge.name}`}
                      variant="outlined"
                    />
                  </div>
        )}


      {(docFilter?.refName) && (
          <div className="mb-2" style={{ marginRight: "3px" }}>
            <Chip
              sx={{
                '& .MuiChip-deleteIcon': {
                },
              }}
              onDelete={() => {
                setAdvSearchFields({ ...advSearchFields, refName: null })
                dispatch(saveDocumentSearchFilter({ ...advSearchFields, refName: null }))
              }}
              label={`Referral Source - ${docFilter?.refName.valueName}`}
              variant="outlined"
            />
          </div>
        )}

        </div></div>
    );
  }

  if (!listInitialLoad) return <div>   <TableRowsLoader rowsNum={12} /></div>
  return (
    <div className={showFilter ? "documnet-inner-content" : ""}>
      <div className="container-fluid blueTheme">
        {renderHeader()}
        <div className="row searchBoxShadow row align-items-center mt-2 filterRow" >
          {renderSearchView()}
        </div>
      </div>
      <AppliedFiltersContent />
      <div className="inner-section-edit position-relative text-center tabs-kendoselect  dateDropdown mt-2">
        <TabStrip className="setting-tabs-staff" selected={selected} onSelect={handleSelect}>
          <TabStripTab title="Document List">
            <div className="d-flex flex-wrap ">
              {renderDocuments()}
              <Button onClick={viewMultipleDocument} className="alert alert-success mt-2">
                <i className="fa fa-eye "></i> View Selected Documents
              </Button>
              {(checkedDocuments && checkedDocuments.length > 0) &&
                <Button
                  disabled={loadingDocBatchPrint}
                  onClick={getDocumentPrintData} className="alert alert-success mt-2 ml-1">
                  <i className="fa fa-print "></i> {loadingDocBatchPrint ? `Fetched records for ${printItemCount} documents.` : 'Print Selected Documents'}
                </Button>}
            </div>
          </TabStripTab>
          <TabStripTab title="Reports">
            <DocumentListChart />
          </TabStripTab>
        </TabStrip>
      </div>

      {confirm && (
        <DeleteDialogModal
          onClose={hideConfirmPopup}
          title="Document"
          message="document"
          handleDelete={handleDelete}
          handleReactive={handleDelete}
          activeType={isActiveCheck}
        />
      )}

      {openAttachmentDialog && (
        <ViewAttachmentDialog
          onClose={handleCloseAttachmentDialog}
          title={"View Attachments"}
          docId={docId}
          clientName={docClientName}
          dos={docDos}
        />
      )}

      {
        showBillingCommentsDialogBox &&
        <Dialog
          onClose={handleCloseBillingCommentDialogBox}
          title="Add Billing Comment"
          className="small-dailog blue_theme" width={"40%"} height={"60%"}
        >
          <div className=" edit-Service-popup py-3">
            <div className="mx-3">
              <div className="row align-items-center">
                <div className="k col-lg-12 col-md-12 col-12">
                  <TextAreaKendoRct
                    txtValue={billingComment}
                    onChange={(e) => setBillingComment(e.target.value)}
                    name="comment"
                    label="Comment"
                    required={true}
                    rows={2}
                  />
                </div>

              </div>
            </div>
          </div>
          <div className="border-bottom-line"></div>
          <div className="d-flex my-4 justify-content-end">
            <div className="right-sde">
              <button
                className="submitButon p  x-4"
                onClick={handleAddBillingComment}
              >
                Add
              </button>
            </div>
            <div className="right-sde-grey">
              <button
                className="cancelBtn mx-3"
                onClick={handleCloseBillingCommentDialogBox}
              >
                Cancel
              </button>
            </div>
          </div>
        </Dialog>
      }



      {IsOpenAuthModel && <AuthModel onClose={handleAuthId} selectAuthId={selectAuthId} />}
      {isOpenDocumentActionModel &&
        <DocumentActionModel
          preSelectedStatus={null}
          userIsSuperAdmin={userIsSuperAdmin(staffLoginInfo?.roleId)}
          onClose={handleDocumentAction} checkedDocuments={checkedDocuments}
          fetchDocuments={(value) => {
            _fetchDocuments(null, null, false)
            updateCheckedDocStatus(value)
          }}
          resetCheckedDocuments={resetCheckedDocuments}
           />}

      {isOpenDocumentPayrollModel && <DocumentPayrollModel onClose={() => { setIsOpenDocumentPayrollModel(!isOpenDocumentPayrollModel) }} checkedDocuments={checkedDocuments} documentList={documentList} fetchDocuments={_fetchDocuments} page={page} pageSize={pageSize} isActiveCheck={isActiveCheck} setCheckedDocuments={setCheckedDocuments} setAllSelected={setAllSelected} />}


      {isOpenLock &&
        (<MultipleDocsLockDialog
          title="Documents"
          textForDialogBox="Locking documents prohibits all further editing to below listed documents. Continue?"
          activeType={isLockDocument}
          setDeleteAssociateSign={setDeleteAssociateSign}
          isDeleteAssociateSign={isDeleteAssociateSign}
          onClose={hideLockPopUp}
          handleDelete={lockUnlockDocument}
          handleReactive={lockUnlockDocument}
          checkedDocuments={checkedDocuments}
        />)
      }
    </div>
  );
};
export default ListDocuments;