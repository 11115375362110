import { takeLatest, put } from 'redux-saga/effects';
import * as TYPES from '../actions/types';
import { loaderAction, saveBillingTransItemByClaimId, saveClaimServiceLine, saveEdiBybatchId ,saveBillingStatus, saveCms1500ByClaimId, saveCms1500ForBatch,save837ClaimById,refreshBillingClaim,saveFullRemittanceById} from '../actions';

import {  EDIResponse, ResponseBillingTransByClaimId } from 'src/dataModels/clientResponse';
import { billingService } from 'src/services/billingService';
import { BillingServiceLineResponse } from 'src/dataModels/billingResponses';
import { NotificationManager } from "react-notifications";
import NOTIFICATION_MESSAGE from 'src/helper/notification-messages';



export function* billingClaimServiceLineSaga() {
  yield takeLatest(TYPES.GET_CLAIM_SERVICE_LINE, claimServiceLineWorker);
}

function* claimServiceLineWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response: BillingServiceLineResponse = yield billingService.billingClaimServiceLine(param['payload']);
    yield put(saveClaimServiceLine(response.resultData));
    yield put(loaderAction(false));
  } catch (err) {
  }
}



export function* getbillingTransItemClaimIdSaga() {
  yield takeLatest(TYPES.GET_BILLING_TRANS_ITEM_BY_CLAIMID, getbillingTransItemClaimIdWorker);
}

function* getbillingTransItemClaimIdWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response: ResponseBillingTransByClaimId = yield billingService.getBillingTransItemByClaimId(param['payload']);
    yield put(saveBillingTransItemByClaimId(response));
    yield put(loaderAction(false));
  } catch (err) {
  }
}

export function* getEdiByBatchIdSaga() {
  yield takeLatest(TYPES.GET_EDI_BY_BATCH_ID, getEdiByBatchIdWorker);
}

function* getEdiByBatchIdWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response: EDIResponse = yield billingService.getEdiBybatchId(param['payload']);
    yield put(saveEdiBybatchId(response.resultData));
    yield put(loaderAction(false));
  } catch (err) {
  }
}

export function* updateTransSrvLineBillingStatusSaga() {
  yield takeLatest(TYPES.UPDATE_TRANSSRVLINE_BILLING_STATUS, updateTransSrvLineBillingStatusWorker)
}
function* updateTransSrvLineBillingStatusWorker(param: any) {
  try {
      yield put(loaderAction(true));
      const response: any = yield billingService.updateTransSrvLineBillingStatus(param['payload'])
      if (response.statusCode === 200) {
          NotificationManager.success(NOTIFICATION_MESSAGE.UPDATE_BILLING_STATUS);
      }
      yield put(loaderAction(false));
  }
  catch (err) {
      yield put(loaderAction(false));

  }
}

export function* getBillingStatusSaga() {
  yield takeLatest(TYPES.GET_BILLING_STATUSES, getBillingStatusWorker);
}

function* getBillingStatusWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response:any = yield billingService.getBillingStatus();
    yield put(saveBillingStatus(response.resultData));
    yield put(loaderAction(false));
  } catch (err) {
  }
}

export function* getCms1500ByClaimIdSaga() {
  yield takeLatest(TYPES.GET_CMS_1500_BY_CLAIM_ID, getCms1500ByClaimIdWorker);
}

function* getCms1500ByClaimIdWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response:any = yield billingService.getCms1500ByClaimId(param['payload']);
    yield put(saveCms1500ByClaimId(response.resultData));
    yield put(loaderAction(false));
  } catch (err) {
  }
}

export function* getCms1500ForBatchSaga() {
  yield takeLatest(TYPES.GET_CMS_1500_FOR_BATCH, getCms1500ForBatchWorker);
}

function* getCms1500ForBatchWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response:any = yield billingService.getCms1500ForBatch(param['payload']);
    yield put(saveCms1500ForBatch(response.resultData));
    yield put(loaderAction(false));
  } catch (err) {
  }
}


export function* get837ClaimByIdSaga() {
  yield takeLatest(TYPES.GET_837_CLAIM_BY_ID, get837ClaimByIdSagaWorker);
}

function* get837ClaimByIdSagaWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response:any = yield billingService.get837ClaimById(param['payload']);
    yield put(save837ClaimById(response.resultData));
    yield put(loaderAction(false));
  } catch (err) {
  }
}


export function* saveRemittanceDataSaga() {
  yield takeLatest(TYPES.SAVE_REMITTANCE_DATA, saveRemittanceDataSagasWorker)
}
function* saveRemittanceDataSagasWorker(param: any) {
  try {
      yield put(loaderAction(false));
      yield put(refreshBillingClaim(false));
      const response: any = yield billingService.saveRemittanceDataSaga(param['payload'])
      if (response.statusCode === 200) {
          NotificationManager.success(NOTIFICATION_MESSAGE.UPDATE_ICN);
          yield put(refreshBillingClaim(true));
      }
      yield put(loaderAction(false));
  }
  catch (err) {
      yield put(loaderAction(false));

  }
}

export function* getFullRemittanceByIdSaga() {
  yield takeLatest(TYPES.GET_FULL_REMITTANCE_BY_ID, getFullRemittanceByIdWorker);
}

function* getFullRemittanceByIdWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response:any = yield billingService.getFullRemittanceById(param['payload']);
    yield put(saveFullRemittanceById(response.resultData));
    yield put(loaderAction(false));
  } catch (err) {
  }
}