export const styles = `
   body, h1, h2, h3, h4, h5, h6 {
        font-family:  'Manrope', sans-serif !important;
      }
      label {
        font-size: 16px !important;
      }
   .header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px; /* Adjust the height as needed */
    background-color: #F6F8FA;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000; /* Ensure the header is on top of other elements */
}
.footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 45px; /* Adjust the height as needed */
    background-color: #F6F8FA;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000; /* Ensure the footer is on top of other elements */
}
   .customCell
   {
    color:Black;
    font-size: 10px !important;
   }
   .customCell-light
   {
    color: #4a4a4b;
    margin-left: 2px;
    font-size: 10px !important;
   }
  .grid-template-pdf-head {
    margin-top: 1rem; /* equivalent to mt-4 */
  }
  
  .grid-template-pdf{
    padding: 20px 10px 20px 30px !important;
    }
  .client-name {
    color: #000;
    font-size: 11px;
    margin-left: 2px;
  }
  
  .client-name-head {
    color: #000;
    font-size: 22px !important;
    margin-left: 2px;
  }

    .f-14 {
  font-size: 14px !important;
  font-weight:500;
  }
  .margin-L{
  margin-left: 10px !important; 
  }

  
  .client-name-bdss {
    color: #000;
    font-size: 12px !important;
    margin-left: 2px;
  }
  
  .list-unstyled {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
  }
  
    .k-pdf-export select{
  max-height:10px !important;
  border:1px solid #ced4da;
  font-size:12px !important;
  margin-bottom:16px !important;
  padding-bottom:10px !important;
  width:80%;

  
  }
  .q-mb-70{
    
        margin-bottom: 70px !important;

  }

  .additional-info {
    padding-top: 5px;
  }
  
  .client-detail-item {
    color: #000;
  }
  
  .client-detail-label {
    font-size: 10px;
    font-weight: 600;
  }
  
  .client-detail-value {
    color: #4a4a4b;
    margin-left: 2px;
  }

  input[type="radio"] {
    accent-color: #6c757d !important;
  }

  .submitButon {
    background-color: #6c757d !important;
    border: 1px solid;
    font-size: 14px;
    font-weight: 500;
    color:rgb(255, 255, 255) !important;
    border: 1px solid #6c757d !important;
    padding: 6px 12px;
    border-radius: 5px;
    margin: 0 0 10px 10px;
  }

  .head-3 {
  font-size: 26px !important;
  font-weight: 500;
  line-height: 1.5;
  }
  
  .head-6 {
  font-size: 24px !important;
  font-weight: 500;
  line-height: 1.5;
  }
`;
