/**
 * App.js Layout Start Here
 */

import { AutoComplete } from "@progress/kendo-react-dropdowns";
import {
    SELECTED_CLIENT_ID,
    IS_GLOBAL_SEARCH,
    GET_CLIENT_DETAILS, GET_CLIENT_PROFILE_IMG, GET_CLIENT_INSURANCE, GET_CLIENT_FLAGS, GET_CLIENT_PRIMARY_DX
} from "../../redux/actions/types";

import { useDispatch} from "react-redux";
import { ClientService } from "../../services/clientService";
import { renderErrors } from "src/helper/error-message-helper";
import APP_ROUTES from "src/helper/app-routes";
import { useNavigate } from "react-router";

function GlobalSearch({ data, textField, onChange, label, name,
    dataItemKey, validityStyles, required, value, itemRender, filterable, onFilterChange, placeholder, isSelectedDDL, isGlobalSearchReducer,
    setSearchValue, setIsSelectedDDL,primaryPath,newPath,routeClient
}) {
    const dispatch = useDispatch();

  const navigate = useNavigate();
    const getClientDetail = async (element) => {
        await ClientService.getClientDetail(element)
            .then((result) => {
                dispatch({
                    type: GET_CLIENT_DETAILS,
                    payload: result.resultData,
                });
                getClientProfileImg(element);

            })
            .catch((error) => {
                renderErrors(error.message);
            });
    };

    const getCurrentInsurance = async (element) => {
        await ClientService.getClientCurrentInsurance(element)
            .then((result) => {
                let insuranceList = result.resultData;
                dispatch({
                    type: GET_CLIENT_INSURANCE,
                    payload: insuranceList,
                });
            })
            .catch((error) => {
                renderErrors(error.message);
            });
    };

    const getClientProfileImg = async (element) => {
        await ClientService.getClientProfileImg(element)
            .then((result) => {
                dispatch({
                    type: GET_CLIENT_PROFILE_IMG,
                    payload: result.resultData,
                });
            })
            .catch((error) => {
                renderErrors(error.message);
            });
    };
    
    const getClientDiagnose = async (sibId) => {
        await ClientService.getClientDiagnose(sibId )
          .then((result) => {
            let list = result.resultData;
            dispatch({
              type: GET_CLIENT_PRIMARY_DX,
              payload: result.resultData,
            });
          })
          .catch((error) => {
            renderErrors(error.message);
          });
      };
    
    const getClientTags = async (element) => {
        dispatch({
            type: GET_CLIENT_FLAGS,
            payload: [],
          });
        await ClientService.getClientFlags(element)
            .then((result) => {
                let list = result.resultData;
                dispatch({
                  type: GET_CLIENT_FLAGS,
                  payload: list,
                });
            })
            .catch((error) => {
                renderErrors(error.message);
            });
    };

    let element;
    if (data.length === 1) {
        for (var i = 0; i < data.length; i++) {
            element = data[i].clientId;
        }
        if (isSelectedDDL) {
           
            getClientDetail(element)
            getCurrentInsurance(element)
            getClientTags(element)
            getClientDiagnose(element)
            dispatch({
                type: SELECTED_CLIENT_ID,
                payload: element,
            });
            dispatch({
                type: IS_GLOBAL_SEARCH,
                payload: true,
            });
            if (primaryPath == "client") {
                if (routeClient) {
                  // navigate(APP_ROUTES.CLIENT_DASHBOARD);
                } else {
                  navigate(APP_ROUTES.CLIENT_DASHBOARD);
                }
              } else {
                navigate(APP_ROUTES.CLIENT_DASHBOARD);
              }
              if (newPath === APP_ROUTES.GET_CLIENT) {
                navigate(APP_ROUTES.CLIENT_DASHBOARD);
              }
            setIsSelectedDDL(false)


        }
    }

    return (
        <AutoComplete
            className='search_input_bar'
            label={label}
            onChange={onChange}
            data={data}
            textField={textField}
            value={value}
            name={name}
            validityStyles={validityStyles}
            required={required}
            dataItemKey={dataItemKey}
            itemRender={itemRender}
            filterable={filterable}
            onFilterChange={onFilterChange}
            dataId={element}
            placeholder={placeholder}
           
        />
    );
}
export default GlobalSearch;



