/**
 * App.js Layout Start Here
 */
import React, { useState } from 'react';
import { Error } from '@progress/kendo-react-labels';
import { MultiSelect, MultiSelectChangeEvent, MultiSelectOpenEvent } from '@progress/kendo-react-dropdowns';
import { filterBy } from "@progress/kendo-data-query";
// import { RefreshButton } from 'src/app-modules/global-components/refresh-button';



export interface MultiSelectDropDownProps {
    placeholder?: string,
    disabled?: boolean,
    autoClose?: boolean,
    data: any[],
    ref?: any,
    textField: string,
    error?, name,
    dataItemKey?,
    validityStyles?,
    required?,
    value?,
    itemRender?,
    tags?,
    onChange?: (newValue: MultiSelectChangeEvent) => void,
    label?: string,
    onOpen?: (value: MultiSelectOpenEvent) => void
    // onRefetchData?: () => void
    // canReFetch?: boolean
    loading?: boolean
    showExtraPadding?: boolean
}


function MultiSelectDropDown(props: MultiSelectDropDownProps) {
    const {
        data,
        textField,
        onChange,
        label,
        error,
        name,
        dataItemKey,
        validityStyles,
        required,
        value,
        itemRender,
        placeholder,
        disabled,
        autoClose, tags, ref,
        // onRefetchData,
        // canReFetch,
        onOpen,
        loading,
        showExtraPadding
    } = props

    const [filter, setFilter] = useState(data?.slice());


    const onFilterChange = (event) => {
        setFilter(filterBy(data?.slice(), event.filter))
    }


    return (
        <div>
            <label style={{ textAlign: "left", display: "block", marginBottom: "8px" }}>{label}</label>
            <MultiSelect
                loading={loading}
                label={""}
                onChange={onChange}
                data={filter?.length > 0 ? filter : data}
                textField={textField}
                value={value}
                name={name}
                validityStyles={validityStyles}
                required={required}
                dataItemKey={dataItemKey}
                itemRender={itemRender}
                filterable={true}
                onFilterChange={onFilterChange}
                placeholder={placeholder}
                disabled={disabled}
                autoClose={autoClose}
                tags={tags && tags}
                ref={ref}
                popupSettings={{ height: "200px" }}
                onOpen={onOpen} />

            {/* (canReFetch && !loading) &&
                      <div className={showExtraPadding?"position-absolute mr-n4 staffDoc-refresh-button-single-selection":"position-absolute mr-n2 staffDoc-refresh-button"}>
                      <RefreshButton
                          onRefresh={onRefetchData }
                      />
                  </div> */
            }

            {
                error &&
                <Error >
                    {error}
                </Error>
            }
        </div>


    );

}
export default MultiSelectDropDown;



