import { Dialog } from '@progress/kendo-react-dialogs'
import React, { ComponentState, useEffect, useState } from 'react'
import DropDownKendoRct from 'src/control-components/drop-down/drop-down'
import MultiSelectDropDown from 'src/control-components/multi-select-drop-down/multi-select-drop-down'
import { CommonService } from 'src/services/commonService'
import { renderErrors } from "src/helper/error-message-helper";
import { useDispatch, useSelector } from 'react-redux'
import { getBillingAction } from 'src/redux/actions'
import { NotificationManager } from "react-notifications";
import NOTIFICATION_MESSAGE from 'src/helper/notification-messages';
import ErrorHelper from "../../../helper/error-helper";
import moment from 'moment'
import DatePickerKendoRct from 'src/control-components/date-picker/date-picker'
import calender from "src/assets/images/calendar-03.png"
import { Error } from '@progress/kendo-react-labels'
import TextAreaKendoRct from "./../../../control-components/kendo-text-area/kendo-text-area";

const DocumentPayrollModel = ({ onClose, checkedDocuments, documentList, fetchDocuments ,page,pageSize,isActiveCheck ,setCheckedDocuments, setAllSelected}) => {
    const state: ComponentState = useSelector(states => {
        return states;
    });

    const [settingError, setSettingError] = useState<boolean>(false);
    const [errors, setErrors] = useState<any>("");
    const [field, setField] = useState<any>({
        documentId: [],
        payrollDate: undefined,
        isPaid: undefined,
        payrollComments : ''
    })



    useEffect(() => {
        setField({
            ...field,
            documentId: checkedDocuments,
        })
    }, [])


    
    const handleChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        setField({
            ...field,
            [name]: value,
        });
    };

    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;
        if ((field.isPaid === 'YES' || field.isPaid === 'RO') && !field.payrollDate) {
            formIsValid = false;
            errors["payrollDate"] = ErrorHelper.PAYROLL_SELECTED_DATE;
        }
        if (field.isPaid === undefined) {
            formIsValid = false;
            errors["isPaid"] = ErrorHelper.PAYROLL_BILL_STATUS;
        }
        if (field.documentId.length <= 0) {
            formIsValid = false;
            errors["documentId"] = ErrorHelper.DOCUMENT_ID
        }
        // if (field.payrollComments !== null && field.payrollComments.trim() === '') {
        //     formIsValid = false;
        //     errors["payrollComments"] = ErrorHelper.PAYROLL_COMMENT_INVALID;
        // }
        setErrors(errors);
        return formIsValid;
    };

    const handleSubmit = () => {
        setSettingError(true);
        if (handleValidation()) {
            CommonService.updatePayRollStatus(field.payrollDate, field.documentId, field.isPaid,field.payrollComments)
                .then((result) => {
                    NotificationManager.success(NOTIFICATION_MESSAGE.SUBMIT_PAYROLL_ACTION);
                    onClose()
                    fetchDocuments(pageSize,page,isActiveCheck)
                    setCheckedDocuments([]);
                    setAllSelected(false);
                })
                .catch((error) => {
                    renderErrors(error.message);
                });
        }
    }

    return (
        <div>
            <Dialog onClose={onClose} title={"Set Payroll Status"} className="small-dailog flag width_90 SmallHEight blue_theme" height="80%">
                <div className="edit-client-popup">
                    <label>
                        Select Payroll Status
                    </label>
                    <div className="popup-modal slibling-data">
                        <div className="row ">

                            <div className="col-lg-12 col-md-12 select_table_phq">
                                <div className="cats_label">
                                    <label>
                                        <input
                                            type="radio"
                                            name={`radio_${0}`}
                                            value="yes"
                                            checked={field.isPaid === 'YES'}
                                            onChange={() => setField({ ...field, isPaid: 'YES' })}
                                            className="mr-2 mb-0"
                                        />
                                        Yes
                                    </label>
                                </div>
                                <div className="cats_label">
                                    <label>
                                        <input
                                            type="radio"
                                            name={`radio_${2}`}
                                            value="no"
                                            checked={field.isPaid === 'NO'}
                                            onChange={() => setField({ ...field, isPaid: 'NO' })}
                                            className="mr-2 mb-0"
                                        />
                                        No
                                    </label>
                                </div>
                                <div className="cats_label">
                                    <label>
                                        <input
                                            type="radio"
                                            name={`radio_${3}`}
                                            value="ro"
                                            checked={field.isPaid === 'RO'}
                                            onChange={() => setField({ ...field, isPaid: 'RO' })}
                                            className="mr-2 mb-0"
                                        />
                                        Rollover
                                    </label>
                                </div>
                                <div className="cats_label">
                                    <label>
                                        <input
                                            type="radio"
                                            name={`radio_${4}`}
                                            value="nr"
                                            checked={field.isPaid === 'NR'}
                                            onChange={() => setField({ ...field, isPaid: 'NR' })}
                                            className="mr-2 mb-0"
                                        />
                                        Not Reimbursable
                                    </label>
                                </div>
                            </div>
                        </div>
                        {(field.isPaid === undefined && errors.isPaid) &&
                            <Error>{errors.isPaid}</Error>
                        }

                        
                        {(field.isPaid === 'YES' || field.isPaid === 'RO') && <div >
                            <div className="position-relative">
                                <DatePickerKendoRct
                                    value={(field.payrollDate && field.payrollDate != null) && field.payrollDate}
                                    onChange={handleChange}
                                    name="payrollDate"
                                    label="Payroll Paid Date"
                                    error={!field.payrollDate && errors.payrollDate}
                                    required={true}
                                />
                                <img
                                    src={calender}
                                    alt=""
                                    className="position-absolute py-2"
                                    style={{ top: "30px", right: "19px" }}
                                />
                            </div>
                        </div>}
                    </div>

                </div>
                <div className='px-3'>
                        <TextAreaKendoRct
                            txtValue={field.payrollComments}
                            onChange={handleChange}
                            name="payrollComments"
                            placeholder="Comment"
                            label="Comment"
                            error={errors.payrollComments}
                        />
                    
                    </div>
                <div className="show-height-common client-card-scroll">
                    <ul>
                        {checkedDocuments.map((item) => (
                            <>
                                <li key={item.id}>{item?.documentId} - [{moment(item?.serviceDate).format("M/D/yyyy")} - {item?.clientName}]</li><br></br>

                            </>
                        ))}
                    </ul></div>
                   


                <div className="preview-btn-bottom ">
                    <div className="border-bottom-line"></div>
                    <div className="d-flex my-3 mr-3">
                        <div className="right-sde">
                            <button
                                className="submitButon"
                                onClick={handleSubmit}
                            >
                                Submit
                            </button>
                        </div>
                        <div className="right-sde-grey">
                            <button
                                className="cancelBtn"
                                onClick={onClose}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default DocumentPayrollModel