import React, { useEffect} from "react";
import { useSelector } from "react-redux";
import logoNotenetic from "../../assets/images/logo_notenetic.png";
import DEVELOPMENT_CONFIG from "./../../helper/config";

function Footer() {
   const staffLoginInfo = useSelector((state) => state?.getStaffReducer);
   const storageAppVersion= localStorage.getItem(DEVELOPMENT_CONFIG.APP_VERSION);
   const state = useSelector(states => {
    return states;
  });
   const { appIsImpersonate } = state['LoggedInReducer'];

useEffect(() => {
  if (!appIsImpersonate) {  
    const zendeskScript = document.createElement("script");
    zendeskScript.id = "ze-snippet";
    zendeskScript.src = "https://static.zdassets.com/ekr/snippet.js?key=6eb92e52-23a7-468d-8b9c-eaba5376d54d";
    document.body.appendChild(zendeskScript);
    // Additional script to configure the widget
    zendeskScript.onload = () => {
      window.zE("messenger:set", "conversationFields", [
        { id: "27279104111643", value: staffLoginInfo.userName },
        { id: "27284511723547", value: staffLoginInfo.email },
        { id: "27284128129819", value: staffLoginInfo.clinicName },
        { id: "27308904562075", value: staffLoginInfo.id },
        { id: "27286264395163", value: staffLoginInfo.roleName },
        { id: "27308861941659", value: staffLoginInfo.clinicId },
        { id: "27286365763739", value: staffLoginInfo.position },
      ])
    };

    return () => {
      document.body.removeChild(zendeskScript);
    };
  }
}, [appIsImpersonate, staffLoginInfo]);

  return (
    <div>
      <footer className='footer-main'>
      <div className="container">
        <h1 className="logo-link notenetic-text text-center pb_5">
          <img src={logoNotenetic} alt="Notenetic logo" />
        </h1>
     <div className="row ptb_40">
        <div className='col-lg-4 col-md-4 col-12'>
          <h4 className='address-title text-grey mb-2'><span className='f-24'>About Notenetic</span></h4>
          <p className = 'f-16 text-grey' > Notenetic is an entirely web - based solution created to streamline and manage your data and documentation. Notenetic is designed to be a user - friendly interface, providing you with secure and convenient access to your vital data and documents from anywhere with an internet connection. Discover a smarter way to organize, search, and manage your digital content with Notenetic.</p>
        </div>
        <div className='col-lg-2 col-md-6 col-12'>         
        <h4 className='address-title text-grey mb-2'><span className='f-24'>Quick Access</span></h4>
          <ul className='list-unstyled'>
             <li className='mb-2'><a href='/dashboard' className="text-grey f-16 text-decoration-none">Dashboard</a></li>
             <li className='mb-2'><a href='/document/list' className="text-grey f-16 text-decoration-none">Documents</a></li>
             <li className='mb-2'><a href='/client/list' className="text-grey f-16 text-decoration-none">Clients</a></li>
          </ul>
        </div>
     </div>
     <div className='border-bottom'></div>
     <div className='ptb_30'>
         <p className='text-center f-16 text-grey mb-0'> {`Notenetic All rights reserved Copyright`} {new Date().getFullYear()}</p>
         <p className='text-center f-16 text-grey mb-0'> {`Version ${storageAppVersion}`}</p>
     </div>
    </div>
    </footer>
   </div>
  );
 }

 export default Footer;