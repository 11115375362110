import {
  // LOADER, LOADER_DOC_LIST ,
 REPORT_LOADING, SAVE_BUTTON_PRESSED, SAVE_CLIENT_GENERAL_REPORT, SAVE_CLIENT_REPORT_EXPORT_BUTTON, SET_CLIENT_REPORT_FILTERS
} from "../actions/types";

const initialState = {
  clientFilters: {},
  loadingReport: false,
  clientGeneralReportData: [],
  buttonPressed: false,
  exportButtonPressed: false,
  // pullToRefreshIndicator: false,
  // footerIndicator: false,
  // loadingStateGrid: false,
  // loadingIndicatorState :false
};

export const ReportReducer = (state = initialState, action) => {
  const oldState = { ...state };
  const { type, payload } = action;
  switch (type) {
    case REPORT_LOADING:
      return {
        ...oldState,
        loadingReport: payload,
      };
    // case LOADER_DOC_LIST:
    //     return {
    //       ...oldState,
    //       loadingStateDocList: payload,
    //     };
    case SET_CLIENT_REPORT_FILTERS:
      return {
        ...oldState,
        clientFilters: payload,
      };

    case SAVE_CLIENT_GENERAL_REPORT:
      return {
        ...oldState,
        clientGeneralReportData: payload,
      };

    case SAVE_BUTTON_PRESSED:
      return {
        ...oldState,
        buttonPressed: payload,
      };

    case SAVE_CLIENT_REPORT_EXPORT_BUTTON:
      return {
        ...oldState,
        exportButtonPressed: payload,
      };


  }
  return oldState;
};
