import { API_ENDPOINTS } from "./api-endpoints";
import { customAxios } from "./useExPressApi";
import { ClientBillingClaimResponse, EDIResponse, ResponseBillingTransByClaimId ,DenialReasonResponse} from "src/dataModels/clientResponse";



export const billingService = {
  billingClaimServiceLine: (data: number) => {
    return customAxios.get<any, ClientBillingClaimResponse>(`${API_ENDPOINTS.FETCH_837_BILLING_TRANS_BY_CLAIM_ID}${data}`);
  },
  getBillingTransItemByClaimId: (claimId: any) => {
    return customAxios.get<any, ResponseBillingTransByClaimId>(`${API_ENDPOINTS.GET_BILLING_TRANS_ITEM_BY_CLAIM_ID}${claimId}`);
  },
  getEdiBybatchId: (id: any) => {
    return customAxios.get<EDIResponse>(`${API_ENDPOINTS.GET_EDI_BY_BATCH_ID}${id}&isRawText=${true}`);
  },
  billingTransQueueToExcel: (data:any) => {
    return customAxios.post<any,any>(`${API_ENDPOINTS.BILLING_TRANS_QUEUE_TO_EXCEL}`, data);
  },
  billingRemittanceViewToExcel: (data:any) => {
    return customAxios.post<any,any>(`${API_ENDPOINTS.BILLING_REMITTANCE_VIEW_TO_EXCEL}`, data);
  },
  billingRemittanceClaimViewToExcel: (data:any) => {
    return customAxios.post<any,any>(`${API_ENDPOINTS.BILLING_REMITTANCE_CLAIM_VIEW_TO_EXCEL}`, data);
  },
  getDenialReason: () => {
    return customAxios.get<any,DenialReasonResponse>(`${API_ENDPOINTS.GET_ADJ_REASON_CODES}`);
  },
  updateTransSrvLineBillingStatus: (data:any) => {
    return customAxios.post<any,any>(`${API_ENDPOINTS.UPDATE_TRANSSRVLINE_BILLING_STATUS}`, data);
  },
  getBillingStatus: () => {
    return customAxios.get<any,any>(`${API_ENDPOINTS.GET_BILLING_STATUSES}`);
  },
  getCms1500ByClaimId: (data) => {
    return customAxios.post<any,any>(`${API_ENDPOINTS.GET_CMS_1500_BY_CLAIM_ID}?claimId=${data.claimId}`);
  },
  getCms1500ForBatch: (data) => {
    return customAxios.post<any,any>(`${API_ENDPOINTS.GET_CMS_1500_FOR_BATCH}?transId=${data.transId}`);
  },
  get837ClaimById: (claimId:any) => {
    return customAxios.get<any,any>(`${API_ENDPOINTS.GET_837_CLAIM_BY_ID}?claimId=${claimId}`);
  },
  saveRemittanceDataSaga: (param:any) => {
    return customAxios.post<any,any>(`${API_ENDPOINTS.SAVE_REMITTANCE_DATA}`, param);
  },
  getFullRemittanceById: (id:any) => {
    return customAxios.get<any,any>(`${API_ENDPOINTS.GET_FULL_REMITTANCE_BY_ID}?id=${id}`);
  },
}
