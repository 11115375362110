import React, { useState, useEffect } from "react";
import Room from "./room";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { VideoService } from "../../services/videoCallService";
import Loading from "../loader/loader";
import { telehealthDecrption } from "../../app-modules/encrption";
import APP_ROUTES from "../../helper/app-routes";
import { renderErrors } from "src/helper/error-message-helper";
import ApiHelper from "../../helper/api-helper";
import { useDispatch } from "react-redux";
import { JOIN_URL } from "../../redux/actions/types";
import { API_ENDPOINTS } from "src/services/api-endpoints";
import { NotificationManager } from "react-notifications";

const Telehealth = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const clientDetails = useSelector((state) => state.clientDetails);
    const selectedClientId = useSelector((state) => state.selectedClientId);
    const [isConnecting, setConnecting] = useState(false)
    const [token, setToken] = useState(null);
    const [clientToken, setClientToken] = useState("")
    const [isRoomName, setRoomName] = useState()
    const [clientRoomName, setClientRoomName] = useState()
    const loggedInUserName = useSelector((state) => state.getStaffReducer?.userName);
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const [joinUrl, setJoinUrl] = useState()
    const { isSupported } = require('twilio-video');
    const [eventIds, setEventIds] = useState(null);

    const updateDimensions = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);


    useEffect(() => {
        const rawQueryString = location.search;
        const normalizedQueryString = rawQueryString.replace(/\+/g, '%2B');
        const searchParams = new URLSearchParams(normalizedQueryString);
        let isClient = searchParams.get("isClient")
        let userId = searchParams.get("userId")
        let eventId = searchParams.get("eventId")
        let roomId = searchParams.get("roomId")
        let userName = searchParams.get("userName")
        let firstClient=searchParams.get('firstClient')
        getClientAccessToken(userId, isClient, roomId, userName,firstClient)
        if (userId && eventId) {
          
            getAccessToken(eventId, userId, isClient, userName);
        }
    }, [])


    const getAccessToken = async (eventId, userId, isClient, uName) => {
        let deEventId = telehealthDecrption(eventId)
        let newUserName = uName ? uName : ""
        let deUserId = await telehealthDecrption(userId)
        setEventIds(deEventId)
        setConnecting(true)
        await VideoService.getTelehealthToken(deEventId, newUserName, deUserId ? deUserId : userId, false, isClient)
            .then((result) => {
                let data = result.resultData;
                setToken(data?.jwtToken)
                setRoomName(data?.room.roomId)
                if (data == null) {
                    renderErrors(result.message);
                    setConnecting(false)
                }
            })
            .catch((error) => {
                setConnecting(false)

            });
    }

    const getClientAccessToken = async (userId, isClient, roomId, userName,firstClientId) => {
        setConnecting(true)
        let clientId=''
        let uName=''
        if (firstClientId){
            clientId=  await telehealthDecrption(firstClientId)
            uName='Client'
        }else{
            clientId=selectedClientId
            uName=`${clientDetails?.fName} ${clientDetails?.lName}`
        }

        await VideoService.getClientTelehealthToken(userId, isClient, roomId, userName?userName:loggedInUserName?loggedInUserName:'')
            .then((result) => {
                let data = result.resultData;
                setClientToken(data?.jwtToken)
                setClientRoomName(data?.room.roomId)
                if (data?.room.roomId) {
                    const dataResult = {
                        clientId: clientId,
                        roomId: data?.room.roomId,
                        domainName: window.location.host.split(".")[0],
                        userName: uName
                    }
                    
                    ApiHelper.telehealthPostRequest(
                        API_ENDPOINTS.GET_TELEHEALTH_ACCESS_LINK_FOR_CLIENT, dataResult)
                        .then((result) => {
                            if (result?.resultData === null) {
                                navigate(APP_ROUTES.CLIENT_DASHBOARD)
                            }
                            setJoinUrl(result?.resultData?.joinUrl)
                            dispatch({
                                type: JOIN_URL,
                                payload: result?.resultData?.joinUrl,
                            });
                        })
                        .catch((error) => {
                            renderErrors(error.message);
                        })
                }
                else if (data == null) {
                    renderErrors(result.message);
                    setConnecting(false)
                }
            })
            .catch((error) => {
                setConnecting(false)
            });
    }

    const handleLogout = () => {
        setToken(null);
        setRoomName(null);
        if (!loggedInUserName) {
            navigate(-1);
        } else {
            window.location.replace(APP_ROUTES.SCHEDULER);
        }

    }


    const handleClientLogout = () => {
        if (!loggedInUserName) {
            navigate(-1);
        } else {
            VideoService.closeTelehealthSession(clientRoomName).then((result) => {
                if (result?.resultData?.status === true) {
                    setClientToken(null);
                    setClientRoomName(null)
                    window.location.replace(APP_ROUTES.CLIENT_DASHBOARD);
                    window.close()
                }
            })

        }

    }

    useEffect(() => {
        if (isSupported) {
           
        } else {
            NotificationManager.error("Your browser don't supports video calling at the moment. Please try on another device");
            navigate(-1);
        }
    }, [isSupported])

    return (
        <>
            {isConnecting && <Loading />}
            {
                (token && eventIds) && <Room
                    roomName={isRoomName}
                    token={token}
                    handleLogout={handleLogout}
                    createdBy={location.state?.eventInfo?.createdBy}
                    setConnecting={setConnecting}
                    joinUrl={joinUrl}
                    eventId={eventIds}
                />
            }

            {
                (clientToken && !eventIds) && <Room
                    roomName={clientRoomName}
                    token={clientToken}
                    handleLogout={handleClientLogout}
                    createdBy={location.state?.eventInfo?.createdBy}
                    setConnecting={setConnecting}
                    joinUrl={joinUrl}
                    clientDetails={clientDetails}
                />
            }
        </>
    )
}


export default Telehealth;