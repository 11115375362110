const DEVELOPMENT_CONFIG = {
  //base_url: "https://kxmbz6w1sc.execute-api.us-east-2.amazonaws.com/prod/api",
  // base_url: "https://zk5f9iji22.execute-api.us-east-2.amazonaws.com/Stage/api",
  //base_url: 'https://localhost:44384/api',
  // 'https://api.notenetic.com/api',
  base_url: process.env.REACT_APP_API_SERVER ? process.env.REACT_APP_API_SERVER : 'https://api.dev.notenetic.com/api',
  stripe_key:process.env.STRIPE_KEY,
  prod_base_url: 'https://api-prod.notenetic.com/api',
  qa_base_url: 'https://api.qa.notenetic.com/api',
  stripe_key_test:'pk_test_51N17tkJZ8IrveQFMfCgIgEdFPSeB6laZT555rwplonH0Im0CtE9D6pRS7kQB8358PhVijAyY1aCjRbZAivJBAoTX00ncYR8auM',
  stripe_key_publish:'pk_live_51N17tkJZ8IrveQFMEgQJqkxkMLeVm7ZrFyZWh2YQ3xOl7MH3ytYOHIguO9mkoZPU1SG5mdllrpOcs0UegECol3tx00vFBhW0Zx',
  statusCode: 200,
  unAuthorized: 401,
  noPermission: 403,
  internalServer: 500,
  internalServerError: 501,
  internalServerBadGateway: 502,
  TOKEN: "token",
  OTP_TOKEN: "otpToken",
  REFRESH_TOKEN: "refeshtoken",
  LOGIN_TIME: "loginTime",
  TOKEN_EXPIRETIME: "accessTokenExpireTime",
  REFRESHTOKEN_EXPIRETIME: "refeshTokenExpireTime",
  LAST_URL: "lasturl",
  IS_TEMP_PASSWORD: "isTemp",
  APP_VERSION: "appVersion",
  IS_IMPERSONATE: "false",
  STAFF_ID:"staffId",
  CLINIC_ID:"clinicId"
};
export default process.env.NODE_ENV === "development" ? DEVELOPMENT_CONFIG : DEVELOPMENT_CONFIG;

