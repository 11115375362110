import { takeLatest, put } from 'redux-saga/effects';
import * as TYPES from '../actions/types';
import { AppSuccessResponse } from 'src/dataModels/apiSuccessResponse';
import { saveCustomerSubscriptions, saveCustomerPaymentMethods, saveCustomerInvoices, subsCriptionsLoading, getCustomerPaymentMethods, saveStripeCustomer } from '../actions';
import { customAxios } from 'src/services/useExPressApi';
import { API_ENDPOINTS } from 'src/services/api-endpoints';



export function* getClientSubsCriptionsSaga() {
    yield takeLatest(TYPES.GET_CUSTOMER_SUBSCRIPTIONS, subscriptionsWorker);
}

function* subscriptionsWorker(param: any) {
    try {
        const token = param['payload']
        let response: AppSuccessResponse;
        if (token) {
            response = yield customAxios.get(`${API_ENDPOINTS.GET_CUSTOMER_SUBSCRIPTIONS}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
            })
        } else {
            response = yield customAxios.get<any, AppSuccessResponse>(`${API_ENDPOINTS.GET_CUSTOMER_SUBSCRIPTIONS}`);
        }

        yield put(subsCriptionsLoading(['subscription', 1]))
        if (response?.resultData) {
            yield put(saveCustomerSubscriptions(response?.resultData))
        }
        yield put(subsCriptionsLoading(['subscription', 0]))
    } catch (err) {
        console.log(err)
    }
}


export function* getPaymentMethodsSaga() {
    yield takeLatest(TYPES.GET_CUSTOMER_PAYMENTS_METHODS, payMethodWorker);
}

function* payMethodWorker(param: any) {
    try {
        yield put(subsCriptionsLoading(['pay-method', 1]))
        const token = param['payload']
        let response: AppSuccessResponse;
        if (token) {
            response = yield customAxios.get(`${API_ENDPOINTS.GET_CUSTOMER_PAYMENT_METHOD}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
            })
        } else {
            response = yield customAxios.get<any, AppSuccessResponse>(`${API_ENDPOINTS.GET_CUSTOMER_PAYMENT_METHOD}`);
        }
        if (response.resultData) {
            yield put(saveCustomerPaymentMethods(response.resultData))
        }
        yield put(subsCriptionsLoading(['pay-method', 0]))
    } catch (err) {
    }
}


export function* getCustomerInvoicesSaga() {
    yield takeLatest(TYPES.GET_SUBSCRIPTIONS_INVOICES, invoiceWorker);
}

function* invoiceWorker(param: any) {
    try {
        yield put(subsCriptionsLoading(['invoices', 1]))
        const token = param['payload']
        let response: AppSuccessResponse;
        if (token) {
            response = yield customAxios.get(`${API_ENDPOINTS.GET_CUSTOMER_INVOICES}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
            })
        } else {
            response = yield customAxios.get<any, AppSuccessResponse>(`${API_ENDPOINTS.GET_CUSTOMER_INVOICES}`);
        }
        if (response.resultData) {
            yield put(saveCustomerInvoices(response.resultData));
        }
        yield put(subsCriptionsLoading(['invoices', 0]))

    } catch (err) {
    }
}

export function* attachPaymentMethodSaga() {
    yield takeLatest(TYPES.ATTACH_SUBSCRIPTION_PAY_METHOD, attachWorker);
}

function* attachWorker(param: any) {
    try {
        yield put(subsCriptionsLoading(['pay-method', 1]))


        const token = param['payload']['token']
        let response: AppSuccessResponse;
        if (token) {
            response = yield customAxios.post(`${API_ENDPOINTS.ATTACH_PAYMENT_METHOD}${param['payload']['id']}`, null, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
            })
        } else {
            response = yield customAxios.post<any, AppSuccessResponse>(`${API_ENDPOINTS.ATTACH_PAYMENT_METHOD}${param['payload']['id']}`);
        }

        if (response.resultData) {
            yield put(getCustomerPaymentMethods(token));
        }
        yield put(subsCriptionsLoading(['pay-method', 0]))

    } catch (err) {
    }
}

export function* getStripeCustomerSaga() {
    yield takeLatest(TYPES.GET_STRIPE_CUSTOMER, customerWorker);
}

function* customerWorker(param: any) {
    try {
        const token = param['payload']
        let response: AppSuccessResponse;
        if (token) {
            response = yield customAxios.get(`${API_ENDPOINTS.GET_CUSTOMER}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
            })
        } else {
            response = yield customAxios.get<any, AppSuccessResponse>(`${API_ENDPOINTS.GET_CUSTOMER}`);
        }
        if (response.resultData) {
            yield put(saveStripeCustomer(response.resultData));
        }
    } catch (err) {
    }
}

export function* updateDefaultMethodSaga() {
    yield takeLatest(TYPES.UPDATE_DEFAULT_PAYMENT_METHOD, updateWorker);
}

function* updateWorker(param: any) {
    try {
        const token = param['payload']['token']
        let response: AppSuccessResponse;
        if (token) {
            response = yield customAxios.post(`${API_ENDPOINTS.UPDATE_DEFAULT_METHOD}${param['payload']['payId']}`,
                null, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
            })
        } else {
            response = yield customAxios.post<any, AppSuccessResponse>(`${API_ENDPOINTS.UPDATE_DEFAULT_METHOD}${param['payload']['payId']}`);
        }

        if (response.resultData) {
            yield put(saveStripeCustomer({ ...param['payload']['customer'], defaultPaymentMethodId: param['payload']['payId'] }));
        }
    } catch (err) {
    }
}