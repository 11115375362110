import React, { ComponentState, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getClientGeneralReport, getReportColumns, getStaffReportColumnPreferences, saveButtonPressed, saveClientGeneralReport, saveExportButtonPressed, setClientReportFilters } from "src/redux/actions";
import ClientReportFilters from "./report-filtrs";
import { TableRowsLoader } from "src/control-components/custom-skelton";
import moment from "moment";
import { groupBy, GroupDescriptor, GroupResult } from '@progress/kendo-data-query';
import { setExpandedState, setGroupIds } from '@progress/kendo-react-data-tools';
import {  Grid, GridColumn,GridExpandChangeEvent, GridGroupChangeEvent } from '@progress/kendo-react-grid';
import { ExcelExport } from "@progress/kendo-react-excel-export";



const initialGroup: GroupDescriptor[] = [];

const processWithGroups = (data: any[], group: GroupDescriptor[]) => {
    const newDataState = groupBy(data, group);
    setGroupIds({ data: newDataState, group: group });
    return newDataState;
};

function Tab1({ selectedReport }: { selectedReport: number }) {
  const { staffReportColumnPrefResp, staffReportWithTypeResp } = useSelector(
    (state: any) => state.StaffReducer
  );
  const [itemsToShow, setShowMore] = useState(3);
  const state: ComponentState = useSelector((states) => { return states; });
  const [activeColumns, setActiveColumns] = useState([]);
  const staffId = useSelector((state: any) => state.loggedIn?.staffId);
  const { clientFilters, loadingReport, clientGeneralReportData, buttonPressed,exportButtonPressed } = state["ReportReducer"];
  const dispatch = useDispatch();
  const [page, setPage] = React.useState<number>(0);
  const [pageSize, setPageSize] = React.useState<number>(500);
  const [isICDData, setICDData] = useState([]);
  const [clientReportData, setClientReportData] = useState([]);
  const [group, setGroup] = React.useState(initialGroup);

  const [isStateSte,setIsStateSet]=useState(false)
  useEffect(()=>{
    if(!isStateSte){
      dispatch(saveClientGeneralReport())
      setIsStateSet(true)
    }
  },[isStateSte])

  const [collapsedState, setCollapsedState] = React.useState<string[]>([]);
  const [metaData, setMetaData] = useState({
    "pageNo": 0,
    "pageSize": 500,
    "totalCount": 0,
    "totalPages": 0
  });

  const initialExpanded = isICDData.reduce(
    (acc, cur: any) => ({ ...acc, [cur.id]: true }),
    {}
  );
  const [expandedModule, setExpandedModule] = React.useState({
    ...initialExpanded,
  });
  const showMoreList = (id) => {
    setExpandedModule({ ...expandedModule, [id]: !expandedModule[id] });
  };

  useEffect(() => {
    if (staffReportWithTypeResp.length > 0 && selectedReport) {
      const data = {
        reportId: selectedReport,
        staffId: staffId
      }
      const data1 = {
        reportId: selectedReport
      }
     
      dispatch(getReportColumns(data1))
      dispatch(getStaffReportColumnPreferences(data))
    }
    dispatch(saveClientGeneralReport())
  }, [staffReportWithTypeResp])

  useEffect(() => {
    if (buttonPressed && clientFilters) {
      setPage(0)
      dispatch(saveClientGeneralReport())
      dispatch(getClientGeneralReport({ ...clientFilters, pageNumber: 1, pageSize: pageSize }))
      dispatch(saveButtonPressed(false))
      setGroup([])
    }
  }, [clientFilters, buttonPressed])


  useEffect(() => {
    if (staffReportColumnPrefResp.length > 0) {
      setActiveColumns(staffReportColumnPrefResp.filter((item: any) =>
        item.isActive === true
      ))
    }
    else {
      setActiveColumns([]);
    }
  }, [staffReportColumnPrefResp])



  useEffect(()=>{
    if(exportButtonPressed){
      excelExport()
      dispatch(saveExportButtonPressed(false))
    }
  },[exportButtonPressed])




  useEffect(() => {
    if (clientGeneralReportData && clientGeneralReportData.metaData && isStateSte) {
      setMetaData(clientGeneralReportData.metaData)

      const updatedData = clientGeneralReportData?.resultData?.map((item) =>
      {
       
          for (let key in item) {
            if (Array.isArray(item[key])) {
              item[key]= Array.prototype.map.call(item[key], function(item) { return item.name; }).join(", ")
            }
          }
          return item;
        });
        
        // return  Array.isArray(item) ? { ...item, name: newName } : item
      
     
    

      setClientReportData(updatedData)
      setResultState(clientGeneralReportData?.resultData);
    }

  }, [loadingReport, clientGeneralReportData])


  const pageChange = (event) => {
    setGroup([])
    let skip = event.page.skip;
    let take = event.page.take;
    setPage(skip);
    setPageSize(take);
    let newValue = skip / take;
    let finalValue = newValue + 1;
    window.scrollTo(0, 0);
    dispatch(getClientGeneralReport({ ...clientFilters, pageNumber: finalValue, pageSize: take}))
  };

  const dataStateChange = (event) => {
    setPage(event.dataState.skip);
    setPageSize(event.dataState.take);
    // setSort(event.dataState.sort);
  };

  const [resultState, setResultState] = React.useState<any[] | GroupResult[]>(
      processWithGroups(clientReportData, initialGroup)
  );

  const onGroupChange = React.useCallback((event: GridGroupChangeEvent,reportData:any) => {
   
      const newGroups = event.group;
      const areNewGroupsUnique = !newGroups.some(
          (item, index) => newGroups.findIndex((group) => group.field === item.field) !== index
      );
     
      if (areNewGroupsUnique) {
          const newDataState = processWithGroups(reportData, event.group);
          setGroup(event.group);
          setResultState(newDataState);
      }
  }, []);

  const onExpandChange = React.useCallback(
      (event: GridExpandChangeEvent) => {
          const item = event.dataItem;

          if (item.groupId) {
              const newCollapsedIds = !event.value
                  ? [...collapsedState, item.groupId]
                  : collapsedState.filter((groupId) => groupId !== item.groupId);
              setCollapsedState(newCollapsedIds);
          }
      },
      [collapsedState]
  );

  const newData = isStateSte?setExpandedState({
      data: resultState,
      collapsedIds: collapsedState
  }):[];

  const _export = React.useRef<ExcelExport | null>(null);
  const excelExport = () => {
      if (_export.current !== null) {
          _export.current.save();
      }
  };

  const DateCell = (props) => {
    return (
        <td
            {...props.tdProps}
            colSpan={1}>
            {moment(props.children).format('M/D/YYYY')}
        </td>
    );
};


const CustomCell2 = (props) => {   
  const dataStr= Array.prototype.map.call(props.dataItem[props.field], function(item) { return item.name; }).join(", "); // "1,2,3"

  return (
      <td
          {...props.tdProps}
          colSpan={1}>

          {dataStr}
      </td>
  );
};

  
  return (
    <>
      {selectedReport ? 
        <>
      <div>
        <ClientReportFilters 
        selectedReport = {selectedReport}
        canExport ={clientReportData.length>0}
        ></ClientReportFilters>
      </div>
      <div className="grid-table blueThemeTable filter-grid ml-2 cursor-default">
        {loadingReport ? <TableRowsLoader rowsNum={12} />
          : (activeColumns.length > 0) &&
          <ExcelExport data={newData} ref={_export} group={group} fileName="Client-Report.xlsx">
          <Grid
            style={{
              height: "100%",
            }}
            data={newData}
            total={
              metaData && metaData?.totalCount > 0
                ? metaData?.totalCount
                : 0
            }
            skip={page}
            take={pageSize}
            // total={metaData.totalCount}
            onPageChange={pageChange}
            className="pagination-row-cus"
            pageable={{
              pageSizes: [500, 750, 1000],
            }}
            onDataStateChange={dataStateChange}
            groupable={true}
            onGroupChange={(event)=>onGroupChange(event,clientReportData)}
            group={group}
            onExpandChange={onExpandChange}
            expandField="expanded"
          >
            {
              activeColumns.map((col: any) => {

               if (col.dataType === 'array') {
                  return   <GridColumn
                  title={col.uiColumnName}
                  width={'150px'}
                  field={col.columnKey}
                  
                  
                />
                
                  
                /*   <GridColumn
                    title={col.uiColumnName}
                    field={col.columnKey}
                    width="180"
                    cells={{ data: CustomCell2 }}
                    cell={(props) => {
                      let field = props.dataItem[col.columnKey];
                      if (field===' '||!field || field.length < 0) {
                        return <></>
                      }
                      let array = field?.slice(
                        0,
                        expandedModule[props.dataItem.id]
                          ? field.length
                          : itemsToShow
                      );

                      return (
                        <td>
                          <ul>
                            {array?.length > 0 &&
                              array?.map((obj, i) => (
                                <>
                                  <li className="mb-0" key={i}>
                                    {obj.name}
                                    {field.length > 3 &&
                                      array?.at(-1).name === obj?.name && (
                                        <span
                                          className="plus-click  ml-2 cursor-pointer"
                                          onClick={() => {
                                            showMoreList(props.dataItem.id);
                                          }}
                                        >
                                          {expandedModule[props.dataItem.id] ? (
                                            <i className="fa fa-minus "></i>
                                          ) : (
                                            <i className="fa fa-plus"></i>
                                          )}
                                        </span>
                                      )}
                                  </li>
                                </>
                              ))}
                          </ul>
                        </td>
                      );
                    }} 
                  />*/
                }

                else if(col.dataType === 'date') {
                  return (
                    <GridColumn
                    title={col.uiColumnName}
                    width={'150px'}
                    field={col.columnKey}
                    filter="date"
                    // format="MM/YYYY/DD"
                    cells={{ data: DateCell }}
                  />
                  
                  );
                }

                else  {
                  
                  return (
                    <GridColumn
                      title={col.uiColumnName}
                      width={col.columnKey==='email'?"190px":'150px'}
                      field={col.columnKey}
                    />
                  );
                }

              })
            }
          </Grid></ExcelExport>
        }
      </div> 
      </> : 'No Report found'}
    </> 
  );
}

export default Tab1;
