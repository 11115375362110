import React, { ComponentState, useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import MultiSelectDropDown from "../../control-components/multi-select-drop-down/multi-select-drop-down";
import apiHelper from '../../helper/api-helper';
import { showError } from '../../util/utility';
import { useSelector, useDispatch } from "react-redux";
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { Switch } from "@progress/kendo-react-inputs";
import { renderErrors } from "src/helper/error-message-helper";
import DropDownKendoRct from "../../control-components/drop-down/drop-down";
import { getDocumentTemplateTypes, referralProviderDelete, saveDocumentSearchFilter } from "src/redux/actions";
import { DropDownObjectWithNumber,DropDownObjectWithString } from "src/dataModels/docListModel";
import { userIsSuperAdmin } from "src/helper/permission-helper";
import { SettingsService } from "../../services/settingsService";
import { ClientService } from "src/services/clientService";
import { MultiSelectTemplateDropDownComponent } from "../global-components/template-dropdown/multi-select-template-dropdown";
import { API_ENDPOINTS } from "src/services/api-endpoints";
import { billingService } from "src/services/billingService";
import { DenialReasonData} from "src/dataModels/clientResponse";
import { Tooltip } from "@progress/kendo-react-tooltip";

const ageList = [
  { id: '1', name: 'Under 18 (0 - 18)' },
  { id: '2', name: 'Over 18 (18 or above)' }
];

const lockedArray: DropDownObjectWithNumber[] = [
    {
        id: -1,
        name: "All"
    },
    {
        id: 0,
        name: "Unlocked"
    },
    {
        id: 1,
        name: "Locked"
    }
]

export const payrollArr: DropDownObjectWithString[] = [
    {
        id: "",
        name: "All"
    },
    {
        id: 'YES',
        name: "Yes"
    },
    {
        id: 'NO',
        name: "No"
    },
    {
        id: 'RO',
        name: "Rollover"
    },
    {
        id: "NR",
        name: "Not Reimbursable"
    }
]

export const signaturesData: any = [
    {
        name: "Author Signed ", value: 3,
    },
    {
        name: "Client Signed", value: 1,
    },
    {
        name: "Multi Signed  ", value: 4,
    },
    {
        name: "Reviewer Signed ", value: 2,
    },
    {
        name: "Not Author Signed ", value: 5,
    },








]


const SideFilter = ({ showFilter, handleShowFilter, advSearchFields, setAdvSearchFields, setPage, setPageSize
    , defaultPageSettings, docFilter, setIsActiveCheck, isActiveCheck, isPayrollPaid
}) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState({});
    const [documentStatus, setDocumentStatus] = useState([]);
    const [billStatus, setBillStatus] = useState([]);
    const [payerList, setPayerist] = useState([]);
    const [clinicFlagList, setClinicFlagList] = useState([]);
    const [clientStatus, setClientStatus] = useState<any>([]);
    const [denialReasonList, setDenialReasonList] = useState<DenialReasonData[]>([]);
    const [referringData, setReferringData] = useState([]);

    const clinicId = useSelector((state: { loggedIn: { clinicId: string } }) => state.loggedIn.clinicId);

    const staffLoginInfo = useSelector((state: ComponentState) => state?.getStaffReducer);
    const isHumanResourcesManager = useSelector((state: ComponentState) => state.getRolePermission.isHumanResourcesManager);
    const maxDescriptionLength = 100;

    const state: ComponentState = useSelector(states => {
        return states;
      });
    const { docTempTypes} = state['DocumentReducer'];

    const attachmentData = [
        { id: null, name: "All" },
        { id: 1, name: "Yes" },
        { id: 0, name: "No" }
    ];



    // --------------------------------------useEffect call------------------------------------------

    useEffect(() => {
        fetchDocumentStatus()
        fetchBillStatus()
        fetchPayers()
        fetachClinicFlagList()
        getClientStatus()
        getDenialReason()
        dispatch(getDocumentTemplateTypes())
        getClinicReferrings()
    }, [])


    // ---------------------------------Fetch dropdown list ------------------------------------


    const fetachClinicFlagList = async () => {
        await SettingsService.getClinicFlags().then((result) => {
            let list = result.resultData;
            setClinicFlagList(list);
        }).catch((error) => {
            renderErrors(error.message);
        });
    };
    const getClientStatus = async () => {
        await ClientService.getClientStatus()
            .then((result) => {
                let client = result.resultData;
                const sortedData = ([...client].sort((a, b) => a.statusName.localeCompare(b.statusName)));
                setClientStatus(sortedData);
            }).catch((error) => {
                renderErrors(error.message)
            });
    }

    function fetchDocumentStatus() {
        setLoading({ documentStatus: true });
        apiHelper
            .getRequest(API_ENDPOINTS.DOCUMENT_STATUS)
            .then((result) => {
                const list = result?.resultData?.map((r) => {
                    return { id: r.id, name: r.name };
                });
                setDocumentStatus(list);
            })
            .catch((err) => {
                showError(err, "Staff List");
            })
            .finally(() => {
                setLoading({ documentStatus: false });
            });
    }

    function fetchBillStatus() {
        setLoading({ billStatuss: true });
        apiHelper
            .getRequest(API_ENDPOINTS.GET_BILLING_STATUSES)
            .then((result) => {
                const list = result?.resultData?.map((r) => {
                    return { id: r.id, name: r.name };
                });
                setBillStatus(list);
            })
            .catch((err) => {
                showError(err, "Staff List");
            })
            .finally(() => {
                setLoading({ billStatus: false });
            });
    }

    function fetchPayers() {
        setLoading({ payers: true });
        apiHelper
            .getRequest(API_ENDPOINTS.GET_INSURANCE_TYPE)
            .then((result) => {
                const list = result.resultData.map((r) => {
                    return { id: r.id, name: r.insuranceName };
                });
                setPayerist(list);
            }).catch((error) => { renderErrors(error); });
    };

    const getDenialReason = async() => {
        await billingService.getDenialReason()
            .then((result) => {
                let data: DenialReasonData[] = result?.resultData;
                setDenialReasonList(data);
            }).catch((error) => {
                renderErrors(error.message)
            });
    }

    const handleChange = (e) => {
        const name = e.target.name;
        let value = e.target.value;

        const newAdvSearchFileds = {
            ...advSearchFields,
            [name]: value,
        }

        setAdvSearchFields(newAdvSearchFileds)
    }




    const handleApplyFilter = () => {
        if (advSearchFields?.documentId !== "" && advSearchFields.documentId === "0") {
            renderErrors("Document id couldn't be zero")
        } else {
            setPage(defaultPageSettings.page)
            setPageSize(defaultPageSettings.pageSize)
            dispatch(saveDocumentSearchFilter(advSearchFields))

        }
    }


    const handleClearFilter = useCallback(() => {
        setAdvSearchFields({
            ...advSearchFields,
            template: [],
            documentId: "",
            billStatus: [],
            denialReason: [],
            documentStatus: 0,
            lockedValue: 0,
            signatures: 0,
            isPayrollPaid: null,
            payer: [],
            flag: null,
            clientStatus: null,
            docTempType:[]
            ,hasAttachment: null,
            billingBatchId: null,
            refName: null
        })
        // dispatch({
        //     type: GET_DOCUMENT_FILTER,
        //     payload: {
        //         template: [],
        //         documentId: ""
        //     },
        // });
        setIsActiveCheck(false)
        setPage(defaultPageSettings.page)
        setPageSize(defaultPageSettings.pageSize)
        dispatch(saveDocumentSearchFilter({
            ...advSearchFields,
            template: [],
            documentId: "",
            billStatus: [],
            denialReason: [],
            documentStatus: 0,
            lockedValue: 0,
            isPayrollPaid: null,
            payer: [],
            signatures: null,
            flag: null,
            clientStatus: null,
            hasAttachment: null,
            billingBatchId: null,
            clientAge: null,
            refName: null

        }))
        // handleShowFilter()

    }, [])

    const handleTrashSwitch = (e) => {
        var changeVal = e.target.value;
        setIsActiveCheck(changeVal);
        const newAdvSearchFileds = {
            ...advSearchFields,
            showTrashNote: e.target.value,
        }
        setAdvSearchFields(newAdvSearchFileds)
    };

    function renderToItem(li, itemProps) {
        const itemChildren = (
            <span>
                <input
                    type="checkbox"
                    name={itemProps.dataItem}
                    checked={itemProps.selected}
                    onChange={(e) => itemProps.onClick(itemProps.index, e)}
                />
                &nbsp;{li.props.children}
            </span>
        );
        return React.cloneElement(li, li.props, itemChildren);
    }
    const itemRender = (li, itemProps) => {
        const itemObj = itemProps.dataItem;
        const itemChildren = (
            <>
                <span
                    className="k-scheduler-mark"
                    style={{
                        backgroundColor: itemObj.color,
                        marginRight: 4,
                    }}
                />
                <span>{itemObj.flagName}</span>
            </>
        );
        return React.cloneElement(li, li.props, itemChildren);
    };

    const customDescription = (description) => {
        if (description.length > maxDescriptionLength) {
            const truncated = description.substring(0, maxDescriptionLength) + "...";
            return (
                <>
                    {truncated}
                    <span>
                        <Tooltip anchorElement="target" position="right"><i style={{ cursor: 'pointer' }} title={description} className="fa fa-info-circle"></i></Tooltip>
                    </span>
                </>
            );
        }
        return description;
    };

  const getClinicReferrings = async () => {
    await SettingsService.getRefSource(clinicId, true)
      .then((result) => {
        let list = result.resultData;
        let newArry = [];
        for (var i = 0; i < list.length; i++) {
          const element = {
            id: list[i]?.id,
            valueName:
              list[i]?.contactPerson +
              " (" +
              list[i]?.referringCompanyName +
              ")",
          };
          newArry.push(element);
        }
        setReferringData(newArry);
      })
      .catch((error) => {
        renderErrors(error.message);
      });
  };

    return <div
        className={showFilter ? "opensidebar" : "closedsidebar"}
        id="sidefiter" >
        <div className="d-flex justify-content-between" >

            <h6 className="f-16 fw-600" style={{ color: "#aaaaaa" }}>Additional Filters</h6>
            <div onClick={handleShowFilter}>
                <a className="cursor-pointer" ><i className="fa fa-times cross-icon  mr-1"  ></i></a>
            </div>
        </div>


        <div className='documnet-id-filter mb-3 mt-3 staffButton'>
            {/* <MultiSelectDropDown
                data={templateList}
                textField="name"
                label='Template'
                name="template"
                value={advSearchFields.template}
                onChange={handleChange}
                autoClose={true}
                dataItemKey={"id"}
                itemRender={renderToItem}

            /> */}

<MultiSelectTemplateDropDownComponent
    autoClose = {false}
    selectedTemplate={advSearchFields.template}
    onChangeTemplate={(e: any) => {
        const name = 'template';
        const value = e.target.value
        const newAdvSearchFileds = {
            ...advSearchFields,
            [name]: value,
    }

    setAdvSearchFields(newAdvSearchFileds)
    }}
/>
        </div>

        <div className='documnet-id-filter mb-3 mt-3 staffButton'>
            <MultiSelectDropDown
                data={payerList}
                textField="name"
                label='Payers'
                name="payer"
                value={advSearchFields.payer}
                onChange={handleChange}
                autoClose={false}
                dataItemKey={"id"}
                itemRender={renderToItem}
            />
        </div>

        <div className="row">

           

            <div className='documnet-id-filter col-6 staffButton mb-2 chipMaxWidth'>
                <MultiSelectDropDown
                data={docTempTypes}
                textField="name"
                label="Doc. Type"
                name="docTempType"
                value={advSearchFields?.docTempType && advSearchFields?.docTempType}
                onChange={handleChange}
                autoClose={false}
                dataItemKey={"id"}
                itemRender={renderToItem}/>
            </div>
            <div className='documnet-id-filter col-6 staffButton mb-2'>
                <DropDownKendoRct

                    data={documentStatus}
                    textField="name"
                    label="Doc. Status"
                    name="documentStatus"
                    value={advSearchFields.documentStatus}
                    onChange={handleChange}
                />
            </div>
            <div className='documnet-id-filter col-6 staffButton mb-2 chipMaxWidth'>
                {/* <DropDownKendoRct
                    data={billStatus}
                    textField="name"
                    label="Bill Status"
                    name="billStatus"
                    value={advSearchFields.billStatus}
                    onChange={handleChange}
                /> */}
                <MultiSelectDropDown
                    data={billStatus}
                    textField="name"
                    label='Bill Status'
                    name="billStatus"
                    value={advSearchFields?.billStatus}
                    onChange={handleChange}
                    autoClose={false}
                    dataItemKey={"id"}
                    itemRender={renderToItem}
                />
            </div>

            <div className='documnet-id-filter col-6 staffButton mb-2'>
                <DropDownKendoRct
                    data={lockedArray}
                    textField="name"
                    label="Locked"
                    name="lockedValue"
                    value={advSearchFields?.lockedValue}
                    onChange={handleChange}
                    autoClose={true}
                />
            </div>

            <div className='documnet-id-filter mb-3 col-6 staffButton '>
                <label htmlFor="documentId">Document ID</label>
                <NumericTextBox
                    validityStyles={false}
                    value={advSearchFields.documentId}
                    onChange={handleChange}
                    name="documentId"
                    // label='Document ID'
                    spinners={false}
                    format={'#.##'}
                />
            </div>

            <div className='documnet-id-filter col-6 staffButton mb-2'>
                <DropDownKendoRct
                    data={signaturesData}
                    textField="name"
                    label="Signatures"
                    name="signatures"
                    value={advSearchFields?.signatures}
                    onChange={handleChange}
                    autoClose={true}
                />
            </div>
            {(isHumanResourcesManager || userIsSuperAdmin(staffLoginInfo?.roleId)) && <div className='documnet-id-filter staffButton mb-3 col-6'>
                <DropDownKendoRct
                    data={payrollArr}
                    textField="name"
                    label={"Payroll Status"}
                    name="isPayrollPaid"
                    value={(isPayrollPaid && isPayrollPaid != null) && isPayrollPaid}
                    onChange={handleChange}
                    autoClose={true}
                />
            </div>
            }
            <div className='documnet-id-filter col-6 staffButton mb-2'>
                <DropDownKendoRct
                    label="Flag Name"
                    onChange={handleChange}
                    data={clinicFlagList}
                    textField="flagName"
                    suggest={true}
                    name="flag"
                    dataItemKey="id"
                    required={true}
                    itemRender={itemRender}
                    value={advSearchFields.flag}
                /> 
            </div>
            <div className='documnet-id-filter col-6 staffButton mb-2'>
                <DropDownKendoRct
                    data={clientStatus}
                    onChange={handleChange}
                    textField="statusName"
                    name="clientStatus"
                    value={advSearchFields.clientStatus}
                    dataItemKey="id"
                    label="Client Status"
                />
            </div>
            <div className='documnet-id-filter col-6 staffButton mb-2'>
                <DropDownKendoRct
                    data={attachmentData}
                    onChange={handleChange}
                    textField="name"
                    name="hasAttachment"
                    value={advSearchFields.hasAttachment}
                    dataItemKey="id"
                    label="Has Attachment"
                />
            </div>
            <div className="document-id-filter mb-3 col-12 staffButton">
                <DropDownKendoRct
                    data={referringData}
                    onChange={handleChange}
                    textField="valueName"
                    name="refName"
                    value={advSearchFields.refName}
                    dataItemKey="id"
                    label="Referral Source"
                />
            </div>
            <div className="document-id-filter mb-3 col-6 staffButton">
                <DropDownKendoRct
                    data={ageList}
                    textField="name"
                    label="Client Age"
                    name="clientAge"
                    value={advSearchFields.clientAge}
                    onChange={handleChange}
                    dataItemKey="id"
                />
            </div>
            <div className='documnet-id-filter mb-3 col-6 staffButton'>
            <label htmlFor="billingBatchId">Billing Batch ID</label>
                <NumericTextBox
                    validityStyles={false}
                    value={advSearchFields.billingBatchId}
                    onChange={handleChange}
                    name="billingBatchId"
                    // label='Billing Batch ID'
                    spinners={false}
                    format={'#.##'}
                />
            </div>
            <div className='documnet-id-filter mb-3 col-12 staffButton'>
                {/* <DropDownKendoRct
                    data={denialReasonList}
                    onChange={handleChange}
                    textField="code"
                    name="denialReason"
                    value={advSearchFields?.denialReason}
                    dataItemKey="id"
                    label="Denial Reason"
                    itemRender={(li, itemProps) => (
                        <li {...li.props}>
                            {itemProps.dataItem.code} - {customDescription(itemProps.dataItem.description)}
                        </li>
                    )}
                /> */}
                <MultiSelectDropDown
                    data={denialReasonList}
                    textField="code"
                    label='Denial Reason'
                    name="denialReason"
                    value={advSearchFields?.denialReason}
                    onChange={handleChange}
                    autoClose={false}
                    dataItemKey={"id"}
                    itemRender={(li, itemProps) => (
                        <li {...li.props}>
                            {itemProps.dataItem.code} - {customDescription(itemProps.dataItem.description)}
                        </li>
                    )
                }
                />
            </div>

            <div className='documnet-id-filter mb-3 col-12 staffButton blue_theme'>
                <br></br>
                <Switch
                    onChange={handleTrashSwitch}
                    checked={isActiveCheck}
                    onLabel={""}
                    offLabel={""}
                    className="switch-on"
                />
                <span className="switch-title-text ml-2">
                    Show Trashed
                </span>
            </div>
        </div>
        <br></br>
        <div className="d-flex side-filter-margin mt-2">
            <div>
                <button type='button' className='submitButon m-0'
                    onClick={handleApplyFilter}>
                    Show Docs
                </button>
            </div>
            <div>
                <button type='button' className='cancelBtn '
                    onClick={handleClearFilter}>
                    Clear
                </button>
            </div>
        </div>
    </div>


}

export default SideFilter;